<template>

  <v-app style="width: 0px;height: 0px;">
    <v-dialog v-model="show" persistent max-width="800px" >
      <v-card>
        <v-card-title>
          <span class="headline">Dettaglio Cartolina compilata</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 10px;">
          <v-container>
            <CompleteCardPanel :idKindomhall="idKindomhall" :is-admin="true"/>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import CommonMixins from "../../../mixins/CommonMixins";
import {BusEvent} from "../../../../../utils/busEvent";
import CompleteCardPanel from "../CompleteCardPanel";

export default {
  name: "CompleteSheetEditDialog",
  components: {CompleteCardPanel},
  props:{
    show:{type:Boolean, default: ()=>false},
    closeDialog:Function,
    sheetCode:String,
    idKindomhall:String|Number
  },
  watch:{
    show(){
      if(this.show && !!this.sheetCode){
        this.$nextTick(() => {
          console.log('Sheet Code ->'  + this.sheetCode);
          BusEvent.onLoadCardCode(this.sheetCode);});
      }
    }
  },
  mixins:[
    CommonMixins
  ],
}
</script>

<style scoped>

</style>