<template>
  <div class="topbar-item">
    <div
      class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      id="kt_quick_user_toggle"
    >
      <div class="d-flex flex-column text-right pr-3">
        <span class="text-muted font-weight-bold font-size-base d-none d-md-inline">{{ $store.getters.currentUser.username }}</span
        >
        <span class="text-dark-75 font-weight-bolder font-size-base d-none d-md-inline">{{ $store.getters.currentProfile.role }}</span
        >
      </div>
      <span class="symbol symbol-35 symbol-light-primary">
        <span class="symbol-label font-size-h5 font-weight-bold">JW</span>
      </span>
    </div>

    <div
      id="kt_quick_user"
      ref="kt_quick_user"
      class="offcanvas offcanvas-right p-10"
    >
      <!--begin::Header-->
      <div
        class="offcanvas-header d-flex align-items-center justify-content-between pb-5"
      >
        <h3 class="font-weight-bold m-0">
          Profilo
          <!--small class="text-muted font-size-sm ml-2">12 messaggi</small-->
        </h3>
        <a
          href="#"
          class="btn btn-xs btn-icon btn-light btn-hover-primary"
          id="kt_quick_user_close"
        >
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->

      <!--begin::Content-->
      <perfect-scrollbar
        class="offcanvas-content pr-5 mr-n5 scroll"
        style="max-height: 90vh; position: relative;"
      >
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" :src="picture" alt="" />
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a
              href="#"
              class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{$store.getters.currentUser.username }}
            </a>
            <div class="text-muted mt-1">{{ $store.getters.currentProfile.role }}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon mr-1">
                    <span class="svg-icon svg-icon-lg svg-icon-primary">
                      <!--begin::Svg Icon-->
                      <inline-svg
                        src="media/svg/icons/Communication/Mail-notification.svg"
                      />
                      <!--end::Svg Icon-->
                    </span>
                  </span>
                </span>
              </a>
            </div>
            <button class="btn btn-light-primary btn-bold" @click="onLogout">
              Esci
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
            <div class="navi-link">
              <div class="navi-text">
                <!--div class="font-weight-bold">Modifica Password</div-->
              </div>
            </div>
            <!--div class="navi-link">
              <div class="navi-text">
                <div class="font-weight-bold">Modifica Posizione</div>
              </div>
            </div-->
          <!--end:Item-->
        </div>
        <!--end::Nav-->
        <!--div class="separator separator-dashed my-7"></div>
        <div>
          <h5 class="mb-5">Recenti Notifiche</h5>
          <template v-for="(item, i) in list">
            <div
              class="d-flex align-items-center rounded p-5 gutter-b"
              v-bind:class="`bg-light-${item.type}`"

            >
              <span
                class="svg-icon mr-5"
                v-bind:class="`svg-icon-${item.type}`"
              >
              </span>
              <div class="d-flex flex-column flex-grow-1 mr-2">
                <a
                  href="#"
                  class="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                >
                  {{ item.title }}
                </a>
                <span class="text-muted font-size-sm">
                  {{ item.desc }}
                </span>
              </div>
              <span
                class="font-weight-bolder py-1 font-size-lg"
                v-bind:class="`text-${item.type}`"
              >
                {{ item.alt }}
              </span>
            </div>
          </template>
        </div-->
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";
import KTOffcanvas from "@/assets/js/components/offcanvas.js";

export default {
  name: "KTQuickUser",
  data() {
    return {
      list: [
        {
          title: "Ritiro letteratura",
          desc: "Ricordati che devi prendere la letteratura",
          type: "warning"
        },
        {
          title: "Seconda sala",
          desc: "I nominativi per chi sostiene la seconda sala sono stati aggiornati",
          type: "success"
        },
        {
          title: "Sostituzione",
          desc: "Riesci a sostituirmi per la lettura?",
          type: "danger"
        },
        {
          title: "Ok programmazione",
          desc: "La programmazione è stata accettata",
          type: "info"
        }
      ]
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      let self = this;
      this.$store
        .dispatch(LOGOUT)
        .then(() => {
          if(self.$router)
            self.$router.push({ name: "login" });
            this.$nextTick(()=>window.location.reload());
        });
    },
    closeOffcanvas() {
      new KTOffcanvas(KTLayoutQuickUser.getElement()).hide();
    }
  },
  computed: {
    picture() {
      if(!! this.$store.getters.currentUser.sex)
        return this.$store.getters.currentUser.sex === 'M' ? 'media/svg/avatars/001-boy.svg' : 'media/svg/avatars/002-girl.svg';
      return 'media/logos/logo.jpg';
    }
  }
};
</script>
