import {Question} from "./Question";


export class QuestionTopic {
    constructor(props) {
        this.idSheet        = props ? props.idSheet : null;
        this.idTopic        = props ? props.idTopic : null;
        this.topic          = props ? props.topic   : null;
        this.info           = props ? props.info   : null;

        this.questions      = props && props.questions ? props.questions.map(t => new Question(t)) : null;
    }

}