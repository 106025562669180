import {Utils} from "../utils/utils";
import moment from "moment";

export class MaintenanceSheetLite{
    constructor(props) {
        this.id                 = props ? props.id : null;
        this.idKindomhall       = props ? props.idKindomhall : null;
        this.idCongregation     = props ? props.idCongregation : null;
        this.idRefer            = props ? props.idRefer : null;
        this.usernameRefer      = props ? props.usernameRefer : null;
        this.internalSheetCode  = props ? props.internalSheetCode : null;
        this.sheetCode          = props ? props.sheetCode : null;
        this.sheetGroup         = props ? props.sheetGroup : null;
        this.sheetName          = props ? props.sheetName : null;
        this.sheetTitle         = props ? props.sheetTitle : null;
        this.intervalMonth      = props ? props.intervalMonth : null;
        this.hourNeeded         = props ? props.hourNeeded : null;
        this.numOperator        = props ? props.numOperator : null;
        this.kindomhallName     = props ? props.kindomhallName : null;
        this.congregationName   = props ? props.congregationName : null;
        this.lastUpdate         = props && props.lastUpdate ? Utils.getFormattedDate(props.lastUpdate) : null
        this.users              = props ? props.users : null;
        this.result             = props ? props.result : null;
        this.resolved           = props ? props.resolved : null;
        this.location           = props ? props.location : null;
        this.extAgencyName      = props && !!props.extAgencyName? props.extAgencyName : '';
        this.sheetCompiled      = props && !!props.sheetCompiled? props.sheetCompiled : [];
        this.ddlast             = props && props.intervalMonth && props.lastUpdate ? props: undefined
        this.settingRowColor    = props;
    }

    set ddlast(item){
        if(!!!item) {
            this.daysRemmaing = '';
            return;
        }
        const data = moment(item.lastUpdate, 'yyyy-MM-DD');
        let df = data.add(item.intervalMonth, 'M');
        let days = df.diff(moment(), 'days');
        this.daysRemmaing = days < 0 ? "Scaduta da " + (-1*days) + " giorni" : days
    }
    set settingRowColor(props){
        this._rowVariant = '';
        if (Utils.checkIfNeedANewOne(this.lastUpdate, this.intervalMonth, 'DD-MM-YYYY'))
            this._rowVariant = 'danger'
        else if(this.resolved === 'Y'|| this.result === "Y")
            this._rowVariant = 'success';
        else if (this.result === "N")
            this._rowVariant = 'warning';
    }
    get settingRowColor(){
        return this._rowVariant;
    }
}