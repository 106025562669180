<template>
  <div>
    <div class="row" >
      <div class="col-md-12">
        <KTCodePreview v-bind:title="`Schede` ">
          <template v-slot:preview>
            <v-tabs
                ref="tabs"
                background-color="white"
                color="deep-purple accent-4"
                v-model="tab">
              <v-tab v-for="tab in tabs" :key="tab.key" v-if="tab.visible" >{{ tab.name }}</v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab" touchless>
                <v-tab-item key="tab1">
                  <ViewSheetTab />
                </v-tab-item>
                <v-tab-item key="tab2">
                  <ViewHistorySheetTab />
                </v-tab-item>
                <v-tab-item key="tab3" v-if="Utils.canSave()">
                  <ManageSheetTab />
                </v-tab-item>
              </v-tabs-items>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </div>
</template>


<script>

import CommonMixins from "../../mixins/CommonMixins";
import ViewSheetTab from "./tab/ViewSheetTab";
import ManageSheetTab from "./tab/ManageSheetTab";
import KTCodePreview from "../../../content/CodePreview";
import ViewHistorySheetTab from "@/view/pages/app/schede/tab/ViewHistorySheetTab";
import {Utils} from "@/utils/utils";

export default {
  name: "Schede",
  mixins: [
    CommonMixins
  ],
  components: {
    ViewHistorySheetTab,
    KTCodePreview,
    ManageSheetTab,
    ViewSheetTab

  },
  data() {
    return {
      tab: null,
      Utils,
      tabs:[
        {
          key: 'tab1',
          name:'Ricerca Schede',
          visible: true
        },
        {
          key: 'tab2',
          name:'Storico Schede',
          visible: true
        },
        {
          key: 'tab3',
          name:'Gestione Schede',
          visible: Utils.canSave()
        }
      ],
      request:{}
    }
  },
  mounted(){
    if(!!this.$store.getters.sheetHistoryCriteria)
      this.tab = 1;
    else
      this.tab = 0
  }
}
</script>
