import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";
import {ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER} from "../../../utils/constants";

// action types
export const LOGIN                  = "login";
export const LOGOUT                 = "logout";
export const UPDATE_AUTH            = "updateAuth";
export const UPDATE_CARD_CODE       = "updateCardCode";
export const KINDOMHALL_SELECTED    = "kindomhallSelected";
export const CONGREGATION_SELECTED  = "congregationSelected";
export const ACTION_SET_403         = "actionSet403";

// mutation types
export const PURGE_AUTH       = "logOut";
export const SET_AUTH         = "setUser";
export const SET_CARD_CODE    = "setCardCode";
export const SET_ERROR        = "setError";
export const SET_KINDOMHALL   = "setKindomhall";
export const SET_CONGREGATION = "setCongregation";
export const SET_403          = "set403";


const state = {
  errors: null,
  user: JwtService.getUserFromToken(),
  profile: JwtService.getProfileFromToken(),
  kindomhall: JwtService.getKindomhallFromToken(),
  kindomhallSelected: JwtService.getKindomhallFromToken(),
  congregation: JwtService.getCongregationFromToken(),
  congregationSelected: {},
  error403Showed: false,
  isAuthenticated: !!JwtService.getToken() && !!JwtService.getUserFromToken() && !!JwtService.getProfileFromToken()
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  currentProfile(state) {
    return state.profile;
  },
  kindomhall(state) {
    return state.kindomhall;
  },
  congregation(state) {
    return state. congregation;
  },
  kindomhallSelected(state) {
    return state.kindomhallSelected;
  },
  congregationSelected(state) {
    return state. congregationSelected;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  error403Showed(state) {
    console.log('auth.module::error403Showed  get error403Showed -> ' + state.error403Showed);
    return state.error403Showed;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("login", credentials)
          .then(({ data }) => {
            context.commit(SET_AUTH, data);
            resolve(data);
          })
          .catch(({ response }) => {
            console.log('ERROR')
            console.log(response)
            if(!!response) {
              if(!!!response.statusText && !!response.status && response.status === 403)
                response.statusText = 'Non hai il permesso per entrare nell\'applicazione';
              context.commit(SET_ERROR, response.statusText);
              reject(response.statusText);
            }else{
              response.statusText = "Errore generico";
            }
          });
    });
  },
  [ACTION_SET_403](context) {
    context.commit(SET_403);
  },
  [UPDATE_AUTH](context, userUpdated) {
    context.commit(SET_AUTH, userUpdated);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [KINDOMHALL_SELECTED](context, kindomhallSelected) {
    context.commit(SET_KINDOMHALL, kindomhallSelected);
  },
  [CONGREGATION_SELECTED](context, congregationSelected) {
    context.commit(SET_CONGREGATION, congregationSelected);
  },
  [UPDATE_CARD_CODE](context, cardCode) {
    context.commit(SET_CARD_CODE, cardCode);
  }
};

const mutations = {
  [SET_403](state) {
    console.log('auth.module::SET_403  set to True');
    state.error403Showed = true;
  },
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, login) {
    state.isAuthenticated     = true;
    state.user                = login.user;
    state.profile             = login.profile;
    state.kindomhall          = login.kindomhall;
    state.congregation        = login.congregation;
    state.kindomhallSelected  = login.kindomhall;
    if(login.profile.id == ADMIN_CONGREGAZIONE ||login.profile.id == ADMIN_CONGREGAZIONE_VIEWER ) {
      state.congregationSelected = login.congregation;
    }
    state.errors  = {};
    JwtService.saveToken(state.user.token);
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user                  = {};
    state.profile               = {};
    state.kindomhall            = {};
    state.congregation          = {};
    state.errors                = {};
    state.kindomhallSelected    = {};
    state.congregationSelected  = {};
    JwtService.destroyToken();
  },
  [SET_KINDOMHALL](state, kindomhall) {
    state.kindomhallSelected = kindomhall;
  },
  [SET_CONGREGATION](state, congregation) {
    state.congregationSelected = congregation;
  },
  [SET_CARD_CODE](state, cardCode) {
    state.user.cardCode = cardCode;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
