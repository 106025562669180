import moment from "moment";
import {ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER, ADMIN_SALA, ADMIN_SALA_VIEWER, SUPER_ADMIN} from "./constants";
const crypto = require('crypto');

import store from "../core/services/store/";
const algorithm = 'aes-256-cbc';
const key = '7efb3e7d61c7670cff5877d00383b8582bbe3a1bc2fdd55ef2a58107e440c8c2';
const iv = 'bad5069d7bc813b9a1dac2c894460d26';


let utils = {
    getScreenHeight() {
        var w = window,
            d = document,
            e = d.documentElement,
            g = d.getElementsByTagName('body')[0],
            x = w.innerHeight || e.clientHeight || g.clientHeight;
        return x;
    },
    getFormattedDateBehind(month, format='DD-MM-YYYY'){
        return moment().subtract(month, 'M').format(format)
    },
    getFormattedDateNow(){
        const today = new Date();
        const yyyy = today.getFullYear();
        let mm = today.getMonth() + 1; // Months start at 0!
        let dd = today.getDate();

        if (dd < 10) dd = '0' + dd;
        if (mm < 10) mm = '0' + mm;

        return yyyy + '-' + mm + '-' + dd;
    },
    getFormattedDate(date, format='DD-MM-YYYY'){
        return moment(date).format(format);
    },
    stringToDate(dateString, format='DD-MM-YYYY'){
        let date =  moment(dateString, format).toDate();
        return moment(date).add(1, 'hours');
    },
    checkIfNeedANewOne(date, rangeMonths, format='YYYY-MM-DD'){
        if(!!!date) return true;
        let newDate = rangeMonths < 1 ? moment(date, format).add(rangeMonths/0.25, 'w') : moment(date, format).add(rangeMonths, 'M');
        return moment().isAfter(newDate);
    },
    nextSheetUpdate(date, rangeMonths){
        if(!!!date) moment(date).format('DD-MM-YYYY')
        return  rangeMonths < 1?  moment(date).add(rangeMonths/0.25, 'w').format('DD-MM-YYYY') : moment(date).add(rangeMonths, 'M').format('DD-MM-YYYY')
    },
    isAdminAll(){
        return [SUPER_ADMIN].some(t=> store.getters.currentProfile.id == t)
    },
    canSave(){
        return [SUPER_ADMIN, ADMIN_SALA,ADMIN_CONGREGAZIONE].some(t=> store.getters.currentProfile.id == t)
    },
    canEditKindomhall(){
        return [SUPER_ADMIN, ADMIN_SALA].some(t=> store.getters.currentProfile.id == t)
    },
    canEditCongregation(){
        return [SUPER_ADMIN, ADMIN_SALA, ADMIN_CONGREGAZIONE].some(t=> store.getters.currentProfile.id == t)
    },
    justEditCongregation(){
        return [ADMIN_CONGREGAZIONE].some(t=> store.getters.currentProfile.id == t)
    },
    canWatchKindomhall(){
        return [SUPER_ADMIN, ADMIN_SALA, ADMIN_SALA_VIEWER].some(t=> store.getters.currentProfile.id == t)
    },
    canWatchJustKindomhall(){
        return [ADMIN_SALA, ADMIN_SALA_VIEWER].some(t=> store.getters.currentProfile.id == t)
    },
    canWatchJustCongregation(){
        return [ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER].some(t=> store.getters.currentProfile.id == t)
    },
    encrypt(text) {
        let cipher = crypto.createCipheriv(algorithm, Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
        let encrypted = cipher.update(text);
        encrypted = Buffer.concat([encrypted, cipher.final()]);
        return encrypted.toString('hex');
    },
    decrypt(text) {
        if(!!!text)return '';
        let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key, 'hex'), Buffer.from(iv, 'hex'));
        let decrypted = decipher.update(Buffer.from(text, 'hex'));
        decrypted = Buffer.concat([decrypted, decipher.final()]);
        return decrypted.toString();
    }
};

export const Utils = utils;