<template>
  <div>
    <!--begin::Dashboard-->
    <div v-if="$store.getters.currentProfile.id != OPERATORE && !!!$store.getters.currentUser.cardCode">
      <DashboardManutenzione />
    </div>
    <div v-if="$store.getters.currentProfile.id == OPERATORE && !!$store.getters.currentUser.cardCode">
      <DashboardOperatore />
    </div>
  </div>
</template>

<script>
import DashboardManutenzione from "../dashboard/DashboardManutenzione";
import DashboardOperatore from "./DashboardOperatore";
import {OPERATORE} from "../../../utils/constants";

export default {
  name: "dashboard",
  components: {
    DashboardOperatore,
    DashboardManutenzione,
  },
  data(){
    return {
      OPERATORE
    }
  }
};
//DA SALVARE
</script>
<style>
.v-label{
  font-size: 14px;
}
</style>
