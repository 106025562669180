<template>
  <div>
  <v-app >
    <ConfirmDialog :prop="dialogObject"/>
    <div class="row" >
      <div class="col-md-12 col-sm-12 col-12">
        <KTCodePreview v-bind:title="`Visualizzazione scheda (${cardCode ? cardCode : ''})` ">
          <template v-slot:toolbar>
            <div style="display: inherit">
              <a href="javascript:void(0)" @click="openTutorialDialog=true" style="padding-top: 5px; padding-right: 10px" class="justify-content-center" title="Vedi Tutorial">
                <i class="fa flaticon2-information icon-2x text-primary"/>
              </a>
              <div class="example-tools justify-content-center">
                <b-dropdown id="dropdown-1" variant="primary" right text="Moduli Utili">
                  <b-dropdown-item href="/moduli/DC-82_I.pdf" target="_blank">
                    <div>
                      <p>Istruzioni DC-82</p>
                      <p>
                        <small>(Standard per
                          i progetti teocratici di costruzione e manutenzione)
                        </small>
                      </p>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="/moduli/DC-85i_I.pdf" target="_blank">
                    <div>
                      <p>Istruzioni compilazione DC-85</p>
                      <p>
                        <small>(Istruzioni per compilare
                          Analisi dei rischi per la congregazione)
                        </small>
                      </p>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item href="/moduli/DC-85_I.pdf" target="_blank">
                    <div>
                      <p>Modulo DC-85</p>
                      <p>
                        <small>(Analisi dei rischi per la congregazione)</small>
                      </p>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-divider></b-dropdown-divider>
                  <b-dropdown-item href="/moduli/TO-5i_I.pdf" target="_blank">
                    <div>
                      <p>Istruzioni TO-5</p>
                      <p>
                        <small>(Istruzioni per il modulo Comunicazione di incidente)</small>
                      </p>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item href="/moduli/TO-5_I.pdf" target="_blank">
                    <div>
                      <p>Modulo TO-5</p>
                      <p><small>(Comunicazione di Incidente)</small></p>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item href="/moduli/pulizie.pdf" target="_blank">
                    <div>
                      <p>Istruzioni Pulizie</p>
                      <p><small>(Ordinarie, Settimanali)</small></p>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item href="/moduli/pulizie_programma.pdf" target="_blank">
                    <div>
                      <p>Programmazione Pulizie</p>
                      <p><small>(Programma annuale)</small></p>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
          <template v-slot:preview>
            <div style="min-height: 400px">
              <b-form inline validated v-if="!!!$store.getters.currentUser.id">
                <label class="mr-sm-2" for="user-select">
                  Scegli il tuo nominativo
                </label>
                <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    required
                    v-model="idUser"
                    :options="listOfUser.map(t=>{return {value: t.id, text: t.username}})"
                    id="user-select">
                  <template v-slot:first>
                    <option :value="null">Scegli...</option>
                  </template>
                </b-form-select>
                <b-button variant="primary" class="mt-xs-2 col-md-1 col-sm-2 col-12" @click="onSubmit">Cambia</b-button>
                <hr>
              </b-form>
              <div v-else>
                <b-form inline validated>
                  <label class="mr-sm-2" for="card-select">
                    <b>Scegli la cartolina su cui vuoi lavorare</b>
                  </label>
                  <b-form-select
                      class="mb-2 mr-sm-2 mb-sm-0"
                      required
                      v-model="$store.getters.currentUser.cardCode"
                      :options="listOfCard"
                      @change="changeSheetCode"
                      style="width:auto;max-width: 100%; overflow-x: hidden"
                      id="user-select"/>
                </b-form>
                <hr>
                <CompleteCardPanel
                    v-if="!!cardCode"
                    :on-save-answer="onSaveAnswer"
                    :on-update-answer="onUpdateAnswer"
                    :onSubmitAnswer="onSubmitAnswer"
                    :readonly="false"/>
              </div>
            </div>
          </template>
        </KTCodePreview>
      </div>
    </div>
  </v-app>
    <TutorialDialog
      :show="openTutorialDialog"
      :closeDialog="()=>{this.openTutorialDialog=false}"/>

  </div>
</template>

<script>
import ApiService from "../../../core/services/api.service";
import CommonMixins from "../mixins/CommonMixins";
import KTCodePreview from "../../content/CodePreview";
import {UPDATE_AUTH} from "@/core/services/store/auth.module";
import {Answer} from "@/models/Answer";
import {BusEvent} from "@/utils/busEvent";
import CompleteCardPanel from "./schede/CompleteCardPanel";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import TutorialDialog from "@/view/pages/app/schede/dialog/TutorialDialog";
export default {
  name: "DashboardOperatore",
  components:{
    TutorialDialog,
    ConfirmDialog,
    CompleteCardPanel,
    KTCodePreview
  },
  data(){
    return {
      listOfUser: [],
      listOfCard:[],
      userId: undefined,
      title: 'Scelta dell\'utente',
      idUser: null,
      show: true,
      dialogObject:{ dialog:false},
      openTutorialDialog:false
    }
  },
  mixins:[
    CommonMixins
  ],
  computed:{
    cardCode(){
      return !!this.$store.getters.currentUser && !!this.$store.getters.currentUser.cardCode
          ? this.$store.getters.currentUser.cardCode : null;
    }
  },
  methods:{
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    onSaveAnswer(evt, maintenanceSheet, dateFilling){
      evt.preventDefault();
      this.dialogObject = this.getModalObject(
          ()=>{
            this.doSaveAnswer(maintenanceSheet, dateFilling);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },

    doSaveAnswer(maintenanceSheet, dateFilling){
      let answerToSave =
          Answer.answerFromQuestionSheet(
              maintenanceSheet,
              this.$store.getters.kindomhall.id,
              this.$store.getters.currentUser.id,
              dateFilling
          );
      ApiService.post('/saveAnswers', answerToSave)
          .then(({data}) => {

            if(data && data.success) {
              this.showToast("Il salvataggio delle risposte è andato a buon fine!");
              BusEvent.onLoadCardCode(this.cardCode);
            }else{
              this.showToast(data.message,'danger', 'Errore durante l\'operazione');
            }
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },
    onUpdateAnswer(evt, maintenanceSheet, dateFilling){
      evt.preventDefault();
      this.dialogObject = this.getModalObject(
          ()=>{this.doUpdateAnswer(maintenanceSheet, dateFilling);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    doUpdateAnswer( maintenanceSheet, dateFilling){
      let answerToSave =
          Answer.answerFromQuestionSheet(
              maintenanceSheet,
              this.$store.getters.kindomhall.id,
              this.$store.getters.currentUser.id,
              dateFilling,
              true
          );
      console.log(answerToSave);
      ApiService.post('/updateAnswers', answerToSave)
          .then(({data}) => {
            BusEvent.onLoadCardCode(this.cardCode);
            if(data && data.success) {
              this.showToast("L'aggiornamento delle risposte è andato a buon fine!");
            }
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },

    onSubmitAnswer(evt, maintenanceSheet) {
      evt.preventDefault();
      this.dialogObject = this.getModalObject(
          () => {
            this.doSubmitAnswer(maintenanceSheet);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    doSubmitAnswer(maintenanceSheet){
      let answerToSave =
          Answer.answerFromQuestionSheetForSubmit(maintenanceSheet)
      console.log(answerToSave);
      ApiService.post('/submitAnswer', answerToSave)
          .then(({data}) => {
            BusEvent.onLoadCardCode(this.cardCode);
            if(data && data.success) {
              this.showToast("L'invio delle risposte è andato a buon fine!");
              KTUtil.scrollTop(0, 1000);
            }
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },
    changeSheetCode(sheetCode){
      if(!!!sheetCode)return;
      // this.$store.dispatch(UPDATE_AUTH, sheetCode);
      BusEvent.onLoadCardCode(this.cardCode, true);
    },
    getListCardByUser(){
      ApiService.get('/getListCardByUser')
          .then(({data}) => {
            this.listOfCard = !!data && !!data.maintenanceSheet ? data.maintenanceSheet.map(t=>{return {value: t.sheetCode, text: t.sheetTitle}}): [];
          })
          .catch(response => {
            this.checkResponse(response)
          });

    },
    onSubmit(evt) {
      evt.preventDefault()
      ApiService.get('/changeUser?idUser='+ this.idUser + '&cardCode=' + this.$store.getters.currentUser.cardCode)
          .then(({data}) => {
            console.log(data);
            if(data && data.user) {
              this.$store.dispatch(UPDATE_AUTH, data);
              this.getListCardByUser();
              setTimeout(() => {
                console.log('entro nel timeout');
                BusEvent.onLoadCardCode(this.cardCode, true);
              }, 500);
            }
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },
    onReset(evt) {
      evt.preventDefault()
      this.idUser = null;
      this.$nextTick(() => {
        this.show = true
      })
    }
  },
  mounted() {
    console.log(this.$store.getters.kindomhall);
    window.scrollTo(0, 0);
    if (!!!this.$store.getters.currentUser.id && !!this.cardCode) {
      ApiService.get('/getUsersBySheetAndKindomhall?idKindomhall='+this.$store.getters.kindomhall.id)
          .then(({data}) => {
            this.listOfUser = data;
          })
          .catch(response => {
            this.checkResponse(response)
          });
    }else{
      this.getListCardByUser();
      BusEvent.onLoadCardCode(this.cardCode);
    }
  }
}
</script>

<style>
.dropdown-menu{
  width: auto!important;
}

</style>