<template>
  <div>
    <v-app>
      <div class="row" >
        <div class="col-md-12">
          <KTCodePreview v-bind:title="`Ricerca Utenti` ">
            <template v-slot:preview>
              <v-form
                  ref="user_form">
                <div class="row">
                  <div class="col-md-3 col-sm-6 col-12">
                    <v-text-field
                        dense
                        v-model="criteria.username"
                        :clearable="true"
                        label="Nome"/>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <v-text-field
                        dense
                        v-model="criteria.email"
                        :clearable="true"
                        label="E-mail"/>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <v-text-field
                        dense
                        placeholder="ES: 4.1.01"
                        v-model="criteria.internalSheetCode"
                        :clearable="true"
                        label="Codice Interno"/>
                  </div>
                  <div class="col-md-3 col-sm-6 col-12">
                    <v-text-field
                        dense
                        placeholder="ES: D-21"
                        v-model="criteria.sheetCode"
                        :clearable="true"
                        label="Codice Scheda"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 col-sm-6 col-12">
                    <v-select
                        :disabled="!Utils.isAdminAll()"
                        dense
                        :items="kindomhalls"
                        label="Sala del Regno*"
                        :rules="nameRules"
                        :clearable="Utils.isAdminAll()"
                        v-model="criteria.idKindomhall"
                        required/>
                  </div>
                  <div class="col-md-4 col-sm-6 col-12">
                    <v-select
                        :disabled="Utils.canWatchJustCongregation()"
                        dense
                        :attach="true"
                        :items="congregations"
                        label="Congregazione"
                        :clearable="Utils.canWatchKindomhall()"
                        v-model="criteria.idCongregation"/>
                  </div>
                </div>
                <br>
                <br>
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="error"
                        class="mr-4 mt-3"
                        @click="reset">
                      Cancella
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="success"
                        class="mr-4 mt-3"
                        @click="searchUsers">
                      Cerca
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="primary"
                        class="mr-4 mt-3"
                        v-if="Utils.canSave()"
                        @click="openCreateDialog">
                      Crea
                    </v-btn>
                  </div>
                </div>
              </v-form>
              <br>
              <div style="min-height: 400px; margin-top: 40px;">
                <CustomBTable
                    :items="users"
                    :headers="headers"
                    :buttons="[{title:'Dettaglio', style:'outline-primary', action:openDetailUser}]"/>
              </div>
            </template>
          </KTCodePreview>
        </div>
      </div>
      <UserEditDialog
          :user="newUser"
          :show="dialog"
          :close-dialog="closeDialog"
          :id-user="newUser.id"
          :save-dialog-done="saveDialogDone"/>
    </v-app>
  </div>
</template>

<script>
import ApiService from "../../../../core/services/api.service";
import CommonMixins from "../../mixins/CommonMixins";
import {User} from "../../../../models/User";
import KTCodePreview from "../../../content/CodePreview";
import UserEditDialog from "./UserEditDialog";
import CustomBTable from "../../../../assets/js/components/CustomBTable";
import {Utils} from "../../../../utils/utils";
import _ from "lodash";
import {SUPER_ADMIN, ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER} from "@/utils/constants";

export default {
  name: "Utenti",
  mixins: [
    CommonMixins
  ],
  components: {
    CustomBTable,
    UserEditDialog,
    KTCodePreview
  },
  data() {
    return {
      Utils,
      SUPER_ADMIN, ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER,
      users: [],
      criteria: {},
      newUser:{},
      nameRules: [ v => !!v || 'Campo obbligatorio'],
      kindomhalls:[],
      congregations:[],
      dialog: false,
      headers: [
        {label: 'Utente', key: 'username', sortable:true},
        {label: 'Email', key: 'email', sortable:true},
        {label: 'Referente', key: 'usernameRefer', sortable:true},
        {label: 'Tipo utente', key: 'typeUser', sortable:true},
        {label: 'Congregazione', key: 'congregationName', sortable:true},
        {label: 'Sala del Regno', key: 'kindomhallName', sortable:true},
        {label: 'Gruppo Manutenzione', key: 'groupList', sortable:true},
        {label: 'Info', key: 'info', sortable:true},
        { key: 'action', label: "Azioni"}
      ]
    }
  },
  methods: {
    searchUsers() {
      ApiService.post('/getUsers', this.criteria)
          .then(({data}) => {
            this.users = !!data && !!data.users && data.users.length > 0 ? data.users.map(t => new User(t)) : [];
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },
    getCongregationByKindomhallId() {
      ApiService.get('/getCongregationsByKindomhallAndRole?idKindomhall=' + this.criteria.idKindomhall)
          .then(({data}) => {
            this.congregations = !!data ? data : [];
            if ([ADMIN_CONGREGAZIONE_VIEWER, ADMIN_CONGREGAZIONE].some(t => this.$store.getters.currentProfile.id == t))
              this.criteria.idCongregation = this.$store.getters.congregation.id;
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    reset() {
      this.criteria = {idKindomhall: _.clone(this.$store.getters.kindomhall).id};
      this.$refs.user_form.resetValidation();
    },
    closeDialog(){
      this.dialog = false;
      this.newUser = {}
    },
    saveDialogDone(){
      this.dialog = false;
      this.searchUsers();
    },
    openCreateDialog() {
      this.dialog = true;
    },
    deleteRow(row){
      console.log('delete')
      console.log(row)
    },
    async openDetailUser(row){
      this.newUser  = row.item;
      this.dialog   = true;
    }
  },
  mounted(){
    let self = this;
    ApiService.get('/getAllKindomhalls')
        .then(({data}) => {
          self.kindomhalls = !!data ? data : [];
          this.criteria.idKindomhall = _.clone(this.$store.getters.kindomhall).id;
          this.getCongregationByKindomhallId(this.criteria.idKindomhall)
        })
        .catch(response => {
          self.checkResponse(response)
        });
  }
}
</script>