import {QuestionTopic} from "./QuestionTopic";
import {Utils} from "../utils/utils";
import {MaintenanceSheetResult} from "@/models/MaintenanceSheetResult";


export class MaintenanceSheet {
    constructor(props) {
        this.idSheet            = props ? props.idSheet             : null;
        this.internalSheetCode  = props ? props.internalSheetCode   : null;
        this.sheetCode          = props ? props.sheetCode           : null;
        this.sheetName          = props ? props.sheetName           : null;
        this.sheetTitle         = props ? props.sheetTitle          : null;
        this.location           = props ? props.location            : null;
        this.intervalMonth      = props ? props.intervalMonth       : null;
        this.hourNeeded         = props ? props.hourNeeded          : null;
        this.numOperator        = props ? props.numOperator         : null;
        this.note               = props ? props.note                : null;
        this.info               = props ? props.info                : null;
        this.warning            = props ? props.warning             : null;
        this.bgColor            = props ? props.bgColor             : null;
        this.lastUpdate         = props && props.lastUpdate ? props.lastUpdate : null
        this.topics             = props && props.topics ? props.topics.map(t=>new QuestionTopic(t)) : null;
        this.previousResult     = props ? new MaintenanceSheetResult(props.previousResult) : null;
        this.addMoreInfo        = props;
        this.otherInfo          = props ? props.otherInfo : null;
        this.extAgency          = props && !!props.extAgency? props.extAgency : false;
        this.extAgencyName      = props && !!props.extAgencyName? props.extAgencyName : false;
        this.warningCustom      = props && !!props.warningCustom? props.warningCustom : null;
    }

    set addMoreInfo(props){
        this.idKindomhall               = props && props.moreInfo ? props.moreInfo.idKindomhall : null;
        this.checkedByAdmin             = props && props.moreInfo ? props.moreInfo.checkedByAdmin: null;
        this.idMaintenanceSheetResult   = props && props.moreInfo ? props.moreInfo.idMaintenanceSheetResult: null;
        this.submitted                  = props && props.moreInfo ? props.moreInfo.submitted : 'N';
        this.needANewOne                =
            (Utils.checkIfNeedANewOne(props.lastUpdate, this.intervalMonth)
                || !!!this.idMaintenanceSheetResult
                || !!this.idMaintenanceSheetResult && this.submitted == 'N'
                || (!!this.idMaintenanceSheetResult && this.submitted == 'Y' && Utils.checkIfNeedANewOne(props.lastUpdate, this.intervalMonth)))
            && (!!!this.previousResult || Utils.checkIfNeedANewOne(this.previousResult.dateFilling, this.intervalMonth));
        if(this.submitted === 'Y'){
            this.nextUpdateDate = Utils.nextSheetUpdate(props.lastUpdate, this.intervalMonth);
        }else{
            if(!!this.previousResult)
                this.nextUpdateDate = Utils.nextSheetUpdate(this.previousResult.dateFilling, this.intervalMonth);
            else
                this.nextUpdateDate = null;
        }
    }
}