<template>
    <div class="row"  v-if="!!maintenanceSheet.idSheet">
        <div class="col-md-12">
            <div style="min-height: 400px">
                <b-alert
                        show
                        variant="light"
                        :style="{background: maintenanceSheet.bgColor}"
                        class="alert alert-custom alert-white alert-shadow fade show gutter-b">
                    <div class="alert-text">
                        <h2 :style="{color: colorText}">{{ maintenanceSheet.sheetTitle }}</h2>
                    </div>
                </b-alert>
                <p v-if="!!maintenanceSheet.note" style="font-size: 15px;"><b>NB: <span style="color: red">{{maintenanceSheet.note}}</span></b></p>

                <div style="width: 250px; ">Tempo di esecuzione (ore)         : <div style="float: right"><b>{{maintenanceSheet.hourNeeded}} </b></div></div>
                <div style="width: 250px">N° operatori                      : <div style="float: right"><b>{{maintenanceSheet.numOperator}}</b></div></div>
                <div style="width: 250px">Ubicazione                        : <div style="float: right"><b>{{maintenanceSheet.location}} </b></div></div>
                <div style="width: 250px">Intervento da fare ogni           : <div style="float: right"> <b>{{getInterval}} </b></div></div>

                <br><br>
                <div class="alert-text" style="font-size: 14px;">
                    <a :href="risk_href" target="_blank" style="font-size: 18px;color:red;"><b>Clicca qui per consultare I RISCHI collegati a questa attività di manutenzione</b></a>
                    <!--span :style="{color: !!!riskAnalyses ||riskAnalyses.length === 0 ? 'red' : 'green'}">
                      <b><span style="font-size: 24px;">NB:
                      {{!!!riskAnalyses ||riskAnalyses.length === 0 ? 'Non è stata compilata alcuna analisi di rischio per questa scheda'
                            :'Ci sono delle analisi di rischio associate alla  scheda.'}}</span></b></span> &nbsp;
                    <b-button variant="danger btn" size="lg"  @click="openRiskAnalyses" v-if="!!!isAdmin">Gestisci Analisi di Rischio</b-button-->
                </div>
                <hr>
                <b-alert
                        show
                        variant="light"
                        style="border: solid thin black; padding:0.75rem!important"
                        class="alert alert-custom alert-white alert-shadow fade show gutter-b">
                    <div class="alert-text">
                        <img src="media/svg/avatars/custom/warning_sheet.png" width="50"/> <span v-html="'<b>'+maintenanceSheet.info+'</b>'"></span>
                    </div>
                </b-alert>
                <b-alert
                        v-if="!!maintenanceSheet.warning"
                        show
                        variant="light"
                        style="border: solid thin black;"
                        class="alert alert-custom alert-white alert-shadow fade show gutter-b">
                    <div class="alert-text">
                        <img src="media/svg/avatars/custom/warning.png" width="100"/> <b>{{ maintenanceSheet.warning }}</b>
                    </div>
                </b-alert>
                <b-alert
                        show
                        variant=""
                        style="border: solid thin black"
                        :class="maintenanceSheet.needANewOne ? 'alert-danger' : 'alert-success'"
                        class="alert alert-custom alert-shadow fade show gutter-b">
                    <div class="alert-text">
                        <b>{{ maintenanceSheet.needANewOne
                            ? "Devi aggiornare la tua scheda perché è scaduta"
                            : "La tua scheda è ancora valida e non necessita di aggiornamento. Prossimo aggionamento sarà " + maintenanceSheet.nextUpdateDate }}</b>
                    </div>
                </b-alert>

                <b-form>
                    <div class="row">
                        <div class="col-md-4 col-sm-6 col-12">
                            <label for="example-datepicker"><b>{{dateExecutionLabel}}</b></label>
                            <b-form-datepicker
                                    id="example-datepicker"
                                    v-model="lastUpdate"
                                    :disabled='disableForm'
                                    locale="it-IT"
                                    class="mb-2"/>
                        </div>
                    </div>
                    <hr>
                    <div :id="'topic_div_' + topic.idTopic" v-for="(topic, $indexTopic) in maintenanceSheet.topics" style="margin-top: 50px">
                        <h4><u>{{topic.topic}}</u></h4>
                        <p><u>{{topic.info}}</u></p>
                        <div :id="'question_div_' + question.idQuestion" v-for="(question, $index) in topic.questions" style="margin: 20px 0">
                            <p style="font-size: 14px"><b>{{($index+1)}}) {{question.question}}</b></p>
                            <div class="row">
                                <div class="col-md-9 col-sm-12 col-12" style="height: 30px">
                                    <b-form-group >
                                        <b-form-radio-group @change="()=>checkSomeIssue(question)" :disabled='disableForm'
                                                            :id="'radio-group-' + $indexTopic + '-' + $index"
                                                            v-model="question.answer.answer"
                                                            :options="[{value:'Y', text:'Esito Positivo'},{value:'N', text:'Esito Negativo'},{value:'I', text:'Controllo non effettutato'}]"
                                                            :name="'radio-group-' + $indexTopic + '-' + $index"
                                        />
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-12 col-12">
                                    <b-form-checkbox style="margin-top: 5px" :disabled='disableForm'  v-model="question.answer.showText" size="sm">Aggiungi note</b-form-checkbox>
                                </div>
                            </div>
                            <b-textarea  rows="5" :disabled='disableForm' v-if="question.answer.showText"
                                         placeholder="Inserisci il motivo per cui il controllo non è andato a buon fine o la lettura di qualche contatore" v-model="question.answer.note" />
                        </div>

                    </div>
                    <b-alert
                            v-if="!!maintenanceSheet.otherInfo"
                            show
                            variant="light"
                            style="border: solid thin black; padding:0.5rem!important"
                            class="alert alert-custom alert-white alert-shadow fade show gutter-b mt-15">
                        <div class="alert-text">
                            <img src="media/svg/avatars/custom/info.png" width="50"/> <b>{{ maintenanceSheet.otherInfo }}</b>
                        </div>
                    </b-alert>
                    <hr>
                    <div class="row">
                        <div class="col-12 text-center">
                            <b-button variant="success" v-if="(!!!maintenanceSheet.idMaintenanceSheetResult || maintenanceSheet.submitted == 'Y') && !readonly" @click="(e)=>{onSaveAnswer(e, this.maintenanceSheet, this.lastUpdate);}" style="min-width: 150px;">Salva Nuovo</b-button>
                            <b-button variant="success" v-if="!!maintenanceSheet.idMaintenanceSheetResult && maintenanceSheet.submitted == 'N'  && !readonly" @click="(e)=>{onUpdateAnswer(e, this.maintenanceSheet, this.lastUpdate);}" style="margin-left:10px; min-width: 150px;">Aggiorna</b-button>
                            <b-button variant="primary"  v-if="!!maintenanceSheet.idMaintenanceSheetResult && maintenanceSheet.submitted == 'N' && !readonly" @click="(e)=>{onSubmitAnswer(e, this.maintenanceSheet);}" style="margin-left:10px; min-width: 150px;">Invia al Referente</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
        <!--RiskAnalysesDialog
            :maintenanceSheet="maintenanceSheet"
            :show="openRiskAnalysesDialog"
            :closeDialog="closeDialog"/-->
        <ConfirmDialog :prop="dialogObject"/>
    </div>
</template>

<script>
import ApiService from "../../../../core/services/api.service";
import {MaintenanceSheet} from "../../../../models/MaintenanceSheet";
import KTCodePreview from "../../../content/CodePreview";
import {BusEvent} from "../../../../utils/busEvent";
import {LOAD_CARD_CODE} from "../../../../utils/constants";
import CommonMixins from "../../mixins/CommonMixins";
import {Utils} from "@/utils/utils";
//import {RiskAnalyses} from "@/models/RiskAnalyses";
import RiskAnalysesDialog from "@/view/pages/app/schede/dialog/RiskAnalysesDialog";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";

export default {
    name: "CompleteCardPanel",
    components: {ConfirmDialog, KTCodePreview},//RiskAnalysesDialog},
    mixins:[CommonMixins],
    props:{
        onSaveAnswer: Function,
        onUpdateAnswer: Function,
        onSubmitAnswer: Function,
        idKindomhall: String|Number,
        isAdmin: { type:Boolean, default: ()=>false},
        readonly:{ type:Boolean, default: ()=>true}
    },
    computed:{
        risk_href(){
            return 'rischio/'+this.maintenanceSheet.sheetCode+' 2023_02 DC-85_I.pdf';
        },
        dateExecutionLabel(){
            if(!this.isAdmin)
                return 'Data nuova esecuzione'
            return 'Data precedente esecuzione'
        },
        getInterval(){
            let interval      = parseFloat(this.maintenanceSheet.intervalMonth) < 1 ? parseFloat(this.maintenanceSheet.intervalMonth)/0.25 : this.maintenanceSheet.intervalMonth;
            let typeInterval  = parseFloat(this.maintenanceSheet.intervalMonth) < 1
                ? (parseFloat(this.maintenanceSheet.intervalMonth) == 0.25 ? 'Settimana' : 'Settimane')
                : (parseFloat(this.maintenanceSheet.intervalMonth) == 1 ? 'Mese' : 'Mesi');
            return interval + ' ' + typeInterval;
        },
        colorText(){
            if(this.maintenanceSheet.bgColor === 'yellow')
                return 'black';
            return 'white';
        },
        disableForm(){
            return this.readonly;/*
      let disable = !((this.maintenanceSheet.needANewOne && (!!!this.maintenanceSheet.idMaintenanceSheetResult || this.maintenanceSheet.submitted == 'Y') && !this.readonly) ||
          (!!this.maintenanceSheet.idMaintenanceSheetResult && this.maintenanceSheet.submitted == 'N'  && !this.readonly));
      console.log(disable);
      return disable;*/
        }
    },
    data(){
        return{
            maintenanceSheet: {},
            //riskAnalyses: null,
            //riskPopuUpShowed: false,
            //openRiskAnalysesDialog: false,
            lastUpdate: Utils.getFormattedDateNow(),
            dialogObject:{ dialog:false}
        }
    },
    methods:{
        hideDialog(){
            this.dialogObject.dialog = false;
        },
        getCompleteCard(cardObj){
            let parameter = '?cardCode='+ cardObj.cardCode;
            parameter+= !!this.idKindomhall ? '&idKindomhall='+this.idKindomhall : '';

            ApiService.get('/getCompleteCard' + parameter)
                .then(({data}) => {
                    if(!!data.maintenanceSheet) {
                        this.maintenanceSheet = new MaintenanceSheet(data.maintenanceSheet);
                        this.lastUpdate = !!this.maintenanceSheet.lastUpdate && this.isAdmin ? this.maintenanceSheet.lastUpdate : Utils.getFormattedDateNow();
                        //this.getRiskAnalyses(cardObj);
                    }
                })
                .catch(response => {
                    this.checkResponse(response)
                });
        },

        checkSomeIssue(question){
            question.answer.solved = question.answer.answer == 'N' ? 'N' : null;
        },
        /*showRiskAnalysesPopup(){
          this.dialogObject = this.getRiskObject(
              ()=>{
                this.hideDialog();
                this.openRiskAnalyses();
              },
              () => {
                this.hideDialog()
              }
          );
        },
        getRiskAnalyses(cardObj){
          ApiService.get('/getRiskAnalysesByMaintenanceSheetId?maintenanceSheetId=' + this.maintenanceSheet.idSheet)
              .then(({data})=>{
                if(data) {
                  this.riskAnalyses = data.map(t =>new RiskAnalyses(t));
                  if(cardObj.showRiskPopUp && (!!!this.riskAnalyses || this.riskAnalyses.length === 0))
                    this.showRiskAnalysesPopup();
                  return;
                }
                this.riskAnalyses = null;
              }) .catch(response => {
            this.checkResponse(response)
          });
        },
        openRiskAnalyses(){
          console.log('CompleteCardPanel::openRiskAnalyses');
          this.openRiskAnalysesDialog = true;
        },
        closeDialog(){
          this.openRiskAnalysesDialog   = false;
          this.getRiskAnalyses({showRiskPopUp: false});
        },*/
    },
    created() {
        BusEvent.$on(LOAD_CARD_CODE, this.getCompleteCard);
    },
    beforeDestroy() {
        BusEvent.$off(LOAD_CARD_CODE, this.getCompleteCard);
    }
}
</script>
