<template>
  <!--begin::Advance Table Widget 10-->
    <div class="card card-custom gutter-b card-stretch">
        <!--begin::Header-->
        <div class="card-header border-0 py-5">
            <h3 class="card-title align-items-start flex-column">
                <span class="card-label font-weight-bolder text-dark">Schede scadute</span>
                <span class="text-muted mt-3 font-weight-bold font-size-sm">Schede : {{list.length}} visualizzate di {{totalSheets}} totali</span>
            </h3>
            <div class="card-toolbar">
                <a href="javascript:void(0)"
                   @click="getItemsForTable"
                   class="btn btn-info font-weight-bolder font-size-sm">
                    <i class="flaticon-refresh icon-lg"></i>
                </a>
                <a href="javascript:void(0)"
                   @click="exportExcel"
                   class="btn btn-info font-weight-bolder font-size-sm"
                   style="float: left; margin-left: 10px;">
                    <i class="flaticon-download icon-lg"></i>
                </a>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Body-->
        <div class="card-body py-0">
            <!--begin::Table-->
            <div class="table-responsive">
                <table
                        class="table table-head-custom table-vertical-center"
                        id="kt_advance_table_widget_4"
                >
                    <thead>
                    <tr class="text-left">
                        <th style="min-width: 50px">Cod. Interno</th>
                        <th style="min-width: 110px">Titolo scheda</th>
                        <th style="min-width: 110px">Data Ultimo aggiornamento</th>
                        <th style="min-width: 120px">Congregazione</th>
                        <th style="min-width: 120px">Referente</th>
                        <th style="min-width: 120px">Operatori</th>
                    </tr>
                    </thead>
                    <tbody  v-if="!!list && list.length > 0">
                    <template v-for="(item, i) in list">
                        <tr v-bind:key="i">
                            <td>
                              <span class="text-info font-weight-bolder d-block font-size-lg">
                                {{ item.internalSheetCode }}
                              </span>
                            </td>
                            <td class="pl-0">
                                <a  href="javascript:void(0)" @click="()=>openHistoryLocalSheet(item.sheetCode)" title="Vedi Storico"
                                    class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                                    {{ item.sheetTitle }}
                                </a>
                            </td>
                            <td>
                 <span
                         class="text-info font-weight-bolder d-block font-size-lg">
                    {{ item.lastUpdate }}</span>
                            </td>
                            <td>
                  <span
                          class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                    {{ item.congregationName }}
                  </span>
                            </td>
                            <td>
                  <span
                          class="text-dark-75 font-weight-bolder text-hover-primary font-size-lg">
                    {{ item.usernameRefer }}
                  </span>
                            </td>
                            <td>
                  <span
                          class="text-info font-weight-bolder d-block font-size-lg">{{ item.users }}
                  </span>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                    <tbody  v-else>
                    <template>
                        <tr style="height: 100px">
                            <td colspan="5">
                                <h3 style="text-align: center" v-html="noElements"></h3>
                            </td>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <!--end::Table-->
        </div>
        <!--end::Body-->
    </div>
  <!--end::Advance Table Widget 10-->
</template>

<script>
import ApiService from "../../../../core/services/api.service";
import {BusEvent} from "../../../../utils/busEvent";
import {Utils} from "../../../../utils/utils";
import {MaintenanceSheetLite} from "../../../../models/MaintenanceSheetLite";
import CommonMixins from "../../mixins/CommonMixins";
import {REFRESH_DASHBOARD} from "../../../../utils/constants";
import xlsx from "json-as-xlsx"

export default {
    name: "SheetExpiredTable",
    data() {
        return {
            Utils,
            checked: false,
            totalSheets: 0,
            list: [],
            totalList:[]
        };
    },
    components: {},
    mixins:[ CommonMixins],
    computed:{
        noElements(){
            let kindomhallName    = this.$store.getters.kindomhallSelected.name ? ' <br> <br>'+ this.$store.getters.kindomhallSelected.name : null;
            let congregationName  = !!this.$store.getters.congregationSelected && this.$store.getters.congregationSelected.name ? ', Congregazione di ' +this.$store.getters.congregationSelected.name : null;

            return '<br>Non ci sono elementi';//  ' + kindomhallName + congregationName;
        }
    },
    methods: {
        getItemsForTable(){
            if(!!this.$store.getters.kindomhallSelected && !!this.$store.getters.kindomhallSelected.id) {
                let endpoint = '/getSheetExpiredOrNull?idKindomhall=' + this.$store.getters.kindomhallSelected.id;
                endpoint+= !!this.$store.getters.congregationSelected && !!this.$store.getters.congregationSelected.id ? '&idCongregation='+ this.$store.getters.congregationSelected.id :'';
                ApiService.get(endpoint)
                    .then(({data}) => {
                        if(!!data && !!data.sheetList ) {
                            this.list         = data.sheetList.map(t => new MaintenanceSheetLite(t));
                            this.totalList    = data.totalList.map(t => new MaintenanceSheetLite(t));
                            this.totalSheets  = data.totalSheets;
                        }
                    })
                    .catch(response => {
                        this.checkResponse(response)
                    });

            }
        },
        openHistoryLocalSheet(sheetCode){
            this.openHistorySheet(sheetCode);
        },
        exportExcel(){

            let data = [
                {
                    sheet: "Schede Scadute",
                    columns: [
                        { label: "Codice Interno", value: "internalSheetTitle" },
                        { label: "Titolo scheda", value: "sheetTitle" },
                        { label: "Ultimo aggiornamento", value: "lastUpdate" },
                        { label: "Congregazione", value: "congregationName" },
                        { label: "Referente", value: "usernameRefer" },
                        { label: "Operatori", value: "users" } ],
                    content: this.totalList,
                }];
            let settings = {
                fileName: "SchedeScadute", // Name of the resulting spreadsheet
                extraLength: 3, // A bigger number means that columns will be wider
                writeMode: 'writeFile', // The available parameters are 'WriteFile' and 'write'. This setting is optional. Useful in such cases https://docs.sheetjs.com/docs/solutions/output#example-remote-file
                writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
                RTL: false, // Display the columns from right-to-left (the default value is false)
            }

            xlsx(data, settings)
        }
    },
    mounted(){
        this.getItemsForTable();
        BusEvent.$on(REFRESH_DASHBOARD, this.getItemsForTable);
    }
};
</script>
