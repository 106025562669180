export const DASHBOARD      = "dashboard";
export const SCHEDE         = "schede";
export const UTENTI         = "utenti";
export const CONGREGAZIONI  = "congregazioni";
export const IMPOSTAZIONI   = "impostazioni";
export const EXCEL_GLOBALE  = "ExcelGlobale";
export const RISCHIO        = "Rischio";

const state = {
    currentPanel: DASHBOARD,
    sheetCriteria: null,
    sheetHistoryCriteria: null
};


const mutations = {
    "SET_PANEL" (state, payload) {
        state.currentPanel = payload.panel;
    },
    "SHEET_CRITERIA" (state, criteria) {
        state.sheetCriteria = criteria;
    },
    "SHEET_HISTORY_CRITERIA" (state, criteria) {
        state.sheetHistoryCriteria = criteria;
    }
};

const actions = {
    setPanel(context, payload) {
        context.commit("SET_PANEL", payload);
    },
    setSheetCriteria(context, criteria) {
        context.commit("SHEET_CRITERIA", criteria);
    },
    setSheetHistoryCriteria(context, criteria) {
        context.commit("SHEET_HISTORY_CRITERIA", criteria);
    }
};

const getters = {
    currentPanel(state) {
        return state.currentPanel;
    },
    sheetCriteria(state) {
        return state.sheetCriteria;
    },
    sheetHistoryCriteria(state) {
        return state.sheetHistoryCriteria;
    }
};


export default {
    state,
    mutations,
    actions,
    getters
};