<template>
  <v-app class="app" style="background: transparent">
    <v-dialog
        v-model="showSpinner"
        content-class="spinner-app">
      <b-spinner label="Loading..." variant="primary" style="margin-left: 10px;margin-top: 10px"></b-spinner>
    </v-dialog>


    <Login v-if="!$store.getters.isAuthenticated" />
    <Layout v-else />
  </v-app>
</template>

<style lang="scss">
// 3rd party plugins css
@import "~bootstrap-vue/dist/bootstrap-vue.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
@import "~socicon/css/socicon.css";
@import "~animate.css";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "assets/plugins/flaticon/flaticon.css";
@import "assets/plugins/flaticon2/flaticon.css";
@import "assets/plugins/keenthemes-icons/font/ki.css";

// Main demo style scss
@import "assets/sass/style.vue";

</style>

<script>
import Login from "./view/pages/auth/Login";
import Dashboard from "./view/pages/app/Dashboard";
import Layout from "./view/layout/Layout";
import ApiService from "./core/services/api.service";
import {DASHBOARD} from "./core/services/store/panelStore";
import {BusEvent} from "@/utils/busEvent";
import {ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER, SHOW_SPINNER} from "@/utils/constants";
import {Utils} from "@/utils/utils";
import {CONGREGATION_SELECTED, SET_CONGREGATION} from "@/core/services/store/auth.module";
export default {
  name: "MetronicVue",
  components: {Layout, Dashboard, Login},
  data(){
    return {
      showSpinner: true
    }
  },
  mounted(){
    BusEvent.$on(SHOW_SPINNER,(showSpinner)=>{
      this.showSpinner=showSpinner
    });
  },
  created() {
    if (this.$store.getters.isAuthenticated) {
      if(Utils.canWatchJustCongregation()) {
        console.log('App::created get congregation selected -> ' + JSON.stringify(this.$store.getters.congregation));
        this.$store.dispatch(CONGREGATION_SELECTED, this.$store.getters.congregation);
      }
      ApiService.get('/checkSession')
          .then(({data}) => {
            console.log('App::created start ...');
            this.$store.dispatch('setPanel', { panel: DASHBOARD});
          })
          .catch(response => {
            this.checkResponse(response)
          });
    }
  }
}
</script>
<style>
.spinner-app{
  overflow: hidden!important; background: #fff!important; width: 50px!important; height:50px!important;
}
</style>