<template>
  <!--begin::Advance Table Widget 10-->
  <div class="card card-custom gutter-b card-stretch">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark">Resoconto schede</span>
      </h3>
      <div class="card-toolbar">
        <a href="javascript:void(0)" @click="getItemsForChart" class="btn btn-info font-weight-bolder font-size-sm">
          <i class="flaticon-refresh icon-lg"></i>
        </a>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body py-0">
      <div class="card-body d-flex flex-column p-0">
        <!--begin::Chart-->
        <apexchart
            class="card-rounded-bottom"
            :options="chartOptions"
            :series="series"
            type="bar"
        ></apexchart>
        <!--end::Chart-->
        <!--begin::Stats-->
        <div class="card-spacer bg-white card-rounded flex-grow-1">
          <!--begin::Row-->
          <div class="row m-0">
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede anno corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{ sheetInOneYear }}</div>
            </div>

            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede corrette anno corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{sheetOkInOneYear}}</div>
            </div>
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede incomplete anno corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{incompleteInOneYear}}</div>
            </div>
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede con anomalie anno corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{issueInOneYear}}</div>
            </div>

            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede risolte anno corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{solvedInOneYear}}</div>
            </div>
          </div>
          <!--end::Row-->
          <!--begin::Row-->
          <div class="row m-0">
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede mese corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{ sheetIn30Days }}</div>
            </div>
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede corrette mese corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{sheetOkIn30Days}}</div>
            </div>
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede incomplete mese corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{incompleteIn30Days}}</div>
            </div>
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede con Anomalie mese corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{issueIn30Days}}</div>
            </div>
            <div class="col px-8 py-6">
              <div class="font-size-sm text-muted font-weight-bold text-center">
                Schede risolte mese corrente
              </div>
              <div class="font-size-h4 font-weight-bolder text-center">{{solvedIn30Days}}</div>
            </div>
          </div>
          <!--end::Row-->
        </div>
        <!--end::Stats-->
      </div>
    </div>
    <!--end::Body-->
  </div>
</template>

<script>
import ApiService from "../../../../core/services/api.service";
import {BusEvent} from "../../../../utils/busEvent";
import {Utils} from "../../../../utils/utils";
import CommonMixins from "../../mixins/CommonMixins";
import {REFRESH_DASHBOARD} from "../../../../utils/constants";

export default {
  name: "SheetIssuedChart",
  data() {
    return {
      Utils,
      chartOptions: {},
      checked: false,
      totalSheets: 0,
      list: [],
      sheetIn30Days: 0,
      sheetInOneYear: 0,
      sheetOkIn30Days: 0,
      sheetOkInOneYear: 0,
      issueIn30Days: 0,
      issueInOneYear: 0,
      incompleteIn30Days: 0,
      incompleteInOneYear: 0,
      solvedIn30Days: 0,
      solvedInOneYear: 0,


      colors: ['#0000ff','#008f39','#FF0000','#ffff00', '#66ff00'],
      sheetInChart:[],
      issueInChart:[],
      sheetOkInChart:[],
      sheetIncompleteInChart:[],
      sheetSolvedInChart:[],
      series:[]

    };
  },
  components: {},
  mixins:[ CommonMixins],
  computed:{
    noElements(){
      let kindomhallName    = this.$store.getters.kindomhallSelected.name ? ' <br> <br>'+ this.$store.getters.kindomhallSelected.name : null;
      let congregationName  = !!this.$store.getters.congregationSelected && this.$store.getters.congregationSelected.name ? ', Congregazione di ' +this.$store.getters.congregationSelected.name : null;

      return '<br>Non ci sono elementi';//  ' + kindomhallName + congregationName;
    }
  },
  methods: {
    getItemsForChart(){
      if(!!this.$store.getters.kindomhallSelected && !!this.$store.getters.kindomhallSelected.id) {
        let endpoint = '/getDashboardChartInfo?idKindomhall=' + this.$store.getters.kindomhallSelected.id;
        endpoint+= !!this.$store.getters.congregationSelected && !!this.$store.getters.congregationSelected.id ? '&idCongregation='+ this.$store.getters.congregationSelected.id :'';
        ApiService.get(endpoint)
            .then(({data}) => {
              if(!!data) {
                this.sheetIn30Days        = data.sheetIn30Days;
                this.sheetInOneYear       = data.sheetInOneYear;

                this.sheetOkIn30Days      = data.sheetOkIn30Days;
                this.sheetOkInOneYear     = data.sheetOkInOneYear;

                this.incompleteIn30Days   = data.incompleteIn30Days;
                this.incompleteInOneYear  = data.incompleteInOneYear;

                this.solvedIn30Days      = data.solvedIn30Days;
                this.solvedInOneYear     = data.solvedInOneYear;

                this.issueIn30Days        = data.issueIn30Days;
                this.issueInOneYear       = data.issueInOneYear;


                this.sheetInChart         = data.sheetInChart.sort((b,a)=>{
                  return new Date('01/'+b.month) - new Date('01/'+a.month) ;
                });
                this.issueInChart   = data.issueInChart.sort((b,a)=>{
                  return new Date('01/'+b.month) - new Date('01/'+a.month);
                });
                this.sheetOkInChart = this.sheetInChart;
                this.sheetOkInChart   = data.sheetOkInChart.sort((b,a)=>{
                  return new Date('01/'+b.month) - new Date('01/'+a.month);
                });


                this.sheetIncompleteInChart   = data.sheetIncompleteInChart.sort((b,a)=>{
                  return new Date('01/'+b.month) - new Date('01/'+a.month);
                });

                this.sheetSolvedInChart   = data.sheetSolvedInChart.sort((b,a)=>{
                  return new Date('01/'+b.month) - new Date('01/'+a.month);
                });
                this.series = [{
                  name: 'Schede compilate',
                  data: this.sheetInChart.map(t=>t.totalSheets)
                }, {
                  name: 'Schede senza Anomalie',
                  data: this.sheetOkInChart.map(t=>t.totalSheets)
                }, {
                  name: 'Schede con Anomalie',
                  data: this.issueInChart.map(t=>t.totalSheets)
                }, {
                  name: 'Schede Incomplete',
                  data: this.sheetIncompleteInChart.map(t=>t.totalSheets)
                }, {
                  name: 'Schede Risolte',
                  data: this.sheetSolvedInChart.map(t=>t.totalSheets)
                }]
                this.fillChartOptions();
              }
            })
            .catch(response => {
              this.checkResponse(response)
            });

      }
    },
    fillChartOptions(){
      this.chartOptions = {
        series: this.series,
        colors:this.colors,
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.sheetInChart.map(t=>t.month),
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            }
          }
        }
      };
    }
  },
  mounted(){
    this.getItemsForChart();
    BusEvent.$on(REFRESH_DASHBOARD, this.getItemsForChart);
  }
};
</script>
