<template>
    <div>
        <v-app >
            <div class="row">
                <div class="col-md-12">
                    <KTCodePreview>
                        <template v-slot:preview>
                            <v-form
                                    ref="sheet_search">
                                <div class="row">
                                    <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                                        <v-text-field
                                                dense
                                                :clearable="true"
                                                placeholder="ES: 4.1.01"
                                                v-model="criteria.internalSheetCode"
                                                label="Codice Interno"/>
                                    </div>
                                    <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                                        <v-text-field
                                                dense
                                                :clearable="true"
                                                placeholder="ES: D-21"
                                                v-model="criteria.sheetCode"
                                                label="Codice Scheda"/>
                                    </div>
                                    <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                                        <v-select
                                                :disabled="$store.getters.currentProfile.id != SUPER_ADMIN"
                                                dense
                                                :items="kindomhalls"
                                                label="Sala del Regno*"
                                                :rules="nameRules"
                                                :clearable="Utils.isAdminAll()"
                                                v-model="criteria.idKindomhall"
                                                required/>
                                    </div>
                                    <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                                        <v-select
                                                :disabled="Utils.canWatchJustCongregation()"
                                                dense
                                                :items="congregations"
                                                label="Congregazione"
                                                :clearable="Utils.canWatchKindomhall()"
                                                v-model="criteria.idCongregation"/>
                                    </div>
                                    <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                                        <v-menu
                                                v-model="menuStart"
                                                :close-on-content-click="true"
                                                offset-y
                                                min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        dense
                                                        :value="dateStartFormatted"
                                                        label="Data da"
                                                        prepend-icon="mdi-calendar"
                                                        :clearable="true"
                                                        readonly
                                                        @click:clear="dateStart = null"
                                                        v-bind="attrs"
                                                        v-on="on"/>
                                            </template>
                                            <v-date-picker
                                                    v-model="dateStart"
                                                    locale="it"
                                                    scrollable/>
                                        </v-menu>
                                    </div>
                                    <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                                        <v-menu
                                                v-model="menuEnd"
                                                :close-on-content-click="true"
                                                transition="scale-transition"
                                                offset-y
                                                min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field
                                                        :value="dateEndFormatted"
                                                        label="Data a"
                                                        prepend-icon="mdi-calendar"
                                                        :clearable="true"
                                                        dense
                                                        readonly
                                                        @click:clear="dateEnd = null"
                                                        v-bind="attrs"
                                                        v-on="on"/>
                                            </template>
                                            <v-date-picker
                                                    v-model="dateEnd"
                                                    locale="it"
                                                    scrollable/>
                                        </v-menu>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                                        <v-text-field
                                                dense
                                                :clearable="true"
                                                placeholder="ES: Paolo Rossi"
                                                v-model="criteria.username"
                                                label="Operatore"/>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                                        <v-radio-group
                                                dense
                                                v-model="criteria.expired"
                                                class="no-margin-top no-padding-top"
                                                row>
                                            <v-radio
                                                    label="Scadute"
                                                    value="Y"/>
                                            <v-radio
                                                label="In Scadenza"
                                                value="I"/>
                                            <v-radio
                                                    label="Non scadute"
                                                    value="N"/>
                                            <v-radio
                                                    label="Indifferente"
                                                    :value="null"/>
                                        </v-radio-group>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <v-radio-group
                                                dense
                                                v-model="criteria.withIssue"
                                                class="no-margin-top no-padding-top"
                                                row>

                                            <v-radio
                                                    label="Con anomalie"
                                                    value="Y"
                                            />
                                            <v-radio
                                                    label="Senza anomalie"
                                                    value="N"
                                            />
                                            <v-radio
                                                    label="Incomplete"
                                                    value="I"
                                            />
                                            <v-radio
                                                    label="Risolte"
                                                    value="S"
                                            />
                                            <v-radio
                                                    label="Indifferente"
                                                    :value="null"
                                            />
                                        </v-radio-group>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                                        <v-radio-group
                                                dense
                                                v-model="criteria.checkedByAdmin"
                                                class="no-margin-top no-padding-top"
                                                row>
                                            <v-radio
                                                    label="Aggiunte"
                                                    value="Y"
                                            />
                                            <v-radio
                                                    label="Da aggiungere"
                                                    value="N"
                                            />
                                            <v-radio
                                                    label="Indifferente"
                                                    :value="null"
                                            />
                                        </v-radio-group>
                                    </div>
                                </div>
                                <br>
                                <br>
                                <div class="row justify-content-center">
                                    <div class="col-12 text-center">
                                        <v-btn
                                                small
                                                style="min-width: 120px"
                                                color="error"
                                                class="mr-4"
                                                @click="reset">
                                            Cancella
                                        </v-btn>
                                        <v-btn
                                                small
                                                style="min-width: 120px"
                                                color="success"
                                                class="mr-4"
                                                @click="searchSheet">
                                            Cerca
                                        </v-btn>
                                    </div>
                                </div>
                            </v-form>
                            <br>
                            <div style="min-height: 400px; margin-top: 40px;">
                                <CustomBTable
                                        :items="sheets"
                                        :headers="headers"
                                        :buttons="buttons"
                                        id-table="sheetTable"/>
                            </div>
                        </template>
                    </KTCodePreview>
                </div>
            </div>
        </v-app>
        <CompleteSheetEditDialog
                :sheet-code="sheetCode"
                :id-kindomhall="criteria.idKindomhall"
                :show="openCompleteSheetDialog"
                :closeDialog="closeDialog"/>

        <!--RiskAnalysesDialog
            :is-admin="true"
            :maintenanceSheet="sheetItem"
            :show="openRiskAnalysesDialog"
            :closeDialog="closeDialog"/-->
    </div>
</template>


<script>

import ApiService from "../../../../../core/services/api.service";
import CommonMixins from "../../../mixins/CommonMixins";
import KTCodePreview from "../../../../content/CodePreview";
import {
    SUPER_ADMIN,
    ADMIN_CONGREGAZIONE,
    ADMIN_CONGREGAZIONE_VIEWER,
    SEARCH_SHEETS
} from '../../../../../utils/constants';
import CustomBTable from "../../../../../assets/js/components/CustomBTable";
import {Utils} from "../../../../../utils/utils";
import {MaintenanceSheetLite} from "../../../../../models/MaintenanceSheetLite";
import CompleteSheetEditDialog from '../dialog/CompleteSheetEditDialog'
import _ from 'lodash';
import moment from "moment";
//import RiskAnalysesDialog from "@/view/pages/app/schede/dialog/RiskAnalysesDialog";
export default {
    name: "ViewSheetTab",
    mixins: [
        CommonMixins
    ],
    components: {
        //RiskAnalysesDialog,
        CustomBTable,
        KTCodePreview,
        CompleteSheetEditDialog
    },
    data() {
        return {
            SUPER_ADMIN,
            ADMIN_CONGREGAZIONE,
            ADMIN_CONGREGAZIONE_VIEWER,
            nameRules: [ v => !!v || 'Campo obbligatorio'],
            Utils,
            sheets: [],
            kindomhalls:[],
            congregations:[],
            dateStart: null,//Utils.getFormattedDateBehind(3, 'YYYY-MM-DD'),
            dateEnd:null,//Utils.getFormattedDateBehind(0, 'YYYY-MM-DD'),
            criteria: { expired: null, withIssue: null, checkedByAdmin:null, dateStart: Utils.getFormattedDateBehind(6)},
            sheetCode:undefined,
            sheetItem: {},
            search: '',
            openCompleteSheetDialog: false,
            //openRiskAnalysesDialog: false,
            headers: [
                {label: 'Cod. interno', key: 'internalSheetCode', sortable:true},
                {label: 'Codice', key: 'sheetCode', sortable:true},
                {label: 'Titolo', key: 'sheetTitle', sortable:true},
                {label: 'Intervallo (mesi)', key: 'intervalMonth', sortable:true},
                {label: 'Giorni rimasti', key: 'daysRemmaing', sortable:true},
                {label: 'Sala del Regno', key: 'kindomhallName', sortable:true},
                {label: 'Ore necessarie', key: 'hourNeeded', sortable:true},
                {label: 'Operatori Necessari', key: 'numOperator', sortable:true},
                {label: 'Congregazione', key: 'congregationName', sortable:true},
                {label: 'Operatori', key: 'users', sortable:true},
                {label: 'Ditta esterna', key: 'extAgencyName', sortable:true},
                {label: 'Ultima compilazione', key: 'lastUpdate', sortable:true},
                {label: 'Ultimo esito', key: 'result', sortable:true},
                {label: 'Risolto', key: 'resolved', sortable:true},
                { key: 'action', label: "Azioni", tdClass: 'width-180'}
            ],
            menuStart: false,
            menuEnd: false
        }
    },
    computed:{
        buttons(){
            return [
                {title:'Dettaglio', style:'outline-success', action:this.openCompleteSheet},
                {title:'Risolvi', style:'outline-danger', action:this.solve},
                //{title:'Rischio', style:'outline-primary', action:this.openRiskAnalysesSheet}
            ];
        },
        dateStartFormatted(){
            let ds = !!this.dateStart ? moment(this.dateStart).format('DD-MM-YYYY') : null;
            this.criteria.dateStart = ds;
            return ds;
        },
        dateEndFormatted(){
            let df = !!this.dateEnd ? moment(this.dateEnd).format('DD-MM-YYYY') : null;
            this.criteria.dateEnd = df;
            return df;
        }
    },
    methods: {
        openCompleteSheet(row) {
            this.sheetCode = !!row && !!row.item ? row.item.sheetCode : null;
            this.openCompleteSheetDialog = true;
        },
        /*openRiskAnalysesSheet(row) {
          this.sheetItem = !!row ? row.item  : null;
          this.openRiskAnalysesDialog = true;
        },*/
        solve(row) {
            let idSheet = !!row && !!row.item ? row.item.id : undefined;
            let lastUpdate = !!row && !!row.item ? row.item.lastUpdate : undefined;
            if(!!idSheet && !!lastUpdate) {
                ApiService.get('/resolveSheet?idSheet=' + idSheet + '&dateFilling=' + lastUpdate)
                    .then(({data}) => {
                        if (!!data && data.success) {
                            this.searchSheet()
                        }
                    })
                    .catch(response => {
                        this.checkResponse(response)
                    });
            }
        },
        searchSheet() {
            if(this.$refs.sheet_search.validate()) {
                ApiService.post('/searchMaintenanceSheet',  this.criteria)
                    .then(({data}) => {
                        this.sheets = !!data && !!data.maintenanceSheets && data.maintenanceSheets.length > 0 ? data.maintenanceSheets.map(t => new MaintenanceSheetLite(t)) : [];
                    })
                    .catch(response => {
                        this.checkResponse(response)
                    });
            }
        },
        reset() {
            this.criteria = {
                expired: null,
                withIssue: null,
                checkedByAdmin: null,
                idKindomhall : _.clone(this.$store.getters.kindomhall).id
            };
            //this.dateStart  = Utils.getFormattedDateBehind(3, 'YYYY-MM-DD');
            //this.dateEnd    = Utils.getFormattedDateBehind(0, 'YYYY-MM-DD');
            this.$refs.sheet_search.resetValidation();
        },
        closeDialog(){
            this.openCompleteSheetDialog = false;
            //this.openRiskAnalysesDialog = false;
        },
        getCongregationByKindomhallId(){
            console.log('ViewSheetTab::getCongregationByKindomhallId start ...');
            ApiService.get('/getCongregationsByKindomhallAndRole?idKindomhall=' + this.criteria.idKindomhall)
                .then(({data}) => {
                    this.congregations = !!data ? data : [];
                    if([ADMIN_CONGREGAZIONE_VIEWER,ADMIN_CONGREGAZIONE].some(t=> this.$store.getters.currentProfile.id == t))
                        this.criteria.idCongregation = this.$store.getters.congregation.id;

                    console.log('ViewSheetTab::getCongregationByKindomhallId search done');
                    if(!!this.$store.getters.sheetCriteria) {
                        this.criteria = this.criteria =Object.assign({}, this.criteria, _.clone(this.$store.getters.sheetCriteria));
                        this.searchSheet();
                        this.$store.dispatch('setSheetCriteria', null);
                    }
                })
                .catch(response => {
                    this.checkResponse(response);
                });
        },
        searchFromOtherPanel(criteria){
            console.log('ViewSheetTab::searchFromOtherPanel start ...');
            this.criteria = criteria;
            !!this.congregations && this.congregations.length > 0 ? this.searchSheet() : this.haveToSearch = true;
        }
    },
    mounted(){
        let self = this;
        console.log('ViewSheetTab::mounted create ...');
        ApiService.get('/getAllKindomhalls')
            .then(({data}) => {
                self.kindomhalls = !!data ? data : [];
                this.criteria.idKindomhall = _.clone(this.$store.getters.kindomhall).id;
                this.getCongregationByKindomhallId(this.criteria.idKindomhall)
            })
            .catch(response => {
                self.checkResponse(response)
            });
    }
}
</script>

