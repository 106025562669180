<template>
  <div>
    <v-app>
      <ConfirmDialog :prop="dialogObject"/>
      <div class="row" >
        <div class="col-md-12">
          <KTCodePreview v-bind:title="`Ricerca Schede` ">
            <template v-slot:preview>
              <v-form
                  ref="sheet_manage_search">
                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-text-field
                        dense
                        :clearable="true"
                        placeholder="ES: 4.1.01"
                        v-model="criteria.internalSheetCode"
                        label="Codice interno"/>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-text-field
                        dense
                        :clearable="true"
                        placeholder="ES: D-21"
                        v-model="criteria.sheetCode"
                        label="Codice Scheda"/>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-select
                        :disabled="$store.getters.currentProfile.id != SUPER_ADMIN"
                        dense
                        :items="kindomhalls"
                        label="Sala del Regno*"
                        :rules="nameRules"
                        :clearable="Utils.isAdminAll()"
                        v-model="criteria.idKindomhall"
                        required/>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-select
                        :disabled="$store.getters.currentProfile.id == ADMIN_CONGREGAZIONE_VIEWER || $store.getters.currentProfile.id == ADMIN_CONGREGAZIONE"
                        dense
                        :items="congregations"
                        label="Congregazione"
                        :clearable="Utils.canWatchKindomhall()"
                        v-model="criteria.idCongregation"/>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuStart"
                        :close-on-content-click="true"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dense
                            :value="dateStartFormatted"
                            label="Data da"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            readonly
                            @click:clear="dateStart = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateStart"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuEnd"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="dateEndFormatted"
                            label="Data a"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            dense
                            readonly
                            @click:clear="dateEnd = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateEnd"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                </div>
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-12 col-12">
                        <v-text-field
                                dense
                                :clearable="true"
                                placeholder="ES: Paolo Rossi"
                                v-model="criteria.username"
                                label="Operatore"/>
                    </div>
                  <div class="col-lg-4 col-md-4 col-sm-12 col-12">
                    <v-radio-group
                        dense
                        v-model="criteria.expired"
                        class="no-margin-top no-padding-top"
                        row>
                      <v-radio
                          label="Scadute"
                          value="Y"/>
                        <v-radio
                                label="In Scadenza"
                                value="I"/>
                      <v-radio
                          label="Non scadute"
                          value="N"/>
                      <v-radio
                          label="Indifferente"
                          :value="null"/>
                    </v-radio-group>
                  </div>
                  <div class="col-lg-6 col-md-6 col-sm-12 col-12">
                    <v-radio-group
                        dense
                        v-model="criteria.withIssue"
                        class="no-margin-top no-padding-top"
                        row>

                      <v-radio
                          label="Con anomalie"
                          value="Y"
                      />
                      <v-radio
                          label="Senza anomalie"
                          value="N"
                      />
                      <v-radio
                          label="Incomplete"
                          value="I"
                      />
                      <v-radio
                          label="Risolte"
                          value="S"
                      />
                      <v-radio
                          label="Indifferente"
                          :value="null"
                      />
                    </v-radio-group>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <v-radio-group
                        dense
                        v-model="criteria.checkedByAdmin"
                        class="no-margin-top no-padding-top"
                        row>
                      <v-radio
                          label="Aggiunte"
                          value="Y"
                      />
                      <v-radio
                          label="Da aggiungere"
                          value="N"
                      />
                      <v-radio
                          label="Indifferente"
                          :value="null"
                      />
                    </v-radio-group>
                  </div>
                </div>
                <br>
                <br>
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="error"
                        class="mr-4"
                        @click="reset">
                      Cancella
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="success"
                        class="mr-4"
                        @click="searchSheet">
                      Cerca
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="primary"
                        class="mr-4"
                        v-if="Utils.canSave()"
                        @click="openDetailSheet">
                      Crea
                    </v-btn>
                  </div>
                </div>
              </v-form>
              <br>
              <br>
              <div style="min-height: 400px; margin-top: 40px;">
                <CustomBTable
                    :items="sheets"
                    :headers="headers"
                    :buttons="buttons"/>
              </div>
            </template>
          </KTCodePreview>
        </div>
      </div>
    </v-app>
    <SheetEditDialog
        :close-dialog="closeSheetDialog"
        :save-dialog-done="saveSheetDone"
        :idSheet="idSheet"
        :show="openMaintenanceSheetDialog"/>
    <SheetLinkUserDialog
        :close-dialog="closeSheetDialog"
        :save-dialog-done="saveSheetDone"
        :id-sheet="idSheet"
        :id-kindomhall="idKindomhall"
        :show="openUserDialog"
    />
    <TopicSheetDialog
        :close-dialog="closeSheetDialog"
        :save-dialog-done="saveSheetDone"
        :id-sheet="idSheet"
        :show="openTopicDialog"/>
    <QuestionSheetDialog
        :close-dialog="closeSheetDialog"
        :save-dialog-done="saveSheetDone"
        :id-sheet="idSheet"
        :show="openQuestionDialog"/>
    <!--RiskAnalysesDialog
        :is-admin="true"
        :maintenanceSheet="sheetItem"
        :show="openRiskAnalysesDialog"
        :closeDialog="closeSheetDialog"/-->
  </div>
</template>


<script>

import ApiService from "@/core/services/api.service";
import CommonMixins from "../../../mixins/CommonMixins";
import KTCodePreview from "../../../../content/CodePreview";
import SheetEditDialog from "../dialog/SheetEditDialog";
import CustomBTable from "@/assets/js/components/CustomBTable";
import {Utils} from "@/utils/utils";
import {MaintenanceSheetLite} from "@/models/MaintenanceSheetLite";
import _ from "lodash";
import {ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER, SUPER_ADMIN} from "@/utils/constants";
import moment from "moment";
import SheetLinkUserDialog from "@/view/pages/app/schede/dialog/SheetLinkUserDialog";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import TopicSheetDialog from "@/view/pages/app/schede/dialog/TopicSheetDialog";
import QuestionSheetDialog from "@/view/pages/app/schede/dialog/QuestionSheetDialog";
//import RiskAnalysesDialog from "@/view/pages/app/schede/dialog/RiskAnalysesDialog";

export default {
  name: "ManageSheetTab",
  mixins: [
    CommonMixins
  ],
  components: {
    //RiskAnalysesDialog,
    QuestionSheetDialog,
    TopicSheetDialog,
    ConfirmDialog,
    SheetLinkUserDialog,
    CustomBTable,
    SheetEditDialog,
    KTCodePreview
  },
  data() {
    return {
      dialogObject:{ dialog:false},
      nameRules: [ v => !!v || 'Campo obbligatorio'],
      Utils,
      sheets: [],
      kindomhalls:[],
      congregations:[],
      SUPER_ADMIN,
      ADMIN_CONGREGAZIONE,
      ADMIN_CONGREGAZIONE_VIEWER,
      idSheet:undefined,
      idKindomhall:undefined,
      openMaintenanceSheetDialog: false,
      openUserDialog: false,
      openTopicDialog: false,
      openQuestionDialog: false,
      //openRiskAnalysesDialog: false,
      dateStart: null,//Utils.getFormattedDateBehind(3, 'YYYY-MM-DD'),
      dateEnd:null,//Utils.getFormattedDateBehind(0, 'YYYY-MM-DD'),
      criteria: { expired: null, withIssue: null, checkedByAdmin:null, dateStart: Utils.getFormattedDateBehind(6)},
      sheetItem: {},
      search: '',
      headers: [
        {label: 'Cod. interno', key: 'internalSheetCode', sortable:true},
        {label: 'Codice', key: 'sheetCode', sortable:true},
        {label: 'Titolo', key: 'sheetTitle', sortable:true},
        {label: 'Nome', key: 'sheetName', sortable:true},
        {label: 'Gruppo assegnazione', key: 'sheetGroup', sortable:true},
        {label: 'Operatori', key: 'users', sortable:true},
        {label: 'Ditta esterna', key: 'extAgencyName', sortable:true},
        { key: 'action', label: "Azioni",  tdClass: 'width-250'}
      ],
      menuStart: false,
      menuEnd: false
    }
  },
  computed:{
    buttons(){
      return [
        {title:'Modifica', style:'outline-primary', action:this.openDetailSheet},
        {title:'Operatori', style:'outline-success', action:this.openDetailUser},
        {title:'Sezioni', style:'outline-warning', action:this.openDetailTopic},
        {title:'Domande', style:'outline-danger', action:this.openDetailQuestion},
        {title:'Elimina', style:'outline-dark', action:this.deleteSheet}
      ];
    },
    dateStartFormatted(){
      let ds = !!this.dateStart ? moment(this.dateStart).format('DD-MM-YYYY') : null;
      this.criteria.dateStart = ds;
      return ds;
    },
    dateEndFormatted(){
      let df = !!this.dateEnd ? moment(this.dateEnd).format('DD-MM-YYYY') : null;
      this.criteria.dateEnd = df;
      return df;
    }
  },
  methods: {
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    closeSheetDialog() {
      this.idSheet                    = null;
      this.idKindomhall               = null;
      this.openMaintenanceSheetDialog = false;
      this.openUserDialog             = false;
      this.openTopicDialog            = false;
      this.openQuestionDialog         = false;
      //this.openRiskAnalysesDialog     = false;
      this.searchSheet();
    },
    saveSheetDone() {
      this.openMaintenanceSheetDialog = false;
      this.openUserDialog             = false;
      this.openTopicDialog            = false;
      this.openQuestionDialog         = false;
      //this.openRiskAnalysesDialog     = false;
      this.searchSheet();
    },
    openDetailSheet(row) {
      console.log('ManageSheetTab::openDetailKindomHall  start ...');
      this.idSheet = !!row && !!row.item ? row.item.id : undefined;
      console.log('ManageSheetTab::openDetailKindomHall  start -> ' + this.idSheet);
      this.openMaintenanceSheetDialog = true;
    },
    openDetailUser(row){
      console.log('ManageSheetTab::openDetailUser  start ...');
      this.idKindomhall = !!row && !!row.item ? row.item.idKindomhall : undefined;
      this.idSheet      = !!row && !!row.item ? row.item.id           : undefined;
      console.log('ManageSheetTab::openDetailUser  idKindomhall -> ' + this.idKindomhall);
      console.log('ManageSheetTab::openDetailUser  idSheet -> ' + this.idSheet);
      this.openUserDialog = true;
    },
    deleteSheet(row){
      console.log('ManageSheetTab::deleteSheet  start ...');
      this.dialogObject = this.getModalObject(
          ()=>{
            this.doDelete(row.item);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    doDelete(item){
      console.log('ManageSheetTab::doDelete start ...');
      ApiService.get('/deleteSheet?id='+item.id)
          .then(({data}) => {
            console.log('ManageSheetTab::doDelete deleted ...');
            if(data.success) {
              this.showToast("Elemento cancellato correttamente")
              this.searchSheet();
            }else{
              this.showToast("Elemento non cancellato", 'danger', 'Errore');
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    openDetailTopic(row){
      console.log('ManageSheetTab::openDetailTopic  start ...');
      this.idSheet      = !!row && !!row.item ? row.item.id           : undefined;
      console.log('ManageSheetTab::openDetailTopic  idSheet -> ' + this.idSheet);
      this.openTopicDialog = true;
    },
    openDetailQuestion(row){
      console.log('ManageSheetTab::openDetailQuestion  start ...');
      this.idSheet      = !!row && !!row.item ? row.item.id           : undefined;
      console.log('ManageSheetTab::openDetailQuestion  idSheet -> ' + this.idSheet);
      this.openQuestionDialog = true;
    },
    /*openRiskAnalyses(row){
      console.log('ManageSheetTab::openDetailQuestion  start ...');
      this.sheetItem      = !!row ? row.item  : undefined;
      console.log('ManageSheetTab::openRiskAnalyses  sheetItem -> ' + this.sheetItem);
      this.openRiskAnalysesDialog = true;
    },*/
    reset() {
      this.criteria = {
        expired: null,
        withIssue: null,
        checkedByAdmin: null,
        idKindomhall : _.clone(this.$store.getters.kindomhall).id
      };
      //this.dateStart  = Utils.getFormattedDateBehind(3, 'YYYY-MM-DD');
      //this.dateEnd    = Utils.getFormattedDateBehind(0, 'YYYY-MM-DD');
      this.$refs.sheet_manage_search.resetValidation();
    },
    searchSheet() {
      if(this.$refs.sheet_manage_search.validate()) {
        ApiService.post('/searchMaintenanceSheet',  this.criteria)
            .then(({data}) => {
              this.sheets = !!data && !!data.maintenanceSheets && data.maintenanceSheets.length > 0 ? data.maintenanceSheets.map(t => new MaintenanceSheetLite(t)) : [];
            })
            .catch(response => {
              this.checkResponse(response)
            });
      }
    },
    getCongregationByKindomhallId(){
      console.log('ManageSheetTab::getCongregationByKindomhallId start ...');
      ApiService.get('/getCongregationsByKindomhallAndRole?idKindomhall=' + this.criteria.idKindomhall)
          .then(({data}) => {
            this.congregations = !!data ? data : [];
            if([ADMIN_CONGREGAZIONE_VIEWER,ADMIN_CONGREGAZIONE].some(t=> this.$store.getters.currentProfile.id == t))
              this.criteria.idCongregation = this.$store.getters.congregation.id;

            console.log('ManageSheetTab::getCongregationByKindomhallId search done');
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
  },
  mounted(){
    let self = this;
    console.log('ManageSheetTab::mounted create ...');
    ApiService.get('/getAllKindomhalls')
        .then(({data}) => {
          self.kindomhalls = !!data ? data : [];
          this.criteria.idKindomhall = _.clone(this.$store.getters.kindomhall).id;
          this.getCongregationByKindomhallId(this.criteria.idKindomhall)
        })
        .catch(response => {
          self.checkResponse(response)
        });
  }
}
</script>
