
export class Congregation{
    constructor(props) {
        this.id             = props ? props.id : null;
        this.idKindomhall   = props ? props.idKindomhall : null;
        this.name           = props ? props.name : null;
    }

    static fromCombo(props){
        return new Congregation(
            {
                id : props.value ?? null,
                name : props.text ?? null,
            }
        )
    }
}