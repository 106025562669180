<template>
    <div>
        <div class="mt-3 hidden-sm-and-down">
            <b-card-group deck class="mb-3">
                <b-card border-variant="dark" header="Schede scadute" class="text-center font-weight-bold cursor-pointer"  header-text-variant="dark" @click="expired">
                    <b-card-text class="font-weight-bold">{{sheetExpired}}</b-card-text>
                </b-card>
                <!--b-card border-variant="danger" header="Analisi Rischio da controllare" class="text-center font-weight-bold cursor-pointer" header-text-variant="danger" @click="checkRiskAnalyses">
                  <b-card-text class="font-weight-bold">{{checkRisk}}</b-card-text>
                </b-card-->
                <b-card border-variant="success" header="Schede in scadenza" class="text-center font-weight-bold cursor-pointer" header-text-variant="success"  @click="expiring">
                    <b-card-text class="font-weight-bold">{{sheetExpiring}}</b-card-text>
                </b-card>
                <b-card border-variant="danger" header="Schede con anomalie" class="text-center font-weight-bold cursor-pointer" header-text-variant="danger"  @click="withIssue">
                    <b-card-text class="font-weight-bold">{{sheetKo}}</b-card-text>
                </b-card>
                <b-card border-variant="primary" header="Schede da inserire" class="text-center font-weight-bold cursor-pointer" header-text-variant="primary" @click="toInsert">
                    <b-card-text class="font-weight-bold">{{sheetToInsert}}</b-card-text>
                </b-card>
                <b-card border-variant="warning" header="Schede incomplete" class="text-center font-weight-bold cursor-pointer" header-text-variant="warning" @click="notCompleted">
                    <b-card-text class="font-weight-bold">{{sheetIncomplete}}</b-card-text>
                </b-card>
                <b-card border-variant="success" header="Schede risolte" class="text-center font-weight-bold cursor-pointer" header-text-variant="success"  @click="resolved">
                    <b-card-text class="font-weight-bold">{{sheetSolved}}</b-card-text>
                </b-card>
            </b-card-group>
        </div>
        <div class="mt-3 hidden-md-and-up">
            <b-card-group deck class="mb-3">
                <b-card border-variant="dark" header="Schede scadute" class="text-center font-weight-bold cursor-pointer"  header-text-variant="dark" @click="expired">
                    <b-card-text class="font-weight-bold">{{sheetExpired}}</b-card-text>
                </b-card>
                <!--b-card border-variant="danger" header="Analisi Rischio da controllare" class="text-center font-weight-bold cursor-pointer" header-text-variant="danger" @click="checkRiskAnalyses">
                  <b-card-text class="font-weight-bold">{{checkRisk}}</b-card-text>
                </b-card-->
                <b-card border-variant="success" header="Schede in scadenza" class="text-center font-weight-bold cursor-pointer" header-text-variant="success"  @click="expiring">
                    <b-card-text class="font-weight-bold">{{sheetExpiring}}</b-card-text>
                </b-card>
                <b-card border-variant="danger" header="Schede con anomalie" class="text-center font-weight-bold cursor-pointer" header-text-variant="danger"  @click="withIssue">
                    <b-card-text class="font-weight-bold">{{sheetKo}}</b-card-text>
                </b-card>
            </b-card-group>
            <b-card border-variant="primary" header="Schede da inserire" class="text-center font-weight-bold cursor-pointer" header-text-variant="primary" @click="toInsert">
                <b-card-text class="font-weight-bold">{{sheetToInsert}}</b-card-text>
            </b-card>
            <b-card-group deck class="mt-3 mb-3">
                <b-card border-variant="warning" header="Schede incomplete" class="text-center font-weight-bold cursor-pointer" header-text-variant="warning" @click="notCompleted">
                    <b-card-text class="font-weight-bold">{{sheetIncomplete}}</b-card-text>
                </b-card>
                <b-card border-variant="success" header="Schede risolte" class="text-center font-weight-bold cursor-pointer" header-text-variant="success"  @click="resolved">
                    <b-card-text class="font-weight-bold">{{sheetSolved}}</b-card-text>
                </b-card>
            </b-card-group>
        </div>
    </div>
</template>

<script>
import {BusEvent} from "@/utils/busEvent";
import {REFRESH_DASHBOARD} from "@/utils/constants";
import ApiService from "@/core/services/api.service";
import CommonMixins from "@/view/pages/mixins/CommonMixins";
import {RISCHIO, SCHEDE} from "@/core/services/store/panelStore";

export default {
    name: "SheetCounter",
    mixins:[CommonMixins],
    data(){
        return {
            sheetExpired: 0,
            sheetExpiring: 0,
            sheetToInsert: 0,
            sheetOk:0,
            checkRisk:0,
            sheetIncomplete:0,
            sheetKo : 0,
            sheetSolved: 0
        }
    },
    methods: {
        getItemsForTable() {
            if (!!this.$store.getters.kindomhallSelected && !!this.$store.getters.kindomhallSelected.id) {
                let endpoint = '/getSheetCounter?idKindomhall=' + this.$store.getters.kindomhallSelected.id;
                endpoint += !!this.$store.getters.congregationSelected && !!this.$store.getters.congregationSelected.id ? '&idCongregation=' + this.$store.getters.congregationSelected.id : '';
                ApiService.get(endpoint)
                    .then(({data}) => {
                        if (!!data) {
                            this.sheetExpired     = data.sheetExpired;
                            this.sheetExpiring    = data.sheetExpiring;
                            this.sheetOk          = data.sheetOk;
                            this.sheetKo          = data.sheetKo;
                            this.checkRisk        = data.checkRisk;
                            this.sheetSolved      = data.sheetSolved;
                            this.sheetIncomplete  = data.sheetIncomplete;
                            this.sheetToInsert    = data.sheetToInsert
                        }
                    })
                    .catch(response => {
                        this.checkResponse(response)
                    });

            }
        },
        commonCriteria(criteria){
            criteria.idKindomhall = this.$store.getters.kindomhallSelected.id;
            criteria.idCongregation =  this.$store.getters.congregationSelected.id;
            if(!!criteria.expired){
                criteria.withIssue      = null;
                criteria.checkedByAdmin = null;
            }
            if(!!criteria.checkedByAdmin){
                criteria.withIssue  = null;
                criteria.expired    = null;
            }
            if(!!criteria.withIssue){
                criteria.checkedByAdmin = null;
                criteria.expired        = null;
            }
            this.$store.dispatch('setSheetCriteria', criteria);
            this.$store.dispatch('setPanel', {panel: !!!criteria.checked ? SCHEDE : RISCHIO});
        },
        expired(){
            this.commonCriteria({expired : 'Y'});
        },
        expiring(){
            this.commonCriteria({expired : 'I'});
        },
        toInsert(){
            this.commonCriteria({checkedByAdmin : 'N'});
        },
        noIssue(){
            this.commonCriteria({withIssue : 'N'});
        },
        withIssue(){
            this.commonCriteria({withIssue : 'Y'});
        },
        notCompleted(){
            this.commonCriteria({withIssue : 'I'});
        },
        resolved(){
            this.commonCriteria({withIssue : 'S'});
        },
        checkRiskAnalyses(){
            this.commonCriteria({checked:'N', compiled: 'Y'});
        }
    },
    mounted(){
        this.getItemsForTable();
        BusEvent.$on(REFRESH_DASHBOARD, this.getItemsForTable);
    }
}
</script>

<style scoped>

</style>