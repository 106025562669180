import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/jwt.service";
import {BusEvent} from "@/utils/busEvent";

/**
 * Service to call HTTP request via Axios
 */


const ApiService = {
  init() {
    Vue.use(VueAxios, axios);
    Vue.axios.defaults.baseURL = window.location.href.indexOf('manutenzione') > -1 ? "maintenance-kindomhall-server/api" : "http://localhost:8081/api/";
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common[
        "Authorization"
        ] = `Bearer ${JwtService.getToken()}`;
    BusEvent.showSpinner(true);
  },

  query(resource, params) {
    this.setHeader();
    return Vue.axios.get(resource, params).catch(error => {
       BusEvent.showSpinner(false);
      throw new Error(`[KT] ApiService ${error}`);
    });
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource, slug = "") {
    this.setHeader();
    //return slug === "" ?  Vue.axios.get(`${resource}`) :  Vue.axios.get(`${resource}/${slug}`);
    return new Promise((resolve, reject)=>{
      Vue.axios.get(`${resource}`) .then(response =>{
        BusEvent.showSpinner(false);
        resolve(response);
      }).catch(e =>{
        BusEvent.showSpinner(false);
        reject(e)
      })
    })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @returns {*}
   */
  post(resource, params) {
    this.setHeader();
    return new Promise((resolve, reject)=>{
      Vue.axios.post(`${resource}`, params).then(response =>{
        BusEvent.showSpinner(false);
        resolve(response);
      }).catch(e =>{
        BusEvent.showSpinner(false);
        reject(e)
      })
    })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    this.setHeader();
    return new Promise((resolve, reject)=>{
      Vue.axios.put(`${resource}/${slug}`, params).then(response =>{
        BusEvent.showSpinner(false);
        resolve(response);
      }).catch(e =>{
        BusEvent.showSpinner(false);
        reject(e)
      })
    })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    this.setHeader();
    return new Promise((resolve, reject)=>{
      Vue.axios.put(`${resource}/${slug}`, params).then(response =>{
        BusEvent.showSpinner(false);
        resolve(response);
      }).catch(e =>{
        BusEvent.showSpinner(false);
        reject(e)
      })
    })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource) {
    this.setHeader();
    return new Promise((resolve, reject)=>{
      Vue.axios.delete(resource).then(response =>{
        BusEvent.showSpinner(false);
        resolve(response);
      }).catch(e =>{
        BusEvent.showSpinner(false);
        reject(e)
      })
    })
  }

};

export default ApiService;
