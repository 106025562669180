<template>

  <v-app style="width: 0px;height: 0px;">
    <v-dialog v-model="show" persistent max-width="1200px" >
      <v-card>
        <v-card-title>
          <span class="headline">Tutorial</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 10px;">
          <v-container>
            <video id="tutorial" width="100%"  controls>
              <source src="media/video/manutenzione_sale_op.mp4" type="video/mp4">
              Your browser does not support the video tag.
            </video>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLocallyDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
export default {
  name: "TutorialDialog",
  props:{
    show:{type:Boolean, default: ()=>false},
    closeDialog:Function,
  },
  methods:{
    closeLocallyDialog(){
      let media = document.getElementById('tutorial');
      media.pause();
      media.currentTime = 0;
      this.closeDialog();
    }
  }
}
</script>

<style scoped>

</style>