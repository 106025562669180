<template>
  <div class="d-flex flex-column flex-root" style="margin-top: 5%!important;">
    <div
        class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white login-signin-on"
        id="kt_login">
      <div class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto">
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">

            <div class="pb-13 pt-lg-0 pt-5">
              <h2
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg font-size-h2-sm text-center">
                Manutenzione Sale del Regno
              </h2>
            </div>
            <div class="pb-13 pt-lg-0 pt-5">
              <p class="mt-5">Accedi con:</p>
              <p class="options">
                <b-form-radio
                    class="custom-control-inline"
                    :value="false"
                    @change="changeAccountMode"
                    v-model="account_mode">Codice cartolina</b-form-radio>
              </p>
              <b-form-radio
                  class="custom-control-inline"
                  :value="true"
                  @change="changeAccountMode"
                  v-model="account_mode">Credenziali</b-form-radio>
            </div>
            <div v-if="account_mode">
              <form
                  class="form"
                  novalidate="novalidate"
                  id="kt_login_signin_form">
                <div class="form-group">
                  <v-text-field
                      label="Email*"
                      required
                      name="email"
                      ref="email"
                      :clearable="true"
                      v-model="form.email"/>
                </div>
                <div class="form-group">
                  <v-text-field
                      :type="pswVisible ? 'text' : 'password'"
                      label="Password*"
                      required
                      name="password"
                      ref="password"
                      :clearable="true"
                      v-model="form.password"
                      :append-icon="pswVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="pswVisible=!pswVisible"/>
                </div>
                <button
                    ref="kt_login_signin_submit"
                    class="btn btn-primary btn-sm font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 w-100">
                  Accedi
                </button>
              </form>
            </div>
            <div v-else>
              <v-form
                  class="form"
                  novalidate="novalidate"
                  id="kt_card_signin_form">
                <div class="form-group">
                  <v-select
                      :items="kindomhalls"
                      label="Sala del Regno*"
                      :attach="true"
                      v-model="form.idKindomhall"/>
                </div>
                <div class="form-group">
                  <v-text-field
                      label="Codice cartolina*"
                      required
                      name="cardCode"
                      ref="cardCode"
                      placeholder="ES: D-20"
                      :clearable="true"
                      v-model="form.cardCode"/>
                </div>
                <div class="form-group">
                  <v-text-field
                      :type="pswVisible ? 'text' : 'password'"
                      label="Password*"
                      required
                      name="password"
                      ref="password"
                      :clearable="true"
                      v-model="form.password"
                      :append-icon="pswVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="pswVisible=!pswVisible"/>
                </div>
                <button
                    ref="kt_card_signin_submit"
                    class="btn btn-primary btn-sm font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3 w-100">
                  Accedi
                </button>
              </v-form>
            </div>
          </div>
          <!--end::Signin-->
        </div>
        <!--begin::Content footer-->
        <div
            class="d-flex justify-content-center py-7 py-lg-0">
          <!--a href="#" class="text-primary font-weight-bolder font-size-h5"
          >Terms</a
          -->
          <a href="mailto:adm.nembro@gmail.com" class="text-primary font-weight-bolder font-size-h5" target="_blank">Contattaci</a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import KTUtil from "@/assets/js/components/util";
import { mapState } from "vuex";
import Swal from "sweetalert2";
import {LOGIN, LOGOUT} from "../../../core/services/store/auth.module";
import {DASHBOARD} from "../../../core/services/store/panelStore";
import {SUPER_ADMIN} from "../../../utils/constants";
import ApiService from "../../../core/services/api.service";
import CommonMixins from "@/view/pages/mixins/CommonMixins";
import {Utils} from "@/utils/utils";
import _ from "lodash";

export default {
  name: "login",
  data() {
    return {
      account_mode: false,
      kindomhalls:[],
      state: "signin",
      // Remove this dummy login info  admin@demo.com  demo
      pswVisible: false,
      form: {
        idKindomhall: null,
        email: "",
        password: "",
        cardCode: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  mixins:[CommonMixins],
  methods: {
    login(evn){
      evn.preventDefault();
    },
    changeAccountMode(){
      this.account_mode ? this.initializeAccountForm() : this.initializeCardForm();
    },
    initializeAccountForm(){
      const self = this;
      const signin_form = KTUtil.getById("kt_login_signin_form");
      this.fv = formValidation(signin_form, {
        fields: {
          email: {
            validators: {
              notEmpty: {
                message: "Username obbligatorio"
              }
            }
          },
          password: {
            validators: {
              notEmpty: {
                message: "Password obbligatoria"
              }
            }
          }
        },
        plugins: {
          trigger:      new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap:    new Bootstrap()
        }
      });
      this.fv.on("core.form.valid", () => {
        if(!self.account_mode)return;
        let email     = self.form.email;
        let password  = self.form.password;

        // clear existing errors
        self.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = self.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          self.$store
              .dispatch(LOGIN, { email, password: Utils.encrypt(password) })
              .then(() => {
                self.$store.dispatch('setPanel', {panel:DASHBOARD});
              })
              .catch((message) => {
                if(message) {
                  this.$bvToast.toast(message, {
                    title: `ERRORE`,
                    toaster: 'b-toaster-bottom-full',
                    solid: true,
                    variant: 'danger',
                    appendToast: true
                  });
                }
              });

          submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
          );
        }, 2000);
      });

      this.fv.on("core.form.invalid", () => {
        if(!self.account_mode)return;
        Swal.fire({
          title: "",
          text: "Per favore, inserisci i dati corretti!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },
    initializeCardForm(){
      const self = this;
      const signin_form_card  = KTUtil.getById("kt_card_signin_form");
      this.fvCard = formValidation(signin_form_card, {
        fields: {
          idKindomhall: {
            validators: {
              notEmpty: {
                message: "Sala del Regno obbligatoria"
              }
            }
          },
          cardCode: {
            validators: {
              notEmpty: {
                message: "Codice cartolina obbligatorio"
              }
            }
          },
          password: {
            validators: {
              notEmpty: {
                message: "Password obbligatoria"
              }
            }
          }
        },
        plugins: {
          trigger: new Trigger(),
          submitButton: new SubmitButton(),
          bootstrap: new Bootstrap()
        }
      });
      this.fvCard.on("core.form.valid", () => {
        if(self.account_mode) return;
        let cardCode      = self.form.cardCode;
        let idKindomhall  = self.form.idKindomhall;
        let password      = self.form.password;

        // clear existing errors
        self.$store.dispatch(LOGOUT);

        // set spinner to submit button
        const submitButton = self.$refs["kt_card_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");

        // dummy delay
        setTimeout(() => {
          self.$store
              .dispatch(LOGIN, { cardCode, password: Utils.encrypt(password), idKindomhall })
              .then(() => {
                self.$store.dispatch('setPanel', {panel:DASHBOARD});
              })
              .catch((message) => {
                if(message) {
                  this.$bvToast.toast(message, {
                    title: `ERRORE`,
                    toaster: 'b-toaster-bottom-full',
                    solid: true,
                    variant: 'danger',
                    appendToast: true
                  });
                }
              });

          submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
          );
        }, 2000);
      });

      this.fvCard.on("core.form.invalid", () => {
        if(self.account_mode) return;
        Swal.fire({
          title: "",
          text: "Per favore, inserisci i dati corretti!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary",
          heightAuto: false
        });
      });
    },
    getAllKindomhall(){
      ApiService.get('/getAllKindomhalls')
          .then(async ({data}) => {
            this.kindomhalls          = !!data ? data : [];
            if(!!this.kindomhalls && !!this.kindomhalls.length>0)
              this.form.idKindomhall    = _.clone(this.kindomhalls[0]).value;
          })
          .catch(response => {
            this.checkResponse(response)
          });
    }
  },
  mounted(){
    this.initializeCardForm();
    this.getAllKindomhall();
  }
};

</script>
