<template>
  <div>
    <ConfirmDialog :prop="dialogObject"/>
    <v-app >
      <div class="row">
        <div class="col-md-12">
          <KTCodePreview  v-bind:title="`Sale del Regno/Congregazioni` ">
            <template v-slot:preview>
              <v-form
                  ref="sheet_search">
                <div class="row">
                  <div class="col-md-6 col-sm-12 col-12">
                    <v-text-field
                        dense
                        v-model="criteria.kindomhallName"
                        :clearable="Utils.isAdminAll()"
                        :readonly="!Utils.isAdminAll()"
                        label="Sala del Regno"/>
                  </div>
                  <div class="col-md-6 col-sm-12 col-12">
                    <v-text-field
                        dense
                        :clearable="Utils.canWatchKindomhall()"
                        :readonly="!Utils.canWatchKindomhall()"
                        v-model="criteria.congregationName"
                        label="Congregazione"/>
                  </div>
                </div>
                <br>
                <br>
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="error"
                        class="mr-4"
                        @click="reset">
                      Cancella
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="success"
                        class="mr-4"
                        @click="searchKindomhall">
                      Cerca
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="primary"
                        class="mr-4"
                        v-if="Utils.isAdminAll()"
                        @click="openDetailKindomhall">
                      Crea
                    </v-btn>
                  </div>
                </div>
              </v-form>
              <br>
              <div style="min-height: 400px; margin-top: 40px;">
                <CustomBTable
                    :items="kindomhalls"
                    :headers="headers"
                    :buttons="buttons"
                    id-table="kindomhallTable"
                    :onRowClicked="getCongregations">
                  <template #row-details="row">
                    <div class="detail m-2 border p-2" style="background: white;">
                      <b-form-row>
                        <b-col class="p-3 pl-5 pr-5">
                          <div class="row">
                            <div class="col-10">
                              <div class="h-100 discarded-title">
                                <h6> Lista Congregazioni </h6>
                              </div>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12">
                              <CustomBTable
                                  id-table="questionAnswerTable"
                                  :items="row.item.congregations"
                                  :with-pagination="false"
                                  :buttons="buttonsCongregation"
                                  :headers="headersCongregation"/>
                            </div>
                          </div>
                        </b-col>
                      </b-form-row>
                    </div>
                  </template>
                </CustomBTable>
              </div>
            </template>
          </KTCodePreview>
        </div>
      </div>
    </v-app>
    <KindomhallEditDialog
        :kindomhall-selected="kindomhallSelected"
        :show="openKindomhallDialog"
        :save-dialog-done="saveDialogDone"
        :closeDialog="closeDialog"/>
    <CongregationSheetDialog
        :kindomhall-name="kindomhallSelected.name"
        :id-kindomhall="kindomhallSelected.id"
        :show="openCongregationDialog"
        :save-dialog-done="saveDialogDone"
        :closeDialog="closeDialog"/>
  </div>
</template>

<script>
import ApiService from "../../../../core/services/api.service";
import CommonMixins from "../../mixins/CommonMixins";
import CustomBTable from "../../../../assets/js/components/CustomBTable";
import KTCodePreview from "../../../content/CodePreview";
import {Kindomhall} from "@/models/Kindomhall";
import {Utils} from "@/utils/utils";
import KindomhallEditDialog from "@/view/pages/app/schede/dialog/KindomhallEditDialog";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import CongregationSheetDialog from "@/view/pages/app/schede/dialog/CongregationEditDialog";

export default {
  name: "Utenti",
  mixins: [
    CommonMixins
  ],
  components: {
    CongregationSheetDialog,
    ConfirmDialog,
    KindomhallEditDialog,
    CustomBTable,
    KTCodePreview
  },
  data() {
    return {
      dialogObject:{ dialog:false},
      Utils,
      kindomhalls:[],
      kindomhallSelected:{},
      criteria: {congregationName:'', kindomhallName:''},
      openKindomhallDialog: false,
      openCongregationDialog: false,
      headers: [
        {key: 'show_details', label: 'Congregazioni', sortable: false, tdClass: 'width-160'},
        {label: 'Nome', key: 'name', sortable:true},
        {label: 'Indirizzo', key: 'address', sortable:true},
        { key: 'action', label: "Azioni", tdClass:'width-390'}
      ],
      headersCongregation: [
        {label: 'Nome', key: 'name', sortable:true},
        { key: 'action', label: "Azioni", tdClass:'width-75'}
      ]
    }
  },
  computed: {
    buttons() {
      return [
        {title: 'Dettaglio', style: 'outline-primary', action: this.openDetailKindomhall},
        {title: 'Congregazioni', style: 'outline-success', action: Utils.canWatchKindomhall() ? this.openDetailCongregation : null},
        {title: 'Elimina', style: 'outline-dark', action: Utils.canEditKindomhall() ? this.deleteKindomhall : null},
      ];
    },
    buttonsCongregation() {
      return [
        {title: 'Elimina', style: 'outline-dark', action: Utils.canSave() ? this.deleteCongregation : null},
      ];
    }
  },
  methods: {
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    searchKindomhall() {
      console.log('Congregazioni::searchKindomhall start...');
      ApiService.post('/getKindomhallsByCriteria', this.criteria)
          .then(({data}) => {
            console.log('Congregazioni::searchKindomhall data got -> ' + JSON.stringify(data));
            this.kindomhalls = !!data && data.length > 0 ? data.map(t => new Kindomhall(t)) : [];
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },
    getCongregations(item) {
      console.log('Congregazioni::getCongregations start...');
      if(!!item.congregations && item.congregations.length > 0) return
      ApiService.get('/getCongregationByKindomhall?idKindomhall='+item.id)
          .then(({data}) => {
            item.congregations = !!data ? data : [];
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    openDetailKindomhall(row){
      console.log('ManageSheetTab::openDetailKindomhall  start ...');
      this.kindomhallSelected = !!row && !!row.item ? row.item : {};
      console.log('ManageSheetTab::openDetailKindomhall  start -> ' + this.kindomhallSelected);
      this.openKindomhallDialog = true;

    },
    openDetailCongregation(row){
      console.log('Congregazioni::openDetailCongregation start...');
      this.kindomhallSelected = !!row && !!row.item ? row.item : {};
      console.log('ManageSheetTab::openDetailCongregation  start -> ' + this.kindomhallSelected);
      this.openCongregationDialog = true;
    },
    deleteKindomhall(row){
      console.log('Congregazioni::deleteKindomhall start...');
      if(Utils.isAdminAll() || (Utils.canEditKindomhall() && row.item.id == this.$store.getters.kindomhall.id)){
        this.dialogObject = this.getModalObject(
            () => {
              this.doDeleteKindomhall(row);
              this.hideDialog();
            },
            () => {
              this.hideDialog()
            }
        );
      }else{
        this.showToast('Non hai i permessi per poter fare quest\'azione', 'warning', "Azione non consentita")
      }
    },
    deleteCongregation(row){
      console.log('Congregazioni::deleteCongregation start...');
      this.dialogObject = this.getModalObject(
          () => {
            this.doRemove(row);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );

    },
    doDeleteKindomhall(row){
      ApiService.get('/deleteKindomhall?id='+ row.item.id)
          .then(({data}) => {
            if(!!data && data.success) {
              this.searchKindomhall();
              this.showToast('Cancellazione Sala del Regno andata a buon fine');
              this.kindomhallSelected = {};
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    reset(){
      this.criteria =  {congregationName:'', kindomhallName:''};
    },
    closeDialog(){
      this.openKindomhallDialog   = false;
      this.openCongregationDialog = false;
      this.kindomhallSelected     = {}
    },
    saveDialogDone() {
      this.openKindomhallDialog   = false;
      this.openCongregationDialog = false;
      this.kindomhallSelected     = {}
      this.searchKindomhall();
    },
  },
  mounted(){
    console.log('Congregazioni::mounted start ...');
    if(!Utils.isAdminAll()){
      console.log('Congregazioni::mounted set Kindomhall name -> ' +this.$store.getters.kindomhall.name);
      this.criteria.kindomhallName = this.$store.getters.kindomhall.name
      console.log('Congregazioni::mounted set criteria Kindomhall name -> ' +this.criteria.kindomhallName);
      if(Utils.canWatchJustCongregation()) {
        console.log('Congregazioni::mounted set congregation name -> ' +this.$store.getters.congregation.name);
        this.criteria.congregationName = this.$store.getters.congregation.name
        console.log('Congregazioni::mounted set criteria congregation name -> ' +this.criteria.congregationName);
      }
    }
  }
}
</script>