import {HISTORY_PROGRAMMATICALLY, LOAD_CARD_CODE, REFRESH_DASHBOARD, SEARCH_SHEETS, SHOW_SPINNER,SEARCH_HISTORY_SHEETS} from "./constants";
import Vue from "vue";

export const BusEvent = new Vue({
    methods: {
        onLoadCardCode(cardCode, showRiskPopUp=false){
            this.$emit(LOAD_CARD_CODE, {cardCode,showRiskPopUp});
        },
        refreshDashboard(cardCode){
            this.$emit(REFRESH_DASHBOARD, cardCode);
        },
        getHistoryProgrammatically(request){
            this.$emit(HISTORY_PROGRAMMATICALLY, request);
        },
        showSpinner(showSpinner){
            this.$emit(SHOW_SPINNER, showSpinner);
        },
        searchSheets(criteria){
            this.$emit(SEARCH_SHEETS, criteria);
        },
        searchHistorySheets(criteria){
            this.$emit(SEARCH_HISTORY_SHEETS, criteria);
        }
    }
});