const ID_TOKEN_KEY = "id_token";
import jwt_decode from "jwt-decode";

export const getToken = () => {
  return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
  window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const destroyToken = () => {
  window.localStorage.removeItem(ID_TOKEN_KEY);
}

export const getTokenDecoded = () =>{
  let token = window.localStorage.getItem(ID_TOKEN_KEY);
  return !!token ? jwt_decode(token) : null
}

export const getUserFromToken = () =>{
  let token         = window.localStorage.getItem(ID_TOKEN_KEY);
  let tokenDecoded  =  !!token ? jwt_decode(token) : null;
  if(tokenDecoded){
    return tokenDecoded.data?.user;
  }
  return null;
}
export const getProfileFromToken = () =>{
  let token = window.localStorage.getItem(ID_TOKEN_KEY);
  let tokenDecoded  =  !!token ? jwt_decode(token) : null;
  if(tokenDecoded){
    return tokenDecoded.data?.profile;
  }
  return null;
}
export const getKindomhallFromToken = () =>{
  let token = window.localStorage.getItem(ID_TOKEN_KEY);
  let tokenDecoded  =  !!token ? jwt_decode(token) : null;
  if(tokenDecoded){
    return tokenDecoded.data?.kindomhall;
  }
  return null;
}
export const getCongregationFromToken = () =>{
  let token = window.localStorage.getItem(ID_TOKEN_KEY);
  let tokenDecoded  =  !!token ? jwt_decode(token) : null;
  if(tokenDecoded){
    return tokenDecoded.data?.congregation;
  }
  return null;
}

export default { getToken, saveToken, destroyToken, getTokenDecoded, getUserFromToken, getProfileFromToken, getKindomhallFromToken, getCongregationFromToken };
