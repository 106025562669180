<template>
  <v-app style="width: 0px;height: 0px;">
    <ConfirmDialog :prop="dialogObject"/>
    <v-dialog v-model="show" :attach="true" persistent max-width="1000px" >
      <v-card>
        <v-card-title>
          <span class="headline">Assegnazione Congregazione a Sala del Regno - {{kindomhallName}}</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 30px;">
          <v-container>
            <v-form ref="congregation_form"  lazy-validation v-if="canSave">
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      dense
                      :readonly="!Utils.canSave()"
                      label="Nome congregazione*"
                      :rules="nameRules"
                      :clearable="Utils.canSave()"
                      v-model="congregationObj.name"/>
                </v-col>
              </v-row>
            </v-form>

            <v-card-actions v-if="canSave">
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="reset">Cancella</v-btn>
              <v-btn color="blue darken-1" text @click="saveLocallyDialog">{{ congregationObj.id ? 'Aggiorna' : 'Salva'}}</v-btn>
            </v-card-actions>
            <hr v-if="canSave">
            <div>
              <div class="row">
                <div class="col-12">
                  <CustomBTable
                      :items="congregationList"
                      :per-page="5"
                      :headers="headers"
                      :buttons="buttons"/>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CommonMixins from "../../../mixins/CommonMixins";
import {Utils} from "@/utils/utils";
import CustomBTable from "@/assets/js/components/CustomBTable";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import _ from "lodash";

export default {
  name: "CongregationSheetDialog",
  components: {ConfirmDialog, CustomBTable},
  props:{
    show:Boolean,
    closeDialog:Function,
    idKindomhall:Number|String,
    kindomhallName:String
  },
  mixins:[
    CommonMixins
  ],
  data(){
    return {
      dialogObject:{ dialog:false},
      Utils,
      isUpdating:false,
      congregationObj:{},
      congregationList: [],
      nameRules: [ v => !!v || 'Campo obbligatorio'],
      headers:[
        {label: 'Congregazione', key: 'name', sortable:true},
        {label: 'Sala del Regno', key: 'kindomhallName', sortable:true},
        { key: 'action', label: "Azioni", tdClass:'width-175'}
      ]
    }
  },
  computed:{
    canSave(){
      return Utils.isAdminAll() || (Utils.canEditKindomhall() && this.idKindomhall == this.$store.getters.kindomhall.id);
    },
    buttons(){
      if(this.canSave){
        return [
          {title:'Modifica', style:'outline-warning', action:(row)=>{this.congregationObj = _.clone(row.item);}},
          {title:'Elimina', style:'outline-dark', action: this.remove}
        ]
      }
    }
  },
  watch:{
    show(){
      this.congregationObj = {};
      if(this.$refs.congregation_form)
        this.$refs.congregation_form.resetValidation();
      if(this.show && !!this.idKindomhall){
        this.searchCongregations();
      }
    }
  },
  methods:{
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    searchCongregations(){
      ApiService.get('/getCongregationByKindomhall?idKindomhall='+this.idKindomhall)
          .then(({data}) => {
            this.congregationList = !!data ? data : [];
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    saveLocallyDialog() {
      console.log('CongregationEditDialog::saveLocallyDialog start ...');
      console.log('CongregationEditDialog::saveLocallyDialog form exist -> ' + !!this.$refs.congregation_form);
      if(this.$refs.congregation_form.validate()) {
        this.dialogObject = this.getModalObject(
            () => {
              this.doSave();
              this.hideDialog();
            },
            () => {
              this.hideDialog()
            }
        );
      }
    },
    doSave(){
      if(this.$refs.congregation_form.validate()) {
        let action = !!this.congregationObj.id ? '/updateCongregation' : '/saveCongregation';
        this.congregationObj.idKindomhall = this.idKindomhall;
        ApiService.post(action, this.congregationObj)
            .then(({data}) => {
              if (!!data && data.success) {
                this.searchCongregations();
                this.showToast('Salvataggio Congregazione andato a buon fine');
                this.congregationObj = {}
                this.$refs.congregation_form.resetValidation();
              }
            })
            .catch(response => {
              this.checkResponse(response);
            });
      }
    },
    remove(row){
      console.log('CongregationEditDialog::remove start ...');
      this.dialogObject = this.getModalObject(
          () => {
            this.doRemove(row);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    doRemove(row){
      ApiService.get('/deleteCongregation?id='+ row.item.id)
          .then(({data}) => {
            if(!!data && data.success) {
              this.searchCongregations();
              this.showToast('Cancellazione Congregazione andata a buon fine');
              this.congregationObj = {};
              this.$refs.congregation_form.resetValidation();
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    reset(){
      this.congregationObj = {};
      this.$refs.congregation_form.resetValidation();
    }
  }
}
</script>