<template>
  <div class="d-flex flex-column flex-root">
    <!-- begin:: Header Mobile -->
    <KTHeaderMobile></KTHeaderMobile>
    <div class="d-flex flex-row flex-column-fluid page">
      <KTAside></KTAside>
      <div class="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
        <KTHeader></KTHeader>
        <div class="content  d-flex flex-column flex-column-fluid"
            id="kt_content">
          <KTSubheader v-if="!!$store.getters.currentProfile && $store.getters.currentProfile.id != OPERATORE" v-show="$store.getters.currentPanel == DASHBOARD"/>
          <div class="d-flex flex-column-fluid" :class="{'pt-5':!!$store.getters.currentProfile && $store.getters.currentProfile.id == OPERATORE || $store.getters.currentPanel != DASHBOARD}">
            <div :class="{
                'container-fluid': contentFluid,
                container: !contentFluid
              }">
              <transition name="fade-in-up">
                <Component :is="$store.getters.currentPanel"/>
              </transition>
            </div>
          </div>
        </div>
        <KTFooter></KTFooter>
        <KTScrollTop></KTScrollTop>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HtmlClass from "@/core/services/htmlclass.service";
import KTAside from "@/view/layout/aside/Aside.vue";
import KTHeaderMobile from "@/view/layout/header/HeaderMobile";
import Loader from "@/view/layout/content/Loader";
import KTHeader from "@/view/layout/header/Header";
import KTSubheader from "@/view/layout/subheader/Subheader";
import KTFooter from "@/view/layout/footer/Footer";
import KTStickyToolbar from "@/view/layout/extras/StickyToolbar.vue";
import KTScrollTop from "@/view/layout/extras/ScrollTop";
import {OPERATORE} from "../../utils/constants";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module.js";
import Dashboard from "../pages/app/Dashboard";
import Schede from "../pages/app/schede/Schede";
import Utenti from "../pages/app/utenti/Utenti";
import Congregazioni from '../pages/app/congregazioni/Congregazioni'
import Rischio from '../pages/app/rischio/Rischio'
import Impostazioni from '../pages/app/Impostazioni'
import ExcelGlobale from '../pages/app/ExcelGlobale'
import {DASHBOARD} from '../../core/services/store/panelStore';

export default {
  name: "Layout",
  components: {
    Dashboard,
    Schede,
    ExcelGlobale,
    Utenti,
    Congregazioni,
    Rischio,
    Impostazioni,
    KTAside,
    KTHeaderMobile,
    Loader,
    KTHeader,
    KTFooter,
    KTSubheader,
    KTStickyToolbar,
    KTScrollTop
  },
  data(){
    return{
      DASHBOARD,
      OPERATORE
    }
  },
  beforeMount() {
    // show page loading
    this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

    // initialize html element classes
    HtmlClass.init(this.layoutConfig());
  },
  mounted() {
    // check if current user is authenticated
    if (!this.isAuthenticated) {
      this.$router.push({ name: "login" });
    }

    // Simulate the delay page loading
    setTimeout(() => {
      // Remove page loader after some time
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }, 2000);
  },
  computed: {
    ...mapGetters([
      "isAuthenticated",
      "breadcrumbs",
      "pageTitle",
      "layoutConfig"
    ]),

    /**
     * Check if the page loader is enabled
     * @returns {boolean}
     */
    loaderEnabled() {
      return !/false/.test(this.layoutConfig("loader.type"));
    },

    /**
     * Check if container width is fluid
     * @returns {boolean}
     */
    contentFluid() {
      return this.layoutConfig("content.width") === "fluid";
    },

    /**
     * Page loader logo image using require() function
     * @returns {string}
     */
    loaderLogo() {
      return process.env.BASE_URL + this.layoutConfig("loader.logo");
    },

    /**
     * Set the right toolbar display
     * @returns {boolean}
     */
    toolbarDisplay() {
      // return !!this.layoutConfig("toolbar.display");
      return true;
    }
  }
};
</script>
