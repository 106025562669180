
export default class QuestionAnswer{
    constructor(props) {
        this.idSheet            = !!props ? props.idSheet           : null;
        this.questionId         = !!props ? props.questionId        : null;
        this.topicId            = !!props ? props.topicId           : null;
        this.internalSheetCode  = !!props ? props.internalSheetCode : null;
        this.sheetCode          = !!props ? props.sheetCode         : null;
        this.topicName          = !!props ? props.topicName         : null;
        this.question           = !!props ? props.question          : null;
        this.answer             = !!props ? props.answer            : null;
        this.note               = !!props ? props.note              : null;
        this.checkDate          = !!props ? props.checkDate         : null;
    }
}