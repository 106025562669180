import {Utils} from "@/utils/utils";


export class RiskAnalyses{
    constructor(props) {
        this.id                 = props ? props.id : null;
        this.idMaintenanceSheet = props ? props.idMaintenanceSheet : null;
        this.activityLocation   = props ? props.activityLocation : null;
        this.activityDesc       = props ? props.activityDesc : null;
        this.idUserCompiled     = props ? props.idUserCompiled : null;
        this.userCompiled       = props ? props.userCompiled : null;
        this.idUserChecked      = props ? props.idUserChecked : null;
        this.userChecked        = props ? props.userChecked : null;
        this.dateStart          = props && props.dateStart ? Utils.getFormattedDate(props.dateStart) : null;
        this.dateCompiled       = props && props.dateCompiled ? Utils.getFormattedDate(props.dateCompiled) : null;
        this.dateChecked        = props && props.dateChecked ? Utils.getFormattedDate(props.dateChecked) : null;
        this.emergencyContact   = props ? props.emergencyContact : null;
        this.activitySequence1  = props ?  props.activitySequence1 : null;
        this.risk1              = props ?  props.risk1 : null;
        this.checkMethods1      = props ?  props.checkMethods1 : null;
        this.activitySequence2  = props ?  props.activitySequence2 : null;
        this.risk2              = props ?  props.risk2 : null;
        this.checkMethods2      = props ?  props.checkMethods2 : null;
        this.activitySequence3  = props ?  props.activitySequence3 : null;
        this.risk3              = props ?  props.risk3 : null;
        this.checkMethods3      = props ?  props.checkMethods3 : null;
    }

    get steps() {
        let arr = [];
        if(!!this.activitySequence1 || !!this.risk1 || !! this.checkMethods1)
            arr.push({activitySequence: this.activitySequence1, risk: this.risk1, checkMethods: this.checkMethods1});
        if(!!this.activitySequence2 || !!this.risk2 || !! this.checkMethods2)
            arr.push({activitySequence: this.activitySequence2, risk: this.risk2, checkMethods: this.checkMethods2});
        if(!!this.activitySequence3 || !!this.risk3 || !! this.checkMethods3)
            arr.push({activitySequence: this.activitySequence3, risk: this.risk3, checkMethods: this.checkMethods3});

        return arr;
    }



}