<template>
  <div>
    <KTCard id="globalView" ref="preview" >
      <template v-slot:title >
        <h3 class="card-title">
          Excel Globale
        </h3>
      </template>
      <template v-slot:body>
        <iframe
            src="https://docs.google.com/spreadsheets/d/14VoAdakHxKARMSwoweKftn9y9LAci8RZ/edit#gid=837711348"
        style="width:100%;height: 100%;"
        ></iframe>
      </template>
    </KTCard>
  </div>
</template>

<script>
import CommonMixins from "../mixins/CommonMixins";
import KTCodePreview from "../../content/CodePreview";
import KTCard from "../../content/Card";
export default {
  name: "ExcelGlobale",
  components:{
    KTCard,
    KTCodePreview
  },
  data(){
    return {
    }
  },
  mixins:[
    CommonMixins
  ],
  computed:{

  },
  methods:{

  }

}
</script>

<style>
#globalView .card-body{
  height: 100vh!important;
}

</style>