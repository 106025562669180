import {Answer} from "./Answer";


export class Question{
    constructor(props) {
        this.idQuestion     = props ? props.idQuestion      : null;
        this.idTopic        = props ? props.idTopic         : null;
        this.question       = props ? props.question        : null;
        this.position       = props ? props.position        : 0;
        this.answer         = new Answer(props.answer);
    }
}