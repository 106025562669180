<template>
  <v-dialog
      v-model="prop.dialog"
      max-width="490">
    <v-card>
      <v-card-title class="text-h5">
        {{prop.title}}
      </v-card-title>

      <v-card-text class="text-h6" v-html="prop.text">
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
            color="green darken-1"
            text
            v-if="!!prop.cancel"
            @click="prop.cancel">
         {{ prop.cancelLabel ? prop.cancelLabel : 'Annulla'}}
        </v-btn>

        <v-btn
            color="green darken-1"
            text
            @click="prop.ok">
          {{ prop.okLabel ? prop.okLabel : 'Continua'}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  props: {
    prop: {type: Object, required: true}
  }
}
</script>

<style scoped>

</style>