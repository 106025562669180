<template>
  <div>
    <v-autocomplete
        v-model="userLinked"
        :items="userTotal"
        filled
        chips
        dense
        color="blue-grey lighten-2"
        :label="label"
        :disabled="disabled"
        item-text="username"
        item-value="id">
      <template v-slot:selection="data">
        <v-chip
            v-bind="data.attrs"
            :input-value="data.selected"
            close
            :disabled="disabled"
            @click:close="remove(data.item)">
          {{ data.item.username }}
        </v-chip>
      </template>
      <template v-slot:item="data">
        <template v-if="typeof data.item !== 'object'">
          <v-list-item-content v-text="data.item"  @click="()=>add(data.item)"></v-list-item-content>
        </template>
        <template v-else >
          <div  @click="()=>add(data.item)">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.username"></v-list-item-title>
              <v-list-item-subtitle v-html="data.item.congregationName"></v-list-item-subtitle>
            </v-list-item-content>
          </div>
        </template>
      </template>
    </v-autocomplete>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CommonMixins from "@/view/pages/mixins/CommonMixins";

export default {
  name: "UserAutocomplete",
  mixins:[CommonMixins],
  props:{
    idKindomhall:Number|String,
    idSheet:{ type:Number|String, default: -1},
    setUser:Function,
    idUser:Number|String,
    label:String,
    disabled:{type:Boolean, default:false}
  },
  data(){
    return{
      userTotal: [],
      userLinked:undefined
    }
  },
  watch:{
    idKindomhall(){
      this.getUserList();
    },
    idSheet(){
      this.getUserList();
    },
    idUser(){
      console.log('idUser -> ' + this.idUser)
      this.prefillField();
    }
  },
  methods:{
    getUserList(){
      console.log('UserAutocomplete::getUserList idKindomhall->' + this.idKindomhall);
      console.log('UserAutocomplete::getUserList userId->' + this.idUser);
      if(!!this.idKindomhall)
        ApiService.get('/getUserToLink?idKindomhall='+this.idKindomhall + '&idSheet='+this.idSheet)
            .then(({data}) => {
              this.userTotal    = !!data ? data.userToLink : [];
              if(this.userTotal && this.userTotal.length > 0 && !!this.idUser){
                let id = this.userTotal.findIndex(t=>t.id==this.idUser);
                if(id >=0) this.userLinked = this.idUser;
              }
            })
            .catch(response => {
              this.checkResponse(response);
            });
    },
    remove(item){
      this.makeAction('remove', item);
    },
    add(item){
      this.makeAction('add', item);
    },
    makeAction(action, item) {
      console.log('UserAutocomplete::makeAction start ...');
      console.log('UserAutocomplete::makeAction item ->' + JSON.stringify(item));
      if (action === 'add') {
        console.log('UserAutocomplete::makeAction -> add');
        this.setUser(item);
      } else if (action === 'remove') {
        console.log('UserAutocomplete::makeAction -> remove');
        this.setUser(undefined);
        this.userLinked = undefined;
      }
    },
    prefillField(){
      this.userLinked = this.idUser;
    }
  },
  mounted() {
    console.log('UserAutocomplete::mounted start ...');
    console.log('UserAutocomplete::mounted id user ->' +  this.idUser);
    this.getUserList()
    this.prefillField();
  }
}
</script>

<style scoped>

</style>