

export class User{
    constructor(props) {
        this.id                 = props ? props.id              : null;
        this.idRefer            = props ? props.idRefer         : null;
        this.idRole             = props ? props.idRole          : null;
        this.idUserRole         = props ? props.idUserRole      : null;
        this.idCongregation     = props ? props.idCongregation  : null;
        this.idKindomhall       = props ? props.idKindomhall    : null;
        this.username           = props ? props.username        : null;
        this.email              = props ? props.email           : null;
        this.phone              = props ? props.phone           : null;
        this.usernameRefer      = props ? props.usernameRefer   : null;
        this.idTypeUser         = props ? props.idTypeUser      : null;
        this.typeUser           = props ? props.typeUser        : null;
        this.congregationName   = props ? props.congregationName: null;
        this.kindomhallName     = props ? props.kindomhallName  : null;
        this.sex                = props ? props.sex             : null;
        this.password           = props ? props.password        : null;
        this.info               = props ? props.info            : null;
        this.groupList          = props ? props.groupList       : null;
    }
}