<template>
  <v-app style="width: 0px;height: 0px;">
    <ConfirmDialog :prop="dialogObject"/>
    <v-dialog v-model="show" persistent :attach="true" max-width="700px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ !!kindomhall && !!kindomhall.id ? 'Modifica' : 'Crea' }} Sala del Regno</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 30px;">
          <v-container>
            <v-form ref="form_kindomhall"  lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      dense
                      label="Nome*"
                      placeholder="Milano"
                      required
                      :readonly="!canSave"
                      :clearable="canSave"
                      v-model="kindomhall.name"
                      :rules="nameRules"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      dense
                      name="address_kindomhall"
                      label="Indirizzo*"
                      placeholder="Via della Bufalotta 23, 22222, Roma"
                      required
                      :readonly="!canSave"
                      :clearable="canSave"
                      v-model="kindomhall.address"
                      :rules="nameRules"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      dense
                      name="password_kindomhall"
                      :type="pswVisible ? 'text' : 'password'"
                      label="Password*"
                      required
                      :readonly="!canSave"
                      :clearable="canSave"
                      v-model="kindomhall.passwordVisible"
                      :append-icon="pswVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="pswVisible=!pswVisible"
                      :rules="nameRules"/>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>I campi indicati con * sono obbligatori</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLocallyDialog">Chiudi</v-btn>
          <v-btn color="blue darken-1"  v-if="canSave" text @click="saveLocallyDialog">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>

import {Utils} from '@/utils/utils';
import CommonMixins from "../../../mixins/CommonMixins";
import ApiService from "@/core/services/api.service";
import {SUPER_ADMIN} from "@/utils/constants";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import _ from "lodash";
export default {
  name: "KindomhallEditDialog",
  components: {ConfirmDialog},
  props:{
    show: { type:Boolean, default: ()=>false},
    closeDialog: Function,
    saveDialogDone:Function,
    kindomhallSelected: Object
  },
  mixins:[CommonMixins],
  watch:{
    show(){
      if(this.show){
        if(this.$refs.form_kindomhall)
          this.$refs.form_kindomhall.resetValidation();
        if(this.kindomhallSelected)
          this.kindomhall                 = _.clone(this.kindomhallSelected)
          this.kindomhall.passwordVisible = Utils.decrypt(this.kindomhall.password);
      }
    }
  },
  computed:{
    canSave(){
      return Utils.isAdminAll() || (Utils.canEditKindomhall() && !!this.kindomhallSelected && this.kindomhallSelected.id == this.$store.getters.kindomhall.id);
    }
  },
  data(){
    return{
      SUPER_ADMIN,
      pswVisible:false,
      dialogObject:{ dialog:false},
      Utils,
      kindomhall:{},
      nameRules: [ v => !!v || 'Campo obbligatorio']
    }
  },
  methods:{
    hideDialog(){
      this.dialogObject.dialog = false;
    },
     saveLocallyDialog() {
      if(this.$refs.form_kindomhall.validate()) {
        this.dialogObject = this.getModalObject(
            () => {
              this.doSave();
              this.hideDialog();
            },
            () => {
              this.hideDialog()
            }
        );
      }
    },
    doSave(){
      console.log('KindomhallEditDialog::saveLocallyDialog start ...');
      if(this.$refs.form_kindomhall.validate()){
        this.kindomhall.password = Utils.encrypt(this.kindomhall.passwordVisible);
        delete this.kindomhall.passwordVisible;
        console.log('KindomhallEditDialog::saveLocallyDialog Kindomhall to save -> ' + JSON.stringify(this.kindomhall));

        ApiService.post(!!this.kindomhall.id ? '/updateKindomhall' : '/saveKindomhall', this.kindomhall)
            .then(({data}) => {
              if (data && data.success) {
                this.showToast('Salvataggio Scheda andato a buon fine');
                this.kindomhall = {};
                this.pswVisible = false;
                this.saveDialogDone();
              }
            })
            .catch(response => {
              this.checkResponse(response)
            });
      }
    },
    closeLocallyDialog() {
      this.kindomhall = {};
      this.pswVisible = false;
      this.closeDialog();
    }
  }
}
</script>
