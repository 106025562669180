<template>
    <v-app style="width: 0px;height: 0px;">
        <ConfirmDialog :prop="dialogObject"/>
        <v-dialog v-model="show" persistent :attach="true" max-width="700px">
            <v-card>
                <v-card-title>
                    <span class="headline">{{ !!sheet && !!sheet.id ? 'Modifica' : 'Crea' }} Scheda</span>
                </v-card-title>
                <hr>
                <v-card-text style="margin-top: 30px;">
                    <v-container>
                        <v-form ref="sheet_save"  lazy-validation>
                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        :disabled="$store.getters.currentProfile.id != SUPER_ADMIN"
                                        dense
                                        :items="kindomhalls"
                                        label="Sala del Regno*"
                                        :rules="nameRules"
                                        :clearable="Utils.isAdminAll()"
                                        v-model="sheet.idKindomhall"
                                        required/>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Codice cartolina*"
                                        placeholder="C-1"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.sheetCode"
                                        :rules="nameRules"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Codice Inteno cartolina*"
                                        placeholder="4.1.01"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.internalSheetCode"
                                        :rules="nameRules"/>
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        dense
                                        :attach="true"
                                        :readonly="!Utils.canSave()"
                                        :items="colors"
                                        label="Colore intestazione*"
                                        :rules="nameRules"
                                        v-model=" sheet.bgColor"
                                        required/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dense
                                        label="Titolo cartolina*"
                                        placeholder="Impianti elettrici / Audio video C-3 Contatore elettrico"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :rules="nameRules"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.sheetTitle"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Intervallo mesi*"
                                        placeholder="5"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :rules="nameRules.concat(nameNumberRules)"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.intervalMonth"/>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Ore necessarie*"
                                        placeholder="0.5"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :rules="nameRules.concat(nameNumberRules)"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.hourNeeded"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        dense
                                        label="Num. Operatori*"
                                        placeholder="2"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :rules="nameRules.concat(nameNumberRules)"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.numOperator"/>
                                </v-col>
                                <v-col cols="6">
                                    <v-checkbox
                                        dense
                                        v-model="sheet.extAgencyBool"
                                        label="Agenzia esterna necessaria"
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dense
                                        label="Info ditta esterna"
                                        placeholder=""
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.extAgencyName"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12">
                                    <v-text-field
                                        dense
                                        label="Ubicazione*"
                                        placeholder="Sotto la scala"
                                        required
                                        :readonly="!Utils.canSave()"
                                        :rules="nameRules"
                                        :clearable="Utils.canSave()"
                                        v-model="sheet.location"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-textarea
                                        dense
                                        title="ES: Prima di eseguire gli interventi di questa scheda di manutenzione .."
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        label="Info"
                                        v-model="sheet.info"/>
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea
                                        dense
                                        title="ES: ALTO RISCHIO! Gli interventi di questa scheda possono richiedere patentini obbligatori. Delimitare l’area di attività."
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        label="Info di pericolo"
                                        v-model="sheet.warning"/>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="6">
                                    <v-textarea
                                        dense
                                        title="ES: NOTA: Se necessaria la sostituzione, deve essere eseguita da un Tecnico abilitato cosi da valutare quali elementi risultano guasti. "
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        label="Info aggiuntive"
                                        v-model="sheet.otherInfo"/>
                                </v-col>
                                <v-col cols="6">
                                    <v-textarea
                                        dense
                                        title="ES: Ricordarsi di ...."
                                        :readonly="!Utils.canSave()"
                                        :clearable="Utils.canSave()"
                                        label="Note"
                                        v-model="sheet.note"/>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                    <small>I campi indicati con * sono obbligatori</small>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeLocallyDialog">Chiudi</v-btn>
                    <v-btn color="blue darken-1" v-if="Utils.canSave()" text @click="saveLocallyDialog">Salva</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-app>
</template>

<script>

import {Utils} from '../../../../../utils/utils';
import CommonMixins from "../../../mixins/CommonMixins";
import ApiService from "../../../../../core/services/api.service";
import {SUPER_ADMIN} from "@/utils/constants";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
export default {
    name: "UserEditDialog",
    components: {ConfirmDialog},
    props:{
        show: { type:Boolean, default: ()=>false},
        closeDialog: Function,
        saveDialogDone:Function,
        idSheet: Number|String
    },
    mixins:[CommonMixins],
    watch:{
        show(){
            if(this.show){
                if(this.$refs.sheet_save)
                    this.$refs.sheet_save.resetValidation();
                this.searchKindomhalls()
            }
        }
    },
    data(){
        return{
            SUPER_ADMIN,
            dialogObject:{ dialog:false},
            Utils,
            sheet:{},
            kindomhalls:[],
            colors:[{text:'Giallo', value:'yellow'}, {text:'Blue', value:'blue'}, {text:'Rosso', value:'lightcoral'}, {text:'Verde', value:'green'}, {text:'Viola', value:'purple'},],
            nameRules: [ v => !!v || 'Campo obbligatorio'],
            nameNumberRules: [ v => !isNaN(v) || 'Il campo deve essere numerico'],
        }
    },
    methods:{
        hideDialog(){
            this.dialogObject.dialog = false;
        },
        searchKindomhalls(){
            console.log('SheetEditDialog::searchKindomhalls start ...');
            ApiService.get('/getAllKindomhalls')
                .then(({data}) => {
                    this.kindomhalls = !!data ? data : [];
                    console.log('SheetEditDialog::searchKindomhalls got items ...');
                    if(!!this.idSheet){
                        console.log('SheetEditDialog::searchKindomhalls id sheet valued -> get the sheet');
                        ApiService.get('/getMaintenanceSheetFromId?id='+ this.idSheet).then(({data}) => {
                            if (!!data && !!data.sheet) {
                                this.sheet = data.sheet;
                                this.sheet.extAgencyBool = this.sheet.extAgency === 'Y';
                                console.log('SheetEditDialog::searchKindomhalls  sheet got');
                            }
                        }).catch(response => {
                            this.checkResponse(response)
                        });
                    }else{
                        this.sheet.idKindomhall = this.$store.getters.kindomhall.id;
                    }
                })
                .catch(response => {
                    this.checkResponse(response);
                });
        },
        changeKindomhallomhall(e) {
            if(this.$store.getters.currentProfile.id != SUPER_ADMIN){
                if(e != this.$store.getters.kindomhall.id){
                    this.kindomhallMaintenance.idKindomhall = null;
                    this.showToast('Non hai i permessi per operare con un Sala del Regno a cui non appartieni', 'warning', 'Attenzione');
                    this.$refs.sheet_save.resetValidation();
                }
            }
        },
        saveLocallyDialog() {
            if(this.$refs.sheet_save.validate()) {
                this.dialogObject = this.getModalObject(
                    () => {
                        this.doSave();
                        this.hideDialog();
                    },
                    () => {
                        this.hideDialog()
                    }
                );
            }
        },
        doSave(){
            console.log('SheetEditDialog::saveLocallyDialog start ...');
            if(this.$refs.sheet_save.validate()){
                //Impianti elettrici / Audio video C-5 Interruttori prese di corrente
                this.sheet.sheetName = this.sheet.sheetTitle.split(this.sheet.sheetCode)[1];
                this.sheet.extAgency = !!this.sheet.extAgencyBool ? 'Y' : 'N';

                console.log('SheetEditDialog::saveLocallyDialog sheet to save -> ' + JSON.stringify(this.sheet));

                ApiService.post(!!this.idSheet ? '/updateMaintenanceSheet' : '/saveMaintenanceSheet', this.sheet)
                    .then(({data}) => {
                        if (data && data.success) {
                            this.showToast('Salvataggio Scheda andato a buon fine');
                            this.sheet = {};
                            this.saveDialogDone();
                        }
                    })
                    .catch(response => {
                        this.checkResponse(response)
                    });
            }
        },
        closeLocallyDialog() {
            this.sheet    = {};
            this.closeDialog();
        }
    }
}
</script>
