<template>
  <div>
    <div
        class="subheader py-5 py-lg-10 gutter-b subheader-transparent subheader-background"
        id="kt_subheader"
        v-if="headerMenuEnabled"
        style="background-image: url('media/svg/patterns/taieri.svg');"
    >
      <div
          class="d-flex flex-column"
          v-bind:class="{ 'container-fluid': widthFluid, container: !widthFluid }"
      >
        <!--begin::Title-->
        <div class="d-flex align-items-sm-end flex-column flex-sm-row mb-5">
          <h2 class="text-white mr-5 mb-0">Inserisci Sala del Regno/Congregazione </h2>
          <span class="text-white opacity-60 font-weight-bold"
                v-if="!!$store.getters.kindomhallSelected">
            Stai visualizzando la Sala del Regno di {{$store.getters.kindomhallSelected.name}}
            {{ !!$store.getters.congregationSelected &&!!$store.getters.congregationSelected.id ?  '- Congregazione di ' + $store.getters.congregationSelected.name : ''}}</span>
        </div>
        <!--end::Title-->
        <!--begin::Search Bar-->
        <div class="d-flex align-items-md-center mb-2 flex-column flex-md-row">
          <div class="bg-white rounded p-4 d-flex flex-grow-1 flex-sm-grow-0">
            <!--begin::Form-->
            <form
                class="form d-flex align-items-md-center flex-sm-row flex-column flex-grow-1 flex-sm-grow-0"
            >
              <!--begin::Input-->
              <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/General/Search.svg-->
                  <inline-svg src="media/svg/icons/General/Search.svg" />
                  <!--end::Svg Icon-->
                </span>
                <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    label="Sala del Regno"
                    :disabled="!Utils.isAdminAll()"
                    v-model="idKindomhall"
                    :options="kindomhalls"
                    @change="changeKindomhall"/>
              </div>
              <!--end::Input-->
              <!--begin::Input-->
              <span
                  class="bullet bullet-ver h-25px d-none d-sm-flex mr-2"
              ></span>
              <div class="d-flex align-items-center py-3 py-sm-0 px-sm-3">
                <span class="svg-icon svg-icon-lg">
                  <!--begin::Svg Icon | path:assets/media/svg/icons/Layout/Layout-4-blocks.svg-->
                  <inline-svg
                      src="media/svg/icons/Layout/Layout-4-blocks.svg"
                  />
                  <!--end::Svg Icon-->
                </span>
                <b-form-select
                    class="mb-2 mr-sm-2 mb-sm-0"
                    label="Congregazione"
                    :disabled="Utils.canWatchJustCongregation()"
                    v-model="idCongregation"
                    :options="congregations"
                    @change="changeCongregation"/>

              </div>
              <!--end::Input-->
              <div v-if="Utils.canWatchKindomhall()">
                <button
                    @click="updateDashboard"
                    type="submit"
                    class="btn btn-dark font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7" :class="Utils.canEditKindomhall() ? 'col-6':'col-12'">
                  Aggiorna
                </button>
                <button
                    style="margin-left: 5px;"
                    v-if="Utils.canEditKindomhall()"
                    @click="sendExpiredMail"
                    type="button"
                    class="btn btn-dark font-weight-bold btn-hover-light-primary mt-3 mt-sm-0 px-7  col-5">
                  Mail
                </button>
              </div>
            </form>
            <!--end::Form-->
          </div>
        </div>
        <!--end::Search Bar-->
      </div>
    </div>
    <div v-if="!headerMenuEnabled" class="gutter-b"></div>
    <ConfirmDialog :prop="dialogObject"/>
  </div>
</template>

<style>
.subheader-background {
  background-color: #663259;
  background-position: right bottom;
  background-size: auto 100%;
  background-repeat: no-repeat;
}
</style>

<script>
import { mapGetters } from "vuex";
import ApiService from "../../../core/services/api.service";
import CommonMixins from "../../pages/mixins/CommonMixins";
import {CONGREGATION_SELECTED, KINDOMHALL_SELECTED} from "../../../core/services/store/auth.module";
import {BusEvent} from "../../../utils/busEvent";
import {Kindomhall} from "../../../models/Kindomhall";
import {Congregation} from "../../../models/Congregation";
import {Utils} from "@/utils/utils";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";

export default {
  name: "Subheader",
  components: {ConfirmDialog},
  mixins:[CommonMixins],
  data(){
    return {
      Utils,
      idKindomhall: null,
      idCongregation:null,
      kindomhalls:[],
      congregations:[],
      dialogObject:{ dialog:false}
    }
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("subheader.display");
    },

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    }
  },
  methods:{
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    sendExpiredMail(){
      this.dialogObject = this.getModalObject(
          ()=>{
            this.reallySendExpiredMail();
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    reallySendExpiredMail(){

      console.log(JSON.stringify(this.$store.getters.kindomhallSelected));
      console.log(JSON.stringify(this.$store.getters.congregationSelected));
      let congregation = (!!this.$store.getters.congregationSelected &&!!this.$store.getters.congregationSelected.id) ? '&idCongregation='+this.$store.getters.congregationSelected.id : '';
      ApiService.get('/sendMailToRefer?idKindomhall=' + this.$store.getters.kindomhallSelected.id + congregation)
          .then(({data}) => {
            console.log(data);
            this.showToast('Le email sono state inviate correttamente');
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    async changeKindomhall(e) {
      return new Promise (resolve =>{
        if (!!!e) {
          this.$store.dispatch(KINDOMHALL_SELECTED, {});
          this.congregations = [];
          return;
        }
        console.log(e);
        ApiService.get('/getCongregationsByKindomhallAndRole?idKindomhall=' + e)
            .then(({data}) => {
              console.log(data);
              this.congregations = !!data ? data : [];
              this.congregations.unshift({text:'Tutte le congregazioni della Sala del Regno', value:null});
              this.$store.dispatch(KINDOMHALL_SELECTED, Kindomhall.fromCombo(this.kindomhalls.filter(t=>t.value==e)[0]));
              resolve(true);
            })
            .catch(response => {
              this.checkResponse(response);
              resolve(false);
            });
      });

    },
    changeCongregation(e) {
      let congregation = {};
      if (!!e) {
        congregation = Congregation.fromCombo(this.congregations.filter(t=>t.value==e)[0]);
      };
      this.$store.dispatch(CONGREGATION_SELECTED, congregation);
    },
    initializeSelect(){
      console.log("Subheader::initializeSelect start ...");
      console.log("Subheader::initializeSelect kindomhall selected -> " + JSON.stringify(this.$store.getters.kindomhallSelected));
      console.log("Subheader::initializeSelect congregation selected -> " + JSON.stringify(this.$store.getters.congregationSelected));
      ApiService.get('/getAllKindomhalls')
          .then(async ({data}) => {
            this.kindomhalls    = !!data ? data : [];
            console.log("Subheader::initializeSelect kindomhalls got");
            console.log("Subheader::initializeSelect handle kindomhall");
            this.handleInitKindomhall();
            console.log("Subheader::initializeSelect handle congregation");
            this.handleInitCongregation();
          })
          .catch(response => {
            this.checkResponse(response)
          });
    },
    handleInitKindomhall(){
      console.log("Subheader::handleInitKindomhall start..");
     if(Utils.canWatchJustKindomhall()){
        console.log("Subheader::handleInitKindomhall can watch kindomhall..");
        this.kindomhalls=[{value: this.$store.getters.kindomhallSelected.id, text: this.$store.getters.kindomhallSelected.name}]
        this.idKindomhall   = this.$store.getters.kindomhallSelected.id;
        this.changeKindomhall(this.idKindomhall);
      }else{
       this.kindomhalls.unshift({text:'Tutte le Sale del Regno', value:null});
     }
    },
    async handleInitCongregation(){
      console.log("Subheader::handleInitCongregation start..");
      if(Utils.canWatchJustCongregation()){
        await this.changeKindomhall(this.$store.getters.kindomhallSelected.id);
        console.log("Subheader::handleInitCongregation can watch congregation..");
        this.kindomhalls=[{value: this.$store.getters.kindomhallSelected.id, text: this.$store.getters.kindomhallSelected.name}];
        this.congregations=[{value: this.$store.getters.congregationSelected.id, text: this.$store.getters.congregationSelected.name}];
        this.idKindomhall   = this.$store.getters.kindomhallSelected.id;
        this.idCongregation = this.$store.getters.congregationSelected.id;
      }
    },
    updateDashboard(e){
      e.preventDefault();
      console.log(JSON.stringify(this.$store.getters.kindomhallSelected));
      console.log(JSON.stringify(this.$store.getters.congregationSelected));
      BusEvent.refreshDashboard();
    }
  },
  created(){
    this.initializeSelect();
  }
};
</script>
