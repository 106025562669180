<template>
  <div>
    <div class="row">
      <div class="col-xl-12">
        <SheetCounter/>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 ">
        <SheetExpiredTable />
      </div>
      <div class="col-xl-6">
        <SheetToInsertTable />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6 ">
        <!--SheetOkTable --/-->
          <SheetExpiringTable />
      </div>
      <div class="col-xl-6">
        <SheetIncompleteTable />
      </div>
    </div>
    <div class="row">
      <div class="col-xl-6">
        <SheetIssuedTable />
      </div>
      <div class="col-xl-6">
        <SheetSolvedTable/>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-12">
        <SheetIssuedChart/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SheetToInsertTable from "./section/SheetToInsertTable";
import SheetExpiredTable from "./section/SheetExpiredTable";
import SheetIssuedTable from "./section/SheetIssuedTable";
import SheetIssuedChart from "./section/SheetIssuedChart";
import SheetSolvedTable from "@/view/pages/dashboard/section/SheetSolvedTable";
import SheetIncompleteTable from "@/view/pages/dashboard/section/SheetIncompleteTable";
import SheetOkTable from "@/view/pages/dashboard/section/SheetOkTable";
import SheetCounter from "@/view/pages/dashboard/section/SheetCounter";
import SheetExpiringTable from "@/view/pages/dashboard/section/SheetExpiringTable.vue";

export default {
  name: "DashboardManutenzione",
  data() {
    return {
      list: [

      ]
    };
  },
  components: {
      SheetExpiringTable,
    SheetCounter,
    SheetOkTable,
    SheetIncompleteTable,
    SheetSolvedTable,
    SheetIssuedChart,
    SheetIssuedTable,
    SheetExpiredTable,
    SheetToInsertTable
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },

};
</script>
