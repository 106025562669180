<template>
  <v-app style="width: 0px;height: 0px;">
    <v-dialog v-model="show" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{ !! user.id ? 'Modifica' : 'Crea' }} Utente</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 10px;">
          <v-container>
            <v-form ref="user" lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                      dense
                      label="Nome e Cognome*"
                      required
                      :clearable="Utils.canSave()"
                      :readonly="!Utils.canSave()"
                      v-model="userObj.username"
                      :rules="nameRules"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                      dense
                      label="Email"
                      v-model="userObj.email"
                      :clearable="Utils.canSave()"
                      :readonly="!Utils.canSave()"/>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                      dense
                      label="Phone"
                      v-model="userObj.phone"
                      :clearable="Utils.canSave()"
                      :readonly="!Utils.canSave()"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                      dense
                      :items="[{value:'M', text:'Maschio'},{value:'F', text:'Femmina'}]"
                      label="Sesso*"
                      :rules="nameRules"
                      v-model="userObj.sex"
                      :readonly="!Utils.canSave()"
                      required/>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                      dense
                      name="password_user"
                      :type="pswVisible ? 'text' : 'password'"
                      label="Password*"
                      required
                      :readonly="!Utils.canSave()"
                      :clearable="Utils.canSave()"
                      v-model="userObj.passwordVisible"
                      :append-icon="pswVisible ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="viewPassword"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-select
                      dense
                      :items="typeUsers"
                      label="Tipo utente*"
                      :rules="nameRules"
                      v-model=" userObj.idTypeUser"
                      :readonly="!Utils.canSave()"
                      @change="checkUserType"
                      required/>
                </v-col>
                <v-col cols="6">
                  <v-select
                      dense
                      :items="roles"
                      label="Ruolo utente*"
                      :rules="nameRules"
                      v-model="userObj.idRole"
                      :readonly="!Utils.canSave()"
                      @change="checkUserRole"
                      required/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                      dense
                      :items="kindomhalls"
                      label="Sala del Regno*"
                      :rules="nameRules"
                      @change="changeCongregation"
                      v-model=" userObj.idKindomhall"
                      :readonly="!Utils.isAdminAll()"
                      required/>
                </v-col>
                <v-col cols="12">
                  <v-select
                      dense
                      :disabled="congregations.length === 0"
                      :items="congregations"
                      :rules="nameRules"
                      v-model="userObj.idCongregation"
                      :readonly="!Utils.canSave() || Utils.justEditCongregation()"
                      label="Congregazione*"
                      required/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                      dense
                      :readonly="!Utils.canSave()"
                      label="Informazioni aggiuntive"
                      :clearable="Utils.canSave()"
                      v-model="userObj.info"/>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
          <small>I campi indicati con * sono obbligatori</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeLocallyDialog">Chiudi</v-btn>
          <v-btn color="blue darken-1" text v-if="Utils.canSave()" @click="saveLocallyDialog">Salva</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import {Utils} from "../../../../utils/utils"
import ApiService from "../../../../core/services/api.service";
import CommonMixins from "../../mixins/CommonMixins";
import _ from "lodash";
import {OPERATORE} from "@/utils/constants";


export default {
  name: "UserEditDialog",
  props:{
    show: { type:Boolean, default: ()=>false},
    closeDialog: Function,
    saveDialogDone:Function,
    user:{ type: Object, default: ()=>{}}
  },
  data(){
    return{
      nameRules: [ v => !!v || 'Campo obbligatorio'],
      Utils,
      kindomhalls:[],
      congregations:[],
      roles:[],
      typeUsers:[],
      userObj:{idRole: null, idTypeUser: null},
      searchDone: false,
      pswVisible:false
    }
  },
  mixins:[CommonMixins],
  watch:{
    show(){
      this.preload();
    }
  },
  methods:{
    async preload(){
      if(this.show && !this.searchDone || !(this.kindomhalls.length > 0 && this.roles.length > 0 && this.typeUsers.length > 0)){
        await this.getDataForNewUser();
      }
      if(this.show  && !!this.user && !!this.user.id){
        this.userObj = _.clone(this.user);
        this.userObj.passwordVisible = Utils.decrypt(this.userObj.password);
        await this.changeCongregation(this.userObj.idKindomhall);
      }
      if(!!!this.user || !!!this.user.id){
        this.userObj.idKindomhall = this.$store.getters.kindomhall.id;
        await this.changeCongregation(this.$store.getters.kindomhall.id);
      }
      if(this.$refs.user)
        this.$refs.user.resetValidation();
    },
    saveLocallyDialog() {
      if(this.$refs.user.validate() && this.checkKindomhall()){
        delete this.userObj.passwordVisible;
        ApiService.post(!!this.userObj.id ? '/updateUser':'/saveUser', this.userObj)
            .then(({data}) => {
              if (data && data.success) {
                this.showToast('Salvataggio utente andato a buon fine');
                this.$refs.user.resetValidation();
                this.userObj = {idRole: null, idTypeUser: null};
                this.saveDialogDone();
              }
            })
            .catch(response => {
              this.checkResponse(response)
            });
      }
    },
    getDataForNewUser(){
      return new Promise(resolve => {
        ApiService.get('/getDataForNewUser')
            .then(({data}) => {
              console.log(data);
              this.kindomhalls  = !!data ? data.kindomhalls : [];
              this.roles        = !!data ? data.roles       : [];
              this.typeUsers    = !!data ? data.typeUsers   : [];
              resolve(true);
            })
            .catch(response => {
              this.checkResponse(response)
              resolve(false);
            });
      });
    },
    changeCongregation(e){
      return new Promise(resolve => {
        if(!!!e)return;
        ApiService.get('/getCongregationsByKindomhallAndRole?idKindomhall=' + e)
            .then(({data}) => {
              this.congregations = !!data ? data : [];
              if(Utils.justEditCongregation()){
                this.userObj.idCongregation = this.$store.getters.congregation.id;
              }
            })
            .catch(response => {
              this.checkResponse(response);
              resolve(false);
            });
      });
    },
    checkKindomhall(){
      if(Utils.isAdminAll()
          || (Utils.canEditKindomhall() && this.userObj.idKindomhall === this.$store.getters.kindomhall.id)
          || (Utils.canEditCongregation() && this.userObj.idCongregation === this.$store.congregation.id)
      )
        return true;
      this.showToast('Non hai i permessi per operare con un Sala del Regno o Congregazione a cui non appartieni', 'warning', 'Attenzione');
      this.$refs.sheet_save.resetValidation();
      return false;
    },
    closeLocallyDialog(){
      this.userObj = {idRole: null, idTypeUser: null};
      this.closeDialog();
    },
    viewPassword(){
      if(!!!this.userObj.id  || parseInt(this.$store.getters.currentProfile.id) <= parseInt(this.userObj.idRole)){
        this.pswVisible = !this.pswVisible
      }else{
        this.showToast('Non hai i permessi per operare con un Sala del Regno o Congregazione a cui non appartieni', 'warning', 'Attenzione');
      }
    },
    checkUserType(){
      if(parseInt(this.userObj.idTypeUser) <  parseInt(this.$store.getters.currentUser.idTypeUser)){
        this.$nextTick(()=>{
          this.userObj.idTypeUser = null;
        });
        this.showToast('Non hai i permessi per creare utenti di questo tipo', 'warning', 'Attenzione');
      }
    },
    checkUserRole(){
      if(parseInt(this.userObj.idRole) <  parseInt(this.$store.getters.currentProfile.id)){
        this.$nextTick(()=>{
          this.userObj.idRole = null;
        });
        this.showToast('Non hai i permessi per creare utenti con questo ruolo', 'warning', 'Attenzione');
      }
    }
  }
}
</script>