<template>
  <v-app style="width: 0px;height: 0px;">
    <ConfirmDialog :prop="dialogObject"/>
    <v-dialog v-model="show" :attach="true" persistent max-width="1200px" >
      <v-card>
        <v-card-title>
          <span class="headline">Analisi dei rischi per la scheda  "{{maintenanceSheet.sheetTitle}}"</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 30px;">
          <v-container>
            <v-form ref="risk_analyses_form"  lazy-validation>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <a href="javascript:void(0)" @click="showTutorialDiv('Scrivi l\'attività della tua scheda di manutenzione')">
                    <i class="fa flaticon2-information  text-default" style="float:right"/>
                  </a>
                  <v-text-field
                      dense
                      label="Descrizione dell'attività"
                      :rules="nameRules"
                      v-model="riskAnalysesObj.activityDesc"/>
                </div>
                <div class="col-md-6 col-sm-12">
                  <a href="javascript:void(0)" @click="showTutorialDiv('Auditorium principale, bagni, seconda sala, sottoscala, entrata principale, ...')">
                    <i class="fa flaticon2-information  text-default" style="float:right"/>
                  </a>
                  <v-text-field
                      dense
                      label="Luogo"
                      :rules="nameRules"
                      v-model="riskAnalysesObj.activityLocation"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <DatePicker
                      :show-info="true"
                      info-detail="Inserire la data di inizio prevista"
                      :showTutorial="showTutorialDiv"
                      :date-input="riskAnalysesObj.dateStart"
                      :change-date="(date)=>{riskAnalysesObj.dateStart = date;}"
                      label="Data di inizio prevista*"
                      :is-mandatory="true"/>
                </div>
                <div class="col-md-6 col-sm-12">
                  <v-text-field
                      dense
                      :readonly="true"
                      label="Contatti di emergenza"
                      v-model="riskAnalysesObj.emergencyContact"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <UserAutocomplete
                      :id-kindomhall="this.maintenanceSheet.idKindomhall"
                      :setUser="setUserCompiled"
                      label="Compilato da"
                      :disabled="!isAdmin"
                      :id-user="riskAnalysesObj.idUserCompiled"/>
                </div>

                <div class="col-md-6 col-sm-12">
                  <DatePicker
                      :show-info="true"
                      info-detail="Inserire la data di compilazione se diversa da quella indicata"
                      :showTutorial="showTutorialDiv"
                      :date-input="riskAnalysesObj.dateCompiled"
                      :change-date="(date)=>{riskAnalysesObj.dateCompiled = date;}"
                      label="Data compilazione*"
                      :is-mandatory="true"/>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <UserAutocomplete
                      :id-kindomhall="this.maintenanceSheet.idKindomhall"
                      :setUser="setUserChecked"
                      label="Controllato da"
                      :disabled="!isAdmin"
                      :id-user="riskAnalysesObj.idUserChecked"/>
                </div>
                <div class="col-md-6 col-sm-12">
                  <DatePicker
                      :show-info="true"
                      :date-input="riskAnalysesObj.dateChecked"
                      :showTutorial="showTutorialDiv"
                      :change-date="(date)=>{riskAnalysesObj.dateChecked = date;}"
                      label="Data di controllo*" :disabled="!isAdmin"/>
                </div>
              </div>
              <v-expansion-panels style="margin-top: 10px; margin-bottom: 30px;" v-model="openAccordion">
                <v-expansion-panel
                    :key="0">
                  <v-expansion-panel-header>
                    <span><b>Inserisci lista attività/rischi/metodi di controllo</b></span>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div class="row">
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(activityText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Sequenza attività 1"
                            v-model="riskAnalysesObj.activitySequence1"/>
                      </div>
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(riskText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Rischi 1"
                            v-model="riskAnalysesObj.risk1"/>
                      </div>
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(methodText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Metodi per i controlli 1"
                            v-model="riskAnalysesObj.checkMethods1"/>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(activityText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Sequenza attività 2"
                            v-model="riskAnalysesObj.activitySequence2"/>
                      </div>
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(riskText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Rischi 2"
                            v-model="riskAnalysesObj.risk2"/>
                      </div>
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(methodText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Metodi per i controlli 2"
                            v-model="riskAnalysesObj.checkMethods2"/>
                      </div>
                    </div>
                    <hr>
                    <div class="row">
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(activityText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Sequenza attività 3"
                            v-model="riskAnalysesObj.activitySequence3"/>
                      </div>
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(riskText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Rischi 3"
                            v-model="riskAnalysesObj.risk3"/>
                      </div>
                      <div class='col-md-4 col-sm-12'>
                        <a href="javascript:void(0)" @click="showTutorialDiv(methodText)">
                          <i class="fa flaticon2-information  text-default" style="float:right"/>
                        </a>
                        <v-textarea
                            dense
                            label="Metodi per i controlli 3"
                            v-model="riskAnalysesObj.checkMethods3"/>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-form>
            <v-card-actions style="margin-top: 15px">
              <div class="row">
                <div class="col-md-6 col-sm-12">
                  <a  style="color: red; font-size: 14px;  font-weight:bold; text-align: center" href="/moduli/DC-85i_I.pdf" target="_blank">
                    Scarica le istruzioni di compilazione (NB: Possono essere creati più moduli)</a>
                </div>
                <div class="col-md-6 col-sm-12" style="text-align: right">
                  <v-btn color="blue darken-1" text @click="reset">Cancella</v-btn>
                  <v-btn color="blue darken-1" text @click="saveLocallyDialog">{{ riskAnalysesObj.id ? 'Salva' : 'Crea nuovo'}}</v-btn>
                </div>
              </div>
            </v-card-actions>
            <hr>
            <div>
              <div class="row">
                <div class="col-12">
                  <CustomBTable
                      id-table="riskTable"
                      :items="riskAnalysesList"
                      :per-page="5"
                      :headers="headers"
                      :buttons="buttons"
                      :with-export="true"
                      :export-column="exportColumn"
                      sheet-title="Analisi Rischio"
                      :sheet-file-name="'Analisi Rischio - ' + maintenanceSheet.sheetTitle"
                      :onRowClicked="()=>{}">
                    <template #row-details="row">
                      <div class="detail m-2 border p-2" style="background: white;">
                        <b-form-row>
                          <b-col class="p-3 pl-5 pr-5">
                            <div class="row">
                              <div class="col-10">
                                <div class="h-100 discarded-title">
                                  <h6> Lista Attività </h6>
                                </div>
                              </div>
                            </div>
                            <div class="row" >
                              <div class="col-12">
                                <CustomBTable
                                    id-table="stepRiskAnswer"
                                    :items="row.item.steps"
                                    :headers="stepFields"
                                    :with-pagination="false"
                                    :with-filter="false">
                                </CustomBTable>
                              </div>
                            </div>
                          </b-col>
                        </b-form-row>
                      </div>
                    </template>
                  </CustomBTable>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ApiService from "@/core/services/api.service";
import CommonMixins from "../../../mixins/CommonMixins";
import {Utils} from "@/utils/utils";
import CustomBTable from "@/assets/js/components/CustomBTable";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import _ from "lodash";
import {RiskAnalyses} from "@/models/RiskAnalyses";
import {User} from "@/models/User";
import DatePicker from "@/view/content/DatePicker";
import UserAutocomplete from "@/view/content/UserAutocomplete";

export default {
  name: "RiskAnalysesDialog",
  components: {UserAutocomplete, DatePicker, ConfirmDialog, CustomBTable},
  props:{
    show:Boolean,
    closeDialog:Function,
    maintenanceSheet:Object,
    isAdmin:{
      type:Boolean,
      default: false
    }
  },
  mixins:[
    CommonMixins
  ],
  data(){
    return {
      modal: false,
      dialogObject: {dialog: false},
      Utils,
      user: {},
      openAccordion: 0,
      isUpdating: false,
      riskAnalysesList: [],
      riskAnalysesObj: {},
      checkedUser:undefined,
      compiledUser:undefined,
      referUser: undefined,
      nameRules: [v => !!v || 'Campo obbligatorio'],
      headers: [
        {key: 'show_details', label: 'Dettaglio', sortable: false},
        {label: 'Attività', key: 'activityDesc', sortable: true},
        {label: 'Luogo', key: 'activityLocation', sortable: true},
        {label: 'Data inizio', key: 'dateStart', sortable: true},
        {label: 'Contatto emergenza', key: 'emergencyContact', sortable: true},
        {label: 'Compilato da', key: 'userCompiled', sortable: true},
        {label: 'Compilato il', key: 'dateCompiled', sortable: true},
        {label: 'Controllato da', key: 'userChecked', sortable: true},
        {label: 'Controllato il', key: 'dateChecked', sortable: true},
        {key: 'action', label: "Azioni", tdClass: this.isAdmin ? 'width-175' : 'width-75'}
      ],
      stepFields: [
        {label: 'Sequenza', key: 'activitySequence', sortable: true},
        {label: 'Rischi', key: 'risk', sortable: true},
        {label: 'Controllo', key: 'checkMethods', sortable: true}
      ],
      exportColumn: [
        {label: 'Attività', value: 'activityDesc'},
        {label: 'Luogo', value: 'activityLocation'},
        {label: 'Data inizio', value: 'dateStart'},
        {label: 'Contatto emergenza', value: 'emergencyContact'},
        {label: 'Compilato da', value: 'userCompiled'},
        {label: 'Compilato il', value: 'dateCompiled'},
        {label: 'Controllato da', value: 'userChecked'},
        {label: 'Controllato il', value: 'dateChecked'},
        {label: 'Sequenze attività 1', value: 'activitySequence1'},
        {label: 'Rischi 1', value: 'risk1'},
        {label: 'Metodi per i controlli 1', value: 'checkMethods1'},
        {label: 'Sequenze attività 2', value: 'activitySequence2'},
        {label: 'Rischi 2', value: 'risk2'},
        {label: 'Metodi per i controlli 2', value: 'checkMethods2'},
        {label: 'Sequenze attività 3', value: 'activitySequence3'},
        {label: 'Rischi 3', value: 'risk3'},
        {label: 'Metodi per i controlli 3', value: 'checkMethods3'},
      ],
      activityText: 'Elenca I PASSAGGI della tua scheda di manutenzione che sono esposti a un rischio di infortunio. SCRIVI UN\'ATTIVITÀ ALLA VOLTA',
      riskText: 'Scrivi il potenziale rischio che lo svolgimento di quella attività potrebbe produrre. Ad esempio: urto, ustione, schiacciamento, ...',
      methodText:'Scrivi come cautelarsi dal rischio di infortunio'
    }
  },
  computed:{
    buttons(){
      let actions =  [{title:'Modifica', style:'outline-warning', action:this.editRisk}];
      if(this.isAdmin)actions.push({title:'Elimina', style:'outline-dark', action: this.remove});
      return actions;
    }
  },
  watch:{
    show(){
      console.log('watch');
      console.log(this.maintenanceSheet);
      if(!!!this.maintenanceSheet.idSheet && !!this.maintenanceSheet.id)
        this.maintenanceSheet.idSheet = this.maintenanceSheet.id;
      if(this.show && !!this.maintenanceSheet.idSheet){
        this.searchRiskAnalyses();
        this.getReferUserBySheet()
        this.reset();
      }
    }
  },
  methods:{
    showTutorialDiv(testo){
      this.dialogObject = this.getTutorialObject(
          () => {
            this.hideDialog();
          },
          testo
      );
    },
    setUserChecked(user){
      this.checkedUser = user;
    },
    setUserCompiled(user){
      this.compiledUser = user;
    },
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    searchRiskAnalyses(){
      ApiService.get('/getRiskAnalysesByMaintenanceSheetId?maintenanceSheetId=' + this.maintenanceSheet.idSheet)
          .then(({data})=>{
            if(data) {
              this.riskAnalysesList = data.map(t =>new RiskAnalyses(t));
              return;
            }
            this.riskAnalysesList =  [];
          }) .catch(response => {
        this.checkResponse(response)
      });

    },
    saveLocallyDialog() {
      console.log('RiskAnalysesDialog::saveLocallyDialog start ...');
      console.log('RiskAnalysesDialog::saveLocallyDialog form exist -> ' + !!this.$refs.risk_analyses_form);
      if(this.$refs.risk_analyses_form.validate()) {
        this.dialogObject = this.getModalObject(
            () => {
              this.doSave();
              this.hideDialog();
              this.openAccordion = null;
            },
            () => {
              this.hideDialog()
            }
        );
      }
    },
    handleSaveObj() {
      let obj = _.clone(this.riskAnalysesObj);
      if(!!obj.dateStart)
        obj.dateStart = Utils.stringToDate(obj.dateStart);
      if(!!obj.dateChecked)
        obj.dateChecked = Utils.stringToDate(obj.dateChecked);
      if(!!obj.dateCompiled)
        obj.dateCompiled = Utils.stringToDate(obj.dateCompiled);
      if(this.isAdmin){
        if(!!this.checkedUser) {
          obj.userChecked = this.checkedUser.username;
          obj.idUserChecked = this.checkedUser.id;
        }
      }else{
        obj.userChecked   = null;
        obj.idUserChecked = null;
        obj.dateChecked   = null;
      }
      if(!!this.compiledUser) {
        obj.userCompiled = this.compiledUser.username;
        obj.idUserCompiled = this.compiledUser.id;
      }
      return obj;
    },
    doSave(){
      if(this.$refs.risk_analyses_form.validate()) {
        let action = !!this.riskAnalysesObj.id ? '/updateRiskAnalyses' : '/saveRiskAnalyses';
        let obj = this.handleSaveObj(this.riskAnalysesObj);
        console.log(obj)
        ApiService.post(action, obj)
            .then(({data}) => {
              if (!!data && data.success) {
                this.searchRiskAnalyses();
                this.showToast('Salvataggio Analisi del Rischio andato a buon fine');
                this.riskAnalysesObj = {}
                this.reset();
              }
            })
            .catch(response => {
              this.checkResponse(response);
            });
      }
    },
    editRisk(row){
      this.riskAnalysesObj = _.clone(row.item);
      this.openAccordion = 0;
    },
    remove(row){
      console.log('RiskAnalysesDialog::remove start ...');
      this.dialogObject = this.getModalObject(
          () => {
            this.doRemove(row);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    doRemove(row){
      ApiService.get('/deleteRiskAnalyses?id='+ row.item.id)
          .then(({data}) => {
            if(!!data && data.success) {
              this.searchRiskAnalyses();
              this.showToast('Cancellazione Analisi del Rischio andata a buon fine');
              this.reset();
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    getReferUserBySheet(){
      ApiService.get('/getReferBySheedId?idKindomhall='+this.maintenanceSheet.idKindomhall+'&idSheet=' + this.maintenanceSheet.idSheet)
          .then(({data}) => {
            if(!!data) {
              this.referUser = new User(data);
              if(!!this.riskAnalysesObj)
                this.riskAnalysesObj.emergencyContact
                    = this.referUser.phone + ' (' + this.referUser.username + ')';
            }else{
              this.referUser = undefined;
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    reset(){
      if(!!this.$refs.risk_analyses_form)
        this.$refs.risk_analyses_form.resetValidation();
      this.riskAnalysesObj = new RiskAnalyses();
      this.riskAnalysesObj.idMaintenanceSheet = this.maintenanceSheet.idSheet;
      if(!this.isAdmin){
        this.riskAnalysesObj.userCompiled = this.user.username;
        this.riskAnalysesObj.idUserCompiled = this.user.id;
      }else{
        this.riskAnalysesObj.userCompiled = null;
        this.riskAnalysesObj.idUserCompiled = null;
      }

      this.riskAnalysesObj.userChecked    = null;
      this.riskAnalysesObj.idUserChecked  = null;
      this.checkedUser = undefined;
      this.compiledUser = undefined;
      this.riskAnalysesObj.dateStart          = Utils.getFormattedDate(new Date());
      this.riskAnalysesObj.dateCompiled       = Utils.getFormattedDate(new Date());
      this.openAccordion = null;

      if (!!this.$refs.risk_analyses_form)
        this.$refs.risk_analyses_form.resetValidation();
      console.log(this.referUser);
      if(!!this.referUser)
        this.riskAnalysesObj.emergencyContact = this.referUser.phone + ' (' + this.referUser.username + ')';
    }
  },
  mounted() {
    this.user = this.$store.getters.currentUser;
  }
}
</script>