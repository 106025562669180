export class Answer{
    constructor(props) {
        this.id                 = props ? props.id : null;
        this.idQuestionSheet    = props ? props.idQuestionSheet : null;
        this.idKindomhall       = props ? props.idKindomhall : null;
        this.idUser             = props ? props.idUser : null;
        this.checkDate          = props ? props.checkDate : null;
        this.answer             = !!props && !!props.answer ? props.answer  : 'Y';
        this.note               = props ? props.note    : null;
        this.solved             = props ? props.solved : null;
        this.showText           = !!this.note;
    }

    static answerFromQuestionSheet(maintenanceSheet, idKindomhall, idUser, dateFilling, isUpdate= false){
        let answers = [];
        maintenanceSheet.topics.forEach(t => {
            t.questions.forEach(q => {
                let answer = {};
                answer.id_question_sheet    = q.idQuestion;
                answer.id_kindomhall        = idKindomhall;
                answer.id_user              = idUser;
                answer.answer               = q.answer.answer;
                answer.note                 = q.answer.showText ? q.answer.note : '';
                answer.check_date           = dateFilling;
                answer.solved               = q.answer.solved;

                if(isUpdate)answer.id = q.answer.id;
                answers.push(answer);
            })
        })

        return {
            answers: answers,
            idMaintenanceSheet: maintenanceSheet.idSheet,
            idMaintenanceSheetResult: maintenanceSheet.idMaintenanceSheetResult,
            idKindomhall: idKindomhall,
            idUser: idUser,
            result: answers.filter(t=>t.answer == 'N').length > 0 ? 'N' : answers.filter(t=>t.answer == 'I').length > 0 ? 'I' : 'Y'
        }
    }
    static answerFromQuestionSheetForSubmit(maintenanceSheet){
        let answers = [];
        maintenanceSheet.topics.forEach(t => {
            t.questions.forEach(q => {
                answers.push(parseInt(q.answer.id));
            })
        });
        return {
            answersIds: answers,
            idMaintenanceSheet: maintenanceSheet.idSheet,
            idMaintenanceSheetResult: maintenanceSheet.idMaintenanceSheetResult,
        }
    }
}