<template>
  <div style="position: relative">
    <a href="javascript:void(0)" @click="showTutorialDiv(infoDetail)">
      <i class="fa flaticon2-information  text-default" style="float:right" v-if="showInfo" />
    </a>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        :disabled="disabled"
        offset-y
        min-width="auto">

      <template v-slot:activator="{ on, attrs }">
        <v-text-field
            dense
            v-model="dateFormatted"
            :label="label"
            prepend-icon="mdi-calendar"
            :disabled="disabled"
            readonly
            v-bind="attrs"
            nameRules
            v-on="on"/>
      </template>
      <v-date-picker
          dense
          v-model="date"
          @change="save"
          no-title
          locale="it"
          scrollable>
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="menu = false">
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import {Utils} from "@/utils/utils";

export default {
  name: "DatePicker",
  props:{
    dateInput:{
      type:String,
      require:null
    },
    changeDate:Function,
    label:String,
    disabled:{
      type:Boolean,
      default:()=>false
    },
    isMandatory:{
      type:Boolean,
      default:()=>false
    },
    showInfo:{
      type:Boolean,
      default:()=>false
    },
    infoDetail:String,
    showTutorial:Function
  },
  watch:{
    dateInput(){
      this.handleDate();
    }
  },
  data(){
    return {
      menu: false,
      date: null,
      dateFormatted: null,

    }
  },
  computed:{
    nameRules(){
      return this.isMandatory ? [ v => !!v || 'Campo obbligatorio'] : []
    }
  },
  methods: {
    showTutorialDiv(text){
      if(this.showTutorial)
        this.showTutorial(text);
    },
    save (date) {
      this.$refs.menu.save(date);
      this.dateFormatted = Utils.getFormattedDate(date);
      if(this.changeDate)this.changeDate(this.dateFormatted);
      console.log(this.dateFormatted);
    },
    handleDate(){
      this.date           = !!!this.dateInput ? null : Utils.getFormattedDate(Utils.stringToDate(this.dateInput), 'YYYY-MM-DD');
      this.dateFormatted  = !!!this.dateInput ? null : this.dateInput;
    }
  },
  mounted(){
    this.handleDate()
  }
}
</script>

<style scoped>

</style>