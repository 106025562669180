import {ACTION_SET_403, LOGOUT} from "../../../core/services/store/auth.module";
import {SCHEDE} from "@/core/services/store/panelStore";

export default {
    name: 'CommonMixins',
    methods: {
        checkResponse(response) {
            if (JSON.stringify(response).indexOf('403') > -1) {
                let self = this;
                this.$store
                    .dispatch(LOGOUT)
                    .then(() => {
                        if (self.$router) self.$router.push({name: "login"})
                    });
            }
            if (JSON.stringify(response).indexOf('405') > -1|| JSON.stringify(response).indexOf('403') > -1) {
                if(!this.$store.getters.error403Showed) {
                    this.$store.dispatch(ACTION_SET_403);
                    this.showToast("Non hai i permessi per vedere queste informazioni", 'danger', "Errore");
                }
            }else{
                this.showToast("Errore durante l'operazione", "danger", "Errore");
            }
        },
        showToast(message, type='success', title=`Operazione eseguita`){
            this.$bvToast.toast(message, {
                title: title,
                toaster: 'b-toaster-bottom-full',
                solid: true,
                variant: type,
                appendToast: true,
                autoHideDelay:7000
            });
        },
        getModalObject(ok, cancel, title='Attenzione', text='Sei sicuro di voler proseguire con questa operatione?', dialog=true){
            return {title, text, dialog,ok,cancel};
        },
        getRiskObject(ok, cancel, title='Attenzione', text="Prima di compilare la scheda, ricorda che la sicurezza è importante! Hai bisogno di aggiornare l'analisi del rischio?", dialog=true){
            return {title, text, dialog,ok,cancel, cancelLabel:'No', okLabel:"Si"};
        },
        getTutorialObject(ok, text, dialog=true){
            return {title:'Istruzioni', text, dialog,ok, okLabel:"Ok"};
        },
        openHistorySheet(sheetCode){
            console.log('SheetExpiredTable::openHistorySheet start ...');
            console.log('SheetExpiredTable::openHistorySheet sheetCode -> ' + sheetCode);
            this.$store.dispatch('setSheetHistoryCriteria', {sheetCode});
            console.log('SheetExpiredTable::openHistorySheet criteria saved -> ' + JSON.stringify(this.$store.getters.sheetHistoryCriteria));
            this.$store.dispatch('setPanel', {panel: SCHEDE});
            console.log('SheetExpiredTable::openHistorySheet end');
        }
    }
}