
// action types

export const SAVE_ALLOWED_KINDOMHALLS = "allowed_kindomhalls";

// mutation types
export const CACHE_ALLOWED_KINDOMHALLS = "cache_allowed_kindomhallS";

const state = {
    allowedKindomhalls: [],
};

const getters = {
    allowedKindomhalls(state) {
        return state.allowedKindomhalls;
    }
};

const actions = {
    [SAVE_ALLOWED_KINDOMHALLS](context, userUpdated) {
        context.commit(CACHE_ALLOWED_KINDOMHALLS, userUpdated);
    },
};

const mutations = {
    [CACHE_ALLOWED_KINDOMHALLS](state, allowedKindomhalls) {
        state.allowedKindomhalls = allowedKindomhalls;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
