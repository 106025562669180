<template>
  <v-app style="width: 0px;height: 0px;">
    <ConfirmDialog :prop="dialogObject"/>
    <v-dialog v-model="show" persistent max-width="600px" min-height="400px">
      <v-card>
        <v-card-title>
          <span class="headline">Associa scheda ad un operatore</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 10px;">
          <v-container>
            <v-form ref="link_user_form"  lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-autocomplete
                      v-model="userLinked"
                      :disabled="isUpdating"
                      :items="userTotal"
                      filled
                      chips
                      :menu-props="{closeOnContentClick:true}"
                      color="blue-grey lighten-2"
                      label="Aggiungi"
                      item-text="username"
                      item-value="id"
                      multiple>
                    <template v-slot:selection="data">
                      <v-chip
                          v-bind="data.attrs"
                          :input-value="data.selected"
                          close
                          @click:close="remove(data.item)">
                        {{ data.item.username }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content v-text="data.item"  @click="()=>add(data.item)"></v-list-item-content>
                      </template>
                      <template v-else >
                        <div  @click="()=>add(data.item)">
                          <v-list-item-content>
                            <v-list-item-title v-html="data.item.username"></v-list-item-title>
                            <v-list-item-subtitle v-html="data.item.congregationName"></v-list-item-subtitle>
                          </v-list-item-content>
                        </div>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ApiService from "../../../../../core/services/api.service";
import CommonMixins from "../../../mixins/CommonMixins";
import {SUPER_ADMIN} from "../../../../../utils/constants";
import {Utils} from "../../../../../utils/utils";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";

export default {
  name: "SheetLinkUserDialog",
  components: {ConfirmDialog},
  props:{
    show:Boolean,
    closeDialog:Function,
    saveDialogDone:Function,
    idKindomhall:Number|String,
    idSheet:Number|String
  },
  mixins:[
    CommonMixins
  ],
  data(){
    return {
      isUpdating:false,
      userLinked: [],
      userTotal:  [],
      dialogObject:{ dialog:false}
    }
  },
  watch:{
    show() {
      if (this.show && !!this.idKindomhall && !!this.idSheet) {
        this.getUserList();
      }
    }
  },
  methods:{
    getUserList(){
      ApiService.get('/getUserToLink?idKindomhall='+this.idKindomhall + '&idSheet='+this.idSheet)
          .then(({data}) => {
            this.userLinked   = !!data ? data.userLinked : [];
            let userToLink    = !!data ? data.userToLink : [];
            this.userTotal    = userToLink.concat(this.userLinked);
            console.log()
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    remove(item){
      this.makeAction('remove', item);
    },
    add(item){
      this.makeAction('add', item);
    },
    makeAction(action, item){
      console.log('SheetLinkUserDialog::makeAction start ...');
      console.log('SheetLinkUserDialog::makeAction item ->' + JSON.stringify(item));
      if(this.$store.getters.currentProfile.id != SUPER_ADMIN){
        if(this.idKindomhall == this.$store.getters.kindomhall.id && Utils.canSave()){
          this.handleAddDeleteUser(item, action);
        }else{
          let message = !Utils.canSave() ? 'Non hai i permessi per editare' : 'Non hai i permessi per operare con un Sala del Regno a cui non appartieni'
          if(action === "add"){
            setTimeout(()=>{this.deleteFromArray(item.id);}, 1000);
          }
          this.showToast(message, 'warning', "Attenzione");
        }
      }else{
        this.handleAddDeleteUser(item, action);
      }
    },
    handleAddDeleteUser(item, action){
      this.dialogObject = this.getModalObject(
          ()=>{
            if(action === 'add') {
              this.reallyAddUser(item)
            }else if (action === 'remove'){
              this.reallyDeleteUser(item)
            }
            this.hideDialog();
          },
          () => {
            if(action == 'add')
              this.deleteFromArray(item.id)
            this.hideDialog()
          }
      );
    },
    reallyAddUser(item){
      ApiService.get('/addUserToSheet?idUser='+item.id + '&idSheet='+this.idSheet)
          .then(({data}) => {
            if(data.success){
              this.getUserList();
              this.showToast('Associazione operatore a scheda eseguito correttamente');
            }else{
              this.showToast('Errore durante l\'associazione dell\'operatore alla scheda','danger', "Errore");
              setTimeout(()=>{this.deleteFromArray(item.id);}, 1000);
            }
          })
          .catch(response => {
            this.checkResponse(response);
            setTimeout(()=>{this.deleteFromArray(item.id);}, 1000);
          });
    },
    reallyDeleteUser(item){
      ApiService.get('/removeUserToSheet?idUser='+item.id + '&idSheet='+this.idSheet)
          .then(({data}) => {
            if(data.success){
              this.showToast('Eliminazione operatore da scheda eseguito correttamente');
              this.getUserList();
            }else{
              this.showToast('Errore durante l\'eliminazione dell\'operatore dalla scheda','danger', 'Errore');
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    deleteFromArray(id){
      console.log('SheetLinkUserDialog::deleteFromArray start ...');
      console.log('SheetLinkUserDialog::deleteFromArray id -> ' + id);
      const index = this.userLinked.indexOf(id)
      console.log('SheetLinkUserDialog::deleteFromArray index -> ' + index);
      if (index >= 0) this.userLinked.splice(index, 1);
      console.log('SheetLinkUserDialog::deleteFromArray end');
    }
  }
}
</script>