<template>
  <v-app style="width: 0px;height: 0px;">
    <ConfirmDialog :prop="dialogObject"/>
    <v-dialog v-model="show" :attach="true" persistent max-width="1000px" >
      <v-card>
        <v-card-title>
          <span class="headline">Assegnazione quesito alle sezioni</span>
        </v-card-title>
        <hr>
        <v-card-text style="margin-top: 30px;">
          <v-container>
            <v-form ref="question_sheet_form"  lazy-validation>
              <v-row>
                  <v-col cols="6">
                    <v-text-field
                        dense
                        label="Posizione*"
                        :rules="nameRules"
                        v-model="questionObj.position"
                        :readonly="!Utils.canSave()"
                        :clearable="Utils.canSave()"
                        required/>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                        :attach="true"
                        dense
                        :items="topicList.map(t=>{ return {text: t.topic, value:t.id}})"
                        label="Sezione*"
                        :rules="nameRules"
                        v-model="questionObj.idTopic"
                        :readonly="!Utils.canSave()"
                        required/>
                  </v-col>
                <v-col cols="12">
                  <v-textarea
                      dense
                      :readonly="!Utils.canSave()"
                      label="Quesito*"
                      :rules="nameRules"
                      :clearable="Utils.canSave()"
                      v-model="questionObj.question"/>
                </v-col>
              </v-row>
            </v-form>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="reset">Cancella</v-btn>
              <v-btn color="blue darken-1" v-if="Utils.canSave()" text @click="saveLocallyDialog">{{ questionObj.id ? 'Aggiorna' : 'Salva'}}</v-btn>
            </v-card-actions>
            <hr>
            <div>
              <div class="row">
                <div class="col-12">
                  <CustomBTable
                      :items="questionList"
                      :per-page="5"
                      :headers="headers"
                      :buttons="buttons"/>
                </div>
              </div>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDialog">Chiudi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import ApiService from "../../../../../core/services/api.service";
import CommonMixins from "../../../mixins/CommonMixins";
import {SUPER_ADMIN} from "../../../../../utils/constants";
import {Utils} from "../../../../../utils/utils";
import CustomBTable from "../../../../../assets/js/components/CustomBTable";
import ConfirmDialog from "@/view/pages/app/schede/dialog/ConfirmDialog";
import _ from "lodash";

export default {
  name: "QuestionSheetDialog",
  components: {ConfirmDialog, CustomBTable},
  props:{
    show:Boolean,
    closeDialog:Function,
    idSheet:Number|String
  },
  mixins:[
    CommonMixins
  ],
  data(){
    return {
      dialogObject:{ dialog:false},
      Utils,
      isUpdating:false,
      questionObj:{},
      topicList: [],
      questionList: [],
      nameRules: [ v => !!v || 'Campo obbligatorio'],
      headers:[
          {label: 'Posizione', key: 'position', sortable:true},
          {label: 'Sezione', key: 'topic', sortable:true},
          {label: 'Quesito', key: 'question', sortable:true},
        { key: 'action', label: "Azioni", tdClass:'width-175'}
      ]
    }
  },
  computed:{
    buttons(){
      if(Utils.canSave()){
        return [
          {title:'Modifica', style:'outline-warning', action:(row)=>{this.questionObj = _.clone(row.item);}},
          {title:'Elimina', style:'outline-dark', action: this.remove}
        ]
      }
    }
  },
  watch:{
    show(){
      this.questionObj = {};
      if(this.$refs.question_sheet_form)
        this.$refs.question_sheet_form.resetValidation();
      if(this.show && !!this.idSheet){
        this.searchTopics();
        this.searchQuestions();
      }
    }
  },
  methods:{
    hideDialog(){
      this.dialogObject.dialog = false;
    },
    searchTopics(){
      ApiService.get('/getTopicsByIdSheet?idSheet='+this.idSheet)
          .then(({data}) => {
            this.topicList = !!data ? data.topicList : [];
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    searchQuestions(){
      ApiService.get('/getQuestionsByIdSheet?idSheet='+this.idSheet)
          .then(({data}) => {
            this.questionList = !!data ? data.questionList : [];
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    saveLocallyDialog() {
      console.log('QuestionSheetDialog::saveLocallyDialog start ...');
      console.log('QuestionSheetDialog::saveLocallyDialog form exist -> ' + !!this.$refs.question_sheet_form);
      if(this.$refs.question_sheet_form.validate()) {
        this.dialogObject = this.getModalObject(
            () => {
              this.doSave();
              this.hideDialog();
            },
            () => {
              this.hideDialog()
            }
        );
      }
    },
    doSave(){
      if(this.$refs.question_sheet_form.validate()) {
        let action = !!this.questionObj.id ? '/updateQuestion' : '/saveQuestion';
        ApiService.post(action, this.questionObj)
            .then(({data}) => {
              if (!!data && data.success) {
                this.searchQuestions();
                this.showToast('Salvataggio quesito andato a buon fine');
                this.questionObj = {}
                this.$refs.question_sheet_form.resetValidation();
              }
            })
            .catch(response => {
              this.checkResponse(response);
            });
      }
    }, remove(row){
      console.log('QuestionSheetDialog::remove start ...');
      this.dialogObject = this.getModalObject(
          () => {
            this.doRemove(row);
            this.hideDialog();
          },
          () => {
            this.hideDialog()
          }
      );
    },
    doRemove(row){
      ApiService.get('/deleteQuestion?id='+ row.item.id)
          .then(({data}) => {
            if(!!data && data.success) {
              this.searchQuestions();
              this.showToast('Cancellazione quesito andata a buon fine');
              this.questionObj = {};
              this.$refs.question_sheet_form.resetValidation();
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    reset(){
      this.questionObj = {};
      this.$refs.question_sheet_form.resetValidation();
    }
  }
}
</script>