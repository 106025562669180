<template>
  <div>
    <v-app >
      <div class="row">
        <div class="col-md-12">
          <KTCodePreview  v-bind:title="`Analisi dei Rischi` ">>
            <template v-slot:preview>
              <v-form
                  ref="sheet_search">
                <div class="row">
                  <div class="col-lg-2 col-md-3 col-sm-12 col-12">
                    <v-text-field
                        dense
                        :clearable="true"
                        placeholder="ES: Paolo Rossi"
                        v-model="criteria.username"
                        label="Operatore"/>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <v-text-field
                        dense
                        :clearable="true"
                        placeholder="ES: D-21"
                        v-model="criteria.sheetCode"
                        label="Codice Scheda"/>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <v-select
                        :disabled="$store.getters.currentProfile.id != SUPER_ADMIN"
                        dense
                        :items="kindomhalls"
                        label="Sala del Regno*"
                        :rules="nameRules"
                        :clearable="Utils.isAdminAll()"
                        v-model="criteria.idKindomhall"
                        required/>
                  </div>
                  <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                    <v-select
                        :disabled="Utils.canWatchJustCongregation()"
                        dense
                        :items="congregations"
                        label="Congregazione"
                        :clearable="Utils.canWatchKindomhall()"
                        v-model="criteria.idCongregation"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuStartFrom"
                        :close-on-content-click="true"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dense
                            :value="dateStartFromFormatted"
                            label="Data di inizio da"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            readonly
                            @click:clear="dateStartFrom = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateStartFrom"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuStartTo"
                        :close-on-content-click="true"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            dense
                            :value="dateStartToFormatted"
                            label="Data di inizio a"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            readonly
                            @click:clear="dateStartTo = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateStartTo"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuCompiledFrom"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="dateCompiledFromFormatted"
                            label="Data di compilazione da"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            dense
                            readonly
                            @click:clear="dateCompiledFrom = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateCompiledFrom"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuCompiledTo"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="dateCompiledToFormatted"
                            label="Data di compilazione a"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            dense
                            readonly
                            @click:clear="dateCompiledTo = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateCompiledTo"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuCheckedFrom"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="dateCheckedFromFormatted"
                            label="Data di controllo da"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            dense
                            readonly
                            @click:clear="dateCheckedFrom = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateCheckedFrom"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                  <div class="col-lg-2 col-md-6 col-sm-12 col-12">
                    <v-menu
                        v-model="menuCheckedTo"
                        :close-on-content-click="true"
                        transition="scale-transition"
                        offset-y
                        min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                            :value="dateCheckedToFormatted"
                            label="Data di controllo a"
                            prepend-icon="mdi-calendar"
                            :clearable="true"
                            dense
                            readonly
                            @click:clear="dateCheckedTo = null"
                            v-bind="attrs"
                            v-on="on"/>
                      </template>
                      <v-date-picker
                          v-model="dateCheckedTo"
                          locale="it"
                          scrollable/>
                    </v-menu>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <v-radio-group
                        dense
                        v-model="criteria.compiled"
                        class="no-margin-top no-padding-top"
                        row>

                      <v-radio
                          label="Compilate"
                          value="Y"/>
                      <v-radio
                          label="Non Compilate"
                          value="N"/>
                      <v-radio
                          label="Indifferente"
                          :value="null"/>
                    </v-radio-group>
                  </div>
                  <div class="col-lg-4 col-md-6 col-sm-12 col-12">
                    <v-radio-group
                        dense
                        v-model="criteria.checked"
                        class="no-margin-top no-padding-top"
                        @change="changeCheked"
                        row>

                      <v-radio
                          label="Controllate"
                          value="Y"
                      />
                      <v-radio
                          label="Non controllate"
                          value="N"
                      />
                      <v-radio
                          label="Indifferente"
                          :value="null"
                      />
                    </v-radio-group>
                  </div>
                </div>
                 <br>
                <br>
                <div class="row justify-content-center">
                  <div class="col-12 text-center">
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="error"
                        class="mr-4"
                        @click="reset">
                      Cancella
                    </v-btn>
                    <v-btn
                        small
                        style="min-width: 120px"
                        color="success"
                        class="mr-4"
                        @click="searchSheet">
                      Cerca
                    </v-btn>
                  </div>
                </div>
              </v-form>
              <br>
              <div style="min-height: 400px; margin-top: 40px;">
                <CustomBTable
                    :items="riskSheets"
                    :headers="headers"
                    :buttons="buttons"
                    id-table="sheetTable"/>
              </div>
            </template>
          </KTCodePreview>
        </div>
      </div>
    </v-app>
    <CompleteSheetEditDialog
        :sheet-code="sheetCode"
        :id-kindomhall="criteria.idKindomhall"
        :show="openCompleteSheetDialog"
        :closeDialog="closeDialog"/>

    <!--RiskAnalysesDialog
        :is-admin="true"
        :maintenanceSheet="riskSheetItem"
        :show="openRiskAnalysesDialog"
        :closeDialog="closeDialog"/-->
  </div>
</template>


<script>

import ApiService from "@/core/services/api.service";
import CommonMixins from "../../mixins/CommonMixins";
import KTCodePreview from "../../../content/CodePreview";
import {
  SUPER_ADMIN,
  ADMIN_CONGREGAZIONE,
  ADMIN_CONGREGAZIONE_VIEWER,
  SEARCH_SHEETS
} from '@/utils/constants';
import CustomBTable from "@/assets/js/components/CustomBTable";
import {Utils} from "@/utils/utils";
import CompleteSheetEditDialog from '../schede/dialog/CompleteSheetEditDialog'
import _ from 'lodash';
import moment from "moment";
//import RiskAnalysesDialog from "@/view/pages/app/schede/dialog/RiskAnalysesDialog";
//import {RiskMaintenanceSheet} from "@/models/RiskMaintenanceSheet";
export default {
  name: "Rischio",
  mixins: [
    CommonMixins
  ],
  components: {
   // RiskAnalysesDialog,
    CustomBTable,
    KTCodePreview,
    CompleteSheetEditDialog
  },
  data() {
    return {
      SUPER_ADMIN,
      ADMIN_CONGREGAZIONE,
      ADMIN_CONGREGAZIONE_VIEWER,
      nameRules: [ v => !!v || 'Campo obbligatorio'],
      Utils,
      riskSheets: [],
      kindomhalls:[],
      congregations:[],
      dateStartFrom: null,//Utils.getFormattedDateBehind(3, 'YYYY-MM-DD'),
      dateCompiledFrom:null,//Utils.getFormattedDateBehind(0, 'YYYY-MM-DD'),
      dateCheckedFrom:null,//Utils.getFormattedDateBehind(0, 'YYYY-MM-DD'),
      dateStartTo: null,//Utils.getFormattedDateBehind(3, 'YYYY-MM-DD'),
      dateCompiledTo:null,//Utils.getFormattedDateBehind(0, 'YYYY-MM-DD'),
      dateCheckedTo:null,//Utils.getFormattedDateBehind(0, 'YYYY-MM-DD'),
      criteria: { compiled:null, checked: null, idKindomhall : _.clone(this.$store.getters.kindomhall).id},
      sheetCode:undefined,
      riskSheetItem: {},
      search: '',
      openCompleteSheetDialog: false,
      //openRiskAnalysesDialog: false,
      headers: [
        {label: 'Codice', key: 'sheetCode', sortable:true},
        {label: 'Titolo', key: 'sheetTitle', sortable:true},
        {label: 'Sala del Regno', key: 'kindomhallName', sortable:true},
        {label: 'Congregazione', key: 'congregationName', sortable:true},
        {label: 'Locazione', key: 'location', sortable:true},
        {label: 'Operatori', key: 'users', sortable:true},
        {label: 'Analisi rischio presente', key: 'riskAnalysesPresent', sortable:true},
        {label: 'Analisi rischio controllate', key: 'riskAnalysesChecked', sortable:true},
        {label: 'Analisi rischio da controllare', key: 'riskAnalysesNotChecked', sortable:true},
        { key: 'action', label: "Azioni", tdClass: 'width-250'}
      ],
      menuStartFrom: false,
      menuCompiledFrom: false,
      menuCheckedFrom: false,
      menuStartTo: false,
      menuCompiledTo: false,
      menuCheckedTo: false
    }
  },
  computed:{
    buttons(){
      return [
        {title:'Dettaglio', style:'outline-success', action:this.openCompleteSheet},
        {title:'Rischio', style:'outline-primary', action:this.openRiskAnalysesSheet}
      ];
    },
    dateStartFromFormatted(){
      let ds = !!this.dateStartFrom ? moment(this.dateStartFrom).format('DD-MM-YYYY') : null;
      this.criteria.dateStartFrom = ds;
      return ds;
    },
    dateCompiledFromFormatted(){
      let df = !!this.dateCompiledFrom ? moment(this.dateCompiledFrom).format('DD-MM-YYYY') : null;
      this.criteria.dateCompiledFrom = df;
      return df;
    },
    dateCheckedFromFormatted(){
      let df = !!this.dateCheckedFrom ? moment(this.dateCheckedFrom).format('DD-MM-YYYY') : null;
      this.criteria.dateCheckedFrom = df;
      return df;
    },
    dateStartToFormatted(){
      let ds = !!this.dateStartTo ? moment(this.dateStartTo).format('DD-MM-YYYY') : null;
      this.criteria.dateStartTo = ds;
      return ds;
    },
    dateCompiledToFormatted(){
      let df = !!this.dateCompiledTo ? moment(this.dateCompiledTo).format('DD-MM-YYYY') : null;
      this.criteria.dateCompiledTo = df;
      return df;
    },
    dateCheckedToFormatted(){
      let df = !!this.dateCheckedTo ? moment(this.dateCheckedTo).format('DD-MM-YYYY') : null;
      this.criteria.dateCheckedTo = df;
      return df;
    }
  },
  methods: {
    changeCheked(item) {
      console.log(item);
      if(item ==='N') this.criteria.compiled = 'Y';
    },
    openCompleteSheet(row) {
      this.sheetCode = !!row && !!row.item ? row.item.sheetCode : null;
      this.openCompleteSheetDialog = true;
    },
    openRiskAnalysesSheet(row) {
      this.riskSheetItem = !!row ? row.item  : null;
      //this.openRiskAnalysesDialog = true;
    },
    searchSheet() {
      if(this.$refs.sheet_search.validate()) {
        ApiService.post('/searchRiskMaintenanceSheet',  this.criteria)
            .then(({data}) => {
              this.riskSheets = !!data && !!data.riskSheets && data.riskSheets.length > 0 ? data.riskSheets.map(t => new RiskMaintenanceSheet(t)) : [];
            })
            .catch(response => {
              this.checkResponse(response)
            });
      }
    },
    reset() {
      this.criteria = {
        compiled: null,
        checked: null,
        idKindomhall : _.clone(this.$store.getters.kindomhall).id
      };
      //this.dateStart  = Utils.getFormattedDateBehind(3, 'YYYY-MM-DD');
      //this.dateEnd    = Utils.getFormattedDateBehind(0, 'YYYY-MM-DD');
      this.$refs.sheet_search.resetValidation();
    },
    closeDialog(){
      this.openCompleteSheetDialog = false;
      //this.openRiskAnalysesDialog = false;
    },
    getCongregationByKindomhallId(){
      console.log('ViewSheetTab::getCongregationByKindomhallId start ...');
      ApiService.get('/getCongregationsByKindomhallAndRole?idKindomhall=' + this.criteria.idKindomhall)
          .then(({data}) => {
            this.congregations = !!data ? data : [];
            if([ADMIN_CONGREGAZIONE_VIEWER,ADMIN_CONGREGAZIONE].some(t=> this.$store.getters.currentProfile.id == t))
              this.criteria.idCongregation = this.$store.getters.congregation.id;

            console.log('ViewSheetTab::getCongregationByKindomhallId search done');
            if(!!this.$store.getters.sheetCriteria) {
              this.criteria = this.criteria =Object.assign({}, this.criteria, _.clone(this.$store.getters.sheetCriteria));
              this.searchSheet();
              this.$store.dispatch('setSheetCriteria', null);
            }
          })
          .catch(response => {
            this.checkResponse(response);
          });
    },
    searchFromOtherPanel(criteria){
      console.log('ViewSheetTab::searchFromOtherPanel start ...');
      this.criteria = criteria;
      !!this.congregations && this.congregations.length > 0 ? this.searchSheet() : this.haveToSearch = true;
    }
  },
  mounted(){
    let self = this;
    console.log('ViewSheetTab::mounted create ...');
    ApiService.get('/getAllKindomhalls')
        .then(({data}) => {
          self.kindomhalls = !!data ? data : [];
          this.criteria.idKindomhall = _.clone(this.$store.getters.kindomhall).id;
          this.getCongregationByKindomhallId(this.criteria.idKindomhall)
        })
        .catch(response => {
          self.checkResponse(response)
        });
  }
}
</script>

