import {Utils} from "../utils/utils";


export class Kindomhall{
    constructor(props) {
        this.id                 = props ? props.id : null;
        this.name               = props ? props.name : null;
        this.address            = props ? props.address : null;
        this.password           = props ? props.password : null;
        this.passwordVisible    = props ? props.passwordVisible : null;
        this.congregations  = [];
    }

    static fromCombo(props){
       return new Kindomhall(
           {
               id : props.value ?? null,
               name : props.text ?? null,
           }
       )
    }
}