<template>
  <div class="aside aside-left d-flex flex-column" id="kt_aside" ref="kt_aside">
    <!--begin::Brand-->
    <KTBrand></KTBrand>
    <!--end::Brand-->
    <!--begin::Nav Wrapper-->
    <div class="aside-nav d-flex flex-column align-items-center flex-column-fluid pt-7">
      <!--begin::Nav-->
      <ul class="nav flex-column">
        <!--begin::Item-->
        <li class="nav-item mb-5"  v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Dashboard'">
          <a
              href="#"
              @click="goToPanel(DASHBOARD)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === DASHBOARD}"
              data-toggle="tab"
              data-target="#kt_aside_tab_1"
              role="tab">
              <i class="flaticon2-protection icon-lg"></i>
          </a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="nav-item mb-5"  v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Schede'" v-if="$store.getters.currentProfile.id !== OPERATORE">
          <a
              href="#"
              @click="goToPanel(SCHEDE)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === SCHEDE}"
              data-toggle="tab"
              data-target="#kt_aside_tab_2"
              role="tab"
          >
            <i class="flaticon2-list-3 icon-lg"></i>
          </a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <!--end::Item-->
        <!--begin::Item-->
        <li class="nav-item mb-5"  v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Excel Globale'" v-if="$store.getters.currentProfile.id !== OPERATORE">
          <a
              href="#"
              @click="goToPanel(EXCEL_GLOBALE)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === EXCEL_GLOBALE}"
              data-toggle="tab"
              data-target="#kt_aside_tab_2"
              role="tab"
          >
            <i class="fa la-file-excel icon-lg"></i>
          </a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="nav-item mb-5" v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Utenti'" v-if="$store.getters.currentProfile.id !== OPERATORE">
          <a
              href="#"
              @click="goToPanel(UTENTI)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === UTENTI}"
              data-toggle="tab"
              data-target="#kt_aside_tab_3"
              role="tab">
            <i class="flaticon2-user icon-lg"></i>
          </a>
        </li>
        <!--end::Item-->
        <!--begin::Item-->
        <li class="nav-item mb-5" v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Sale del Regno/Congregazioni'"
            v-if="$store.getters.currentProfile.id != OPERATORE">
          <a
              href="#"
              @click="goToPanel(CONGREGAZIONI)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === CONGREGAZIONI}"
              data-toggle="tab"
              data-target="#kt_aside_tab_5"
              role="tab">
            <i class="flaticon2-shelter icon-lg"></i>
          </a>
        </li>
        <!--li class="nav-item mb-5" v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Analisi del Rischio'"
            v-if="$store.getters.currentProfile.id != OPERATORE">
          <a
              href="#"
              @click="goToPanel(RISCHIO)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === RISCHIO}"
              data-toggle="tab"
              data-target="#kt_aside_tab_5"
              role="tab">
            <i class="flaticon-danger icon-lg"></i>
          </a>
        </li-->
        <!--end::Item-->
        <!--begin::Item-->
        <!--li class="nav-item mb-5" v-b-tooltip.hover.right="KTUtil.isMobileDevice() ? null :'Impostazioni'"
            v-if="$store.getters.currentProfile.id != OPERATORE && $store.getters.currentProfile.id != ADMIN_CONGREGAZIONE && $store.getters.currentProfile.id != ADMIN_CONGREGAZIONE_VIEWER">
          <a
              href="#"
              @click="goToPanel(IMPOSTAZIONI)"
              class="nav-link btn btn-icon btn-icon-white btn-lg"
              :class="{'active btn-clean ': $store.getters.currentPanel === IMPOSTAZIONI}"
              data-toggle="tab"
              data-target="#kt_aside_tab_6"
              role="tab">
            <i class="flaticon2-gear icon-lg"></i>
          </a>
        </li-->
        <!--end::Item-->
      </ul>
      <!--end::Nav-->
    </div>
    <!--end::Nav Wrapper-->
    <!--begin::Footer-->
    <div
        class="aside-footer d-flex flex-column align-items-center flex-column-auto py-8"
    >
      <!--begin::Quick Panel-->
      <!--KTQuickPanel></KTQuickPanel-->
      <!--end::Quick Panel-->
    </div>
    <!--end::Footer-->
  </div>
</template>

<style lang="scss">
/* hide default vue-bootstrap tab links */
.hide-tabs > div:not(.tab-content) {
  display: none;
}
</style>

<script>
import KTQuickPanel from "@/view/layout/extras/offcanvas/QuickPanel";
import KTBrand from "@/view/layout/brand/Brand";
import KTLayoutAside from "@/assets/js/layout/base/aside.js";
import {OPERATORE,ADMIN_CONGREGAZIONE, ADMIN_CONGREGAZIONE_VIEWER} from "../../../utils/constants";
import {
  DASHBOARD,
  SCHEDE,
  UTENTI,
  CONGREGAZIONI,
  RISCHIO,
  IMPOSTAZIONI,
  EXCEL_GLOBALE
} from "@/core/services/store/panelStore.js";

export default {
  name: "Aside",
  components: {
    KTQuickPanel,
    KTBrand
  },
  data(){
    return{
      KTUtil,
      OPERATORE,
      ADMIN_CONGREGAZIONE_VIEWER,
      ADMIN_CONGREGAZIONE,
      DASHBOARD,
      SCHEDE,
      EXCEL_GLOBALE,
      UTENTI,
      CONGREGAZIONI,
      RISCHIO,
      IMPOSTAZIONI
    }
  },
  methods:{
    goToPanel(panel){
      console.log('Go To Panel')
      const event = new MouseEvent('click', {
        view: body,
        bubbles: true,
        cancelable: true
      });
      const body = document.getElementById('kt_aside_mobile_toggle');
      //body.dispatchEvent(event);
      body.click();
      this.$store.dispatch('setPanel', {panel: panel})
    }
  },
  mounted() {
    this.$nextTick(() => {
      // Init Aside
      KTLayoutAside.init(this.$refs["kt_aside"]);
    });
  }
};
</script>
