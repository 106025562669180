<template>
  <div
      id="kt_header_mobile"
      ref="kt_header_mobile"
      class="header-mobile header-mobile-fixed"
      v-bind:class="headerClasses">
    <a href="/">
      <h3>Manutenzione Sala del Regno</h3>
    </a>
    <div class="d-flex align-items-center">
      <button
          v-if="asideEnabled"
          class="btn p-0 burger-icon burger-icon-left"
          id="kt_aside_mobile_toggle"
          ref="kt_aside_mobile_toggle">
        <span> </span>
      </button>
      <button
          class="btn btn-hover-text-primary p-0 ml-2"
          id="kt_header_mobile_topbar_toggle"
          ref="kt_header_mobile_topbar_toggle">
        <span class="svg-icon svg-icon-xl">
          <inline-svg src="../../media/svg/icons/General/User.svg" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeaderTopbar from "@/assets/js/layout/base/header-topbar.js";

export default {
  name: "KTHeaderMobile",
  components: {},
  mounted() {
    // Init Header Topbar For Mobile Mode
    KTLayoutHeaderTopbar.init(this.$refs["kt_header_mobile_topbar_toggle"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses"]),

    /**
     * Get header logo
     * @returns {string}
     */
    headerLogo() {
      return process.env.BASE_URL + this.layoutConfig("self.logo");
    },

    /**
     * Get classes for mobile header
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header_mobile");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if the left aside menu is enabled
     * @returns {boolean}
     */
    asideEnabled() {
      return this.layoutConfig("aside.self.display");
    }
  }
};
</script>
