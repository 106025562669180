

export const SUPER_ADMIN                = 1;
export const ADMIN_SALA                 = 2;
export const ADMIN_SALA_VIEWER          = 3;
export const ADMIN_CONGREGAZIONE        = 4;
export const ADMIN_CONGREGAZIONE_VIEWER = 5;
export const OPERATORE                  = 6;



export const LOAD_CARD_CODE             = 'LOAD_CARD_CODE';
export const REFRESH_DASHBOARD          = 'REFRESH_DASHBOARD';
export const HISTORY_PROGRAMMATICALLY   = 'HISTORY_PROGRAMMATICALLY';
export const SHOW_SPINNER               = 'SHOW_SPINNER';
export const SEARCH_SHEETS              = 'SEARCH_SHEETS';
export const SEARCH_HISTORY_SHEETS      = 'SEARCH_HISTORY_SHEETS';


