import {Utils} from "../utils/utils";
import QuestionAnswer from "./QuestionAnswer";

export class MaintenanceSheetHistory {
    constructor(props) {
        this.idSheet            = props ? props.idSheet             : null;
        this.idKindomhall       = props ? props.idKindomhall        : null;
        this.idCongregation     = props ? props.idCongregation      : null;
        this.internalSheetCode  = props ? props.internalSheetCode   : null;
        this.sheetCode          = props ? props.sheetCode           : null;
        this.sheetTitle         = props ? props.sheetTitle          : null;
        this.congregationName   = props ? props.congregationName    : null;
        this.result             = props ? props.result              : null;
        this.resolved           = props ? props.resolved            : null;
        this.checkDate          = props && !!props.checkDate ? Utils.getFormattedDate(props.checkDate) : null;
        this.users              = props ? props.users : null;
        this.extAgencyName      = props && !!props.extAgencyName? props.extAgencyName : false;
        this.questionAnswers    = [];
        this.settingRowColor    = props;
    }

    set fillQuestionAnswers(qa){
        this.questionAnswers = qa.map(t=>new QuestionAnswer(t));
    }

    set settingRowColor(props){
        this._rowVariant = '';
        if(this.resolved === 'Y'|| this.result === "Y") {
            this._rowVariant = 'success';
        }
        else if (this.result === "N") {
            this._rowVariant = 'warning';
        }
    }
}



